//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
//ESTILOS
import "../../components_cfdi/estilo.css";
import "./estilostable.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import { MultiSelectColumnFilter, SelectColumnFilter } from "../../components_cfdi/filters";
import Select from "react-select";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { get_bonification_packages_sales, get_without_bon_pack, post_bonification_packages_sales } from "../../services/bonifications/bonifications";
import Checkbox from "@mui/material/Checkbox";
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import TableContainer from "../../main_components/Table/TableContainer";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import SEARCH_FILTERS from "../tools/shearch_filter";
import { formattedCurrentDate, formattedPreviousDate } from "../../main_components/date/day";
import dayjs from "dayjs";
import { custom_multiple_filter } from "../../main_components/methods_v2/auxiliary_functions";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

export const ADD_SALES_BONIFICACIONES_V2 = () => {

  const [name, setname] = useState("");
  const [num, setnum] = useState(0);

  useEffect(() => {
    const queryString = window.location.search;
    if (queryString === "" || queryString === null || queryString === undefined) {
      console.log("no entra");
    } else {
      analisisquery(queryString);
    }
  }, [])

  const analisisquery = (queryString) => {
    const params = new URLSearchParams(queryString);
    const parametro1 = params.get('name_pack');
    const parametro2 = params.get('num_pack');
    setname(parametro1);
    setnum(parametro2);
  }

  const [validationErrors, setValidationErrors] = useState({});
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [originalData, setoriginalData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setopen] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");
  const [editedUsers, setEditedUsers] = useState([]);

  var date = new Date();
  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();
  var añoantes = date.getFullYear();
  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
    añoantes = añoantes - 1;
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }
  const recuperarPPD = `${año}-${mes}-${dia}`;
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    console.log(selected);
    filterArray(selected);
  };

  function filterArray(f) {
    if (f?.length > 0) {
      const filterArr = originalData.filter((elem) => {
        console.log(elem);
        return f?.some((term) =>
          elem?.name?.trim().includes(term.value.trim())
        );
      });
      console.log(filterArr);
      setData(filterArr);
    } else {
      setData(originalData);
    }
  }
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedPreviousDate));
  const [cont, setcont] = useState(0);
  const [contpackage, setcontpackage] = useState(0);

  function alertas(msj, status) {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }
  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };
  useEffect(() => {
    numeros();
  }, []);

  useEffect(() => {
    setcont(selectedRows.length);
  }, [selectedRows])


  const update_identifiers =
    [
      { date: 'string' },
      { name: 'string' },
      { name_business_partner: 'string' },
      { outer_code: 'number' },
      { number_oxxo_store: 'string' },
      { ticket: 'string' },
      { special_folio_number: 'string' },
      { total: 'string' },
      { paper: 'string' }
    ];

  const handleSubmit = async () => {

    if (fi == "") {
      alert("Falta fecha inicial");
    } else {
      setData([]);
      setopen(true);
      setmenssage("Buscando ventas");
      setmodalT(true);

      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              const d = await get_without_bon_pack(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'));
              const data = d === null ? [] : d.data.sales;
              var ndt = update_null_identifiers(data, update_identifiers);

              setData(ndt);
              setmodalGeneral(false);
              setmodalT(false);
              setopen(false);

            } catch (err) {
              console.log("error", err);
              console.log(err)
              if (err?.response?.status === 404) {
                alertas("No se encontraron ventas dentro del plazo indicado", false);
              }
              setData([]);
              setmodalGeneral(false);
              setmodalT(false);
              setopen(false);
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const numeros = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const d = await get_bonification_packages_sales(sessionStorage.getItem("idpack"));
            setcontpackage(d === null ? 0 : d.data.sales.length);
          } catch (err) {
            console.log("error", err);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  }
  function reiniciar() {
    setSelectedRows([]);
    handleSubmit();
    numeros();
  }
  function Terminar() {
    window.location = "/consult_report_bonuses_v2";
  }
  const columns = useMemo(
    () => [
      {
        header: "Fecha",
        accessorKey: "date",
        enableEditing: false,
      },
      {
        header: "Punto de Venta",
        accessorKey: "name",
        id: "name",
        enableEditing: false,
        filterVariant: 'multi-select',
        filterFn: custom_multiple_filter,
      },
      {
        id: "name_business_partner",
        header: "Socio de Negocios",
        accessorKey: "name_business_partner",
        enableEditing: false,
        filterVariant: 'multi-select',
        filterFn: custom_multiple_filter,
      },
      {
        id: "outer_code",
        header: "Código Externo",
        accessorKey: "outer_code",
        enableEditing: false,
      },
      {
        id: "number_oxxo_store",
        header: "Número Tienda",
        accessorKey: "number_oxxo_store",
        enableEditing: false,
      },
      {
        id: "ticket",
        header: "Ticket",
        accessorKey: "ticket",
        enableEditing: false,
      },
      {
        id: "special_folio_number",
        header: "Folio Especial",
        accessorKey: "special_folio_number",
        enableEditing: false,
      },
      {
        id: "total",
        header: "Total",
        accessorKey: "total",
        accessorFn: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
        },
        enableEditing: false,
      },
      {
        header: "Comentarios",
        accessorKey: "paper",
        id: "paper",
        enableFilters: false,//deshabilita el filtrado
        enableRowOrdering: false,//deshabilita el orden de la columna
        enableGrouping: false,//deshabilita el grupo de la columna
        enableColumnDragging: false,//deshabilita el movimiento de la columna
        enableColumnActions: false,//deshabilita el las acciones
        muiEditTextFieldProps: ({ cell, row }) => ({ //permite la edición a nivel de input
          type: 'text',
          required: true,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          // disabled: !rowSelection[row.id], //habilita las columnas que esten seleccionadas
          onBlur: (event) => {
            // const validationError = !validateRequired(event.currentTarget.value)
            //   ? 'Required'
            //   : undefined;
            // setValidationErrors({
            //   ...validationErrors,
            //   [cell.id]: validationError,
            // });
          },
          onChange: (event) => {
            const updatedValue = event.target.value;
            setEditedUsers(prev => ({
              ...prev,
              [row.id]: {
                ...row.original,
                "paper": updatedValue,
              }
            }));
          }
        }),
      }
    ],
    [data, selectedRows]
  );


  const handle_send_data = async () => {

    const join_object = data?.map((originalItem) => {
      const editedItem = Object?.values(editedUsers)?.find((editedObj) => editedObj?.id === originalItem?.id);

      if (editedItem) {
        return { ...originalItem, ...editedItem };
      } else {
        return originalItem;
      }
    });

    const arregloFiltrado = join_object?.filter(elemento => rowSelection[elemento.id]);
    const vacios = arregloFiltrado?.map(item => {
      if (item?.paper === "" || item?.paper === null || item?.paper.trim() === "") {
        return item;
      } else {
        return null;
      }
    }).filter(item => item !== null);

    const arreglo = arregloFiltrado.map(item => {
      var arr = { id: item?.id, paper: item?.paper }
      return arr
    })

    if (vacios.length > 0) {
      alertas("Falta asignar paper en algunas ventas", false);
    } else {
      var datar = {
        sales: arreglo,
      };
      setopen(true);
      setmodalT(true);
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              const d = await post_bonification_packages_sales(sessionStorage.getItem("idpack"), datar);
              console.log(d);
              setopen(false);
              alertas("Ventas agregadas con éxito", true);
              setmodalT(false);
              setRowSelection({});
              reiniciar();
            } catch (err) {
              console.log("error post", err);
              setopen(false);
              alertas("No se pudo agregar Ventas al paquete, vuelva a intentarlo", false);
              setmodalT(false);
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }

    }

  }

  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR REPORTES DE BONIFICACIONES", path: "/consult_report_bonuses_v2" },
          { label: "AGREGAR VENTAS", path: null }
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        rowSelection={rowSelection}
      ></BREADCRUMBS>

      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>

      <div className="">

        <div className="datospackage" style={{ "width": "100%", marginBottom: "10px" }}>
          <div className="package_info">
            <p>Nombre Paquete: {name === "" ? sessionStorage.getItem("name_pack") : name}</p>
            <p>Número paquete: {num === 0 ? sessionStorage.getItem("num_pack") : num}</p>
          </div>
        </div>
        <div className="new_table_v2">

          <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePinning
            enableFacetedValues
            enableStickyHeader
            onStateChange={handleStateChange}
            enableStickyFooter
            enableRowPinning
            enableColumnFilterModes
            editDisplayMode={'table'} //habilita la edicion en cualquier campo de la tabla
            enableEditing //habilta la edición en tabla
            enableRowSelection
            getRowId={(row) => row?.id}
            onRowSelectionChange={setRowSelection}
            enableTooltips={false}
            localization={MRT_Localization_ES}
            state={{
              rowSelection, isLoading: modalT, columnOrder: [
                'mrt-row-pin',
                'date',
                'name',
                'name_business_partner',
                'outer_code',
                'number_oxxo_store',
                'ticket',
                'special_folio_number',
                'total',
                'paper',
                'mrt-row-select',
              ],
            }}
            enableColumnResizing
            enableColumnPinning
            enableColumnOrdering
            enableExpandAll={false}
            enableColumnDragging={false}
            initialState={{
              showGlobalFilter: true,
              showColumnFilters: true,
            }}
            muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
            muiSkeletonProps={{ animation: 'pulse', height: 28 }}
            rowVirtualizerInstanceRef
            rowVirtualizerOptions={{ overscan: 5 }}
            columnVirtualizerOptions={{ overscan: 2 }}
            muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
            positionToolbarAlertBanner="bottom"
            paginationDisplayMode='pages'
            rowPinningDisplayMode='sticky'
            layoutMode="grid"
            muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

            muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
            muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
            displayColumnDefOptions={{
              'mrt-row-pin': {
                enableHiding: true,
              },
              'mrt-row-actions': {
                enableHiding: true,
              },
              'mrt-row-expand': {
                enableHiding: true,
              },
              'mrt-row-select': {
                enableHiding: false,
              }
            }}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  data.splice(
                    hoveredRow.index,
                    0,
                    data.splice(draggingRow.index, 1)[0],
                  );
                  setData([...data]);
                }
              },
            })}
            muiTableBodyRowProps={
              ({ row, table }) => {
                settotal_rows(table);
                const { density } = table.getState();
                return {
                  sx: {
                    height: row.getIsPinned()
                      ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                      }px`
                      : undefined,
                  },
                };
              }
            }
            renderTopToolbarCustomActions={({ }) => (
              <SEARCH_FILTERS
                fi={fi}
                setfi={setfi}
                ff={ff}
                setff={setff}
                contpackage={contpackage}
                handleSubmit={handleSubmit}
                handle_send_data={handle_send_data}
                Terminar={Terminar}
              ></SEARCH_FILTERS>
            )}

            renderToolbarInternalActions={({ table }) => (
              <RENDER_TOOLBAR_INTERNAL_ACTIONS
                table={table}
                file_name={"Ventas"}
                update_identifiers={update_identifiers}
                settotal_rows={settotal_rows}
              />
            )
            }
          />

        </div>
      </div>

    </>
  )
}
