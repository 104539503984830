//React
import GoogleIcon from '@mui/icons-material/Google';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ReceiptIcon from "@mui/icons-material/Receipt";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ConstructionIcon from '@mui/icons-material/Construction';
import InventoryIcon from '@mui/icons-material/Inventory';

//Iconos material-ui

import {
    NoteAdd,
    Payment,
    FindInPage,
    BrandingWatermark,
    LocalShipping,
    Group,
    AirlineSeatReclineNormal,
    LocationOn,
    BarChart,
    InsertChartOutlined,
    Description,
    Public,
    Assignment,
    PieChart,
    ListAlt,
    AssignmentReturn,
    LocalAtm,
    TrendingDown,
    SupervisorAccount,
    PersonAdd,
    Redeem,
    MonetizationOn,
    Business,
    Timeline,
    Category,
    Kitchen,
    LinearScale,
} from "@material-ui/icons";

import {
    ContentPasteGo,
    FactCheck,
    ManageAccounts,
    PersonSearch,
    PriceChange,
    RequestQuote,
    PriceCheck,
    ReceiptLong,
    Calculate,
    Summarize,
    AutoAwesomeMosaic,
    Plagiarism,
    PointOfSale,
    TravelExplore,
    Discount,
    Warehouse,
    Groups2,
    CurrencyExchange,
} from "@mui/icons-material";
import AddCardIcon from "@mui/icons-material/AddCard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import IcecreamIcon from "@mui/icons-material/Icecream";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import * as style from "./style";

import { Icon } from "@material-ui/core";

import { ContentPasteSharp } from "@mui/icons-material";

import OP_UNILEVER from "../../imgComponents/unilever.ico";


import "./menu.css";


export const getMenuSections = (props, openconfigure, handleCerrarSesion, setOpenconfigure, navigate, handleremoveItem) => [
    {
        id: "assets",
        roleDisplay: props.ROLE_ASSETS === "none" && props.ROLE_CABINETS === "none" ? "none" : "",
        stateIndex: 0,
        title: "ACTIVOS",
        icon: MonetizationOnIcon,
        image: null,
        menuItems: [
            {
                id: "consult_frezzers_v2",
                label: "CONGELADORES",
                link: "/consult_frezzers_v2",
                description: "Congeladores",
                rol: props.ROLE_CABINETS,
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <Kitchen style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_brands_v2",
                label: "MARCAS Y MODELOS",
                link: "/consult_brands_v2",
                description: "Marcas y Modelos",
                rol: props.ROLE_ASSETS,
                customOnClick: (event) => {
                    handleremoveItem(`${props.userId}consult_brands_v2`, "Marcas y Modelos")
                        ? (event.currentTarget.style.color = "yellow")
                        : (event.currentTarget.style.color = "white");
                },
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <BrandingWatermark style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_vehicle_v2",
                label: "VEHÍCULOS",
                link: "/consult_vehicle_v2",
                description: "Vehículos",
                rol: props.ROLE_ASSETS,
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <LocalShipping style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },

    {
        id: "Addenda",
        roleDisplay: props.ROLE_ADDENDAS,
        stateIndex: 1,
        title: "ADDENDAS",
        icon: AccountBalanceIcon, //cambiar el icono
        image: null,
        menuItems: [
            {
                id: "addenda",
                label: "ADDENDAS",
                link: "/Addenda",
                description: "ADDENDAS",
                rol: props.ROLE_ADDENDAS,
                icono: (
                    <Icon style={style.styleFavorite("#EC5A4B", "#E88177", "#B32315")}>
                        <ManageAccounts style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },


    {
        id: "bank",
        roleDisplay: props.ROLE_BANK === "ROLE_BANK" || props.ROLE_SALES === "ROLE_SALES" ? "block" : "none",
        stateIndex: 2,
        title: "BANCOS",
        icon: AccountBalanceIcon,
        image: null,
        menuItems: [
            {
                id: "cashclosing_v2",
                label: "CORTE DE CAJA",
                link: "/cashclosing_v2",
                description: "Corte de Caja",
                rol: props.ROLE_BANK,
                icono: (
                    <Icon style={style.styleFavorite("#4BA575", "#378059", "#1E5337")}>
                        <PriceCheck style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "DEBTS",
                label: "DEUDAS",
                link: "/DEBTS",
                description: "DEBTS",
                rol: props.ROLE_BANK,
                icono: (
                    <Icon style={style.styleFavorite("#4BA575", "#378059", "#1E5337")}>
                        <AddCardIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "PAID",
                label: "PAGOS",
                link: "/PAID",
                description: "PAID",
                rol: props.ROLE_BANK,
                icono: (
                    <Icon style={style.styleFavorite("#4BA575", "#378059", "#1E5337")}>
                        <LinearScale style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "old_balances",
                label: "ANTIGÜEDAD DE SALDOS",
                link: "/old_balances",
                description: "old_balances",
                rol: props.ROLE_BANK,
                icono: (
                    <Icon style={style.styleFavorite("#4BA575", "#378059", "#1E5337")}>
                        <LinearScale style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },
    {
        id: "human_resources",
        roleDisplay: props.ROLE_HUMAN_RESOURCES,
        stateIndex: 3,
        title: "COLABORADORES",
        icon: EmojiPeopleIcon,
        image: null,
        menuItems: [
            {
                id: "daily_staff_v2",
                label: "ASIGNACIÓN DE PERSONAL",
                link: "/daily_staff_v2",
                description: "Asignación de personal",
                rol: props.ROLE_HUMAN_RESOURCES,
                icono: (
                    <Icon style={style.styleFavorite("#A5702C", "#E79023", "#B66600")}>
                        <GroupAddIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_employees_v2",
                label: "CONSULTAR EMPLEADOS",
                link: "/consult_employees_v2",
                description: "Consultar Empleados",
                rol: props.ROLE_HUMAN_RESOURCES,
                icono: (
                    <Icon style={style.styleFavorite("#A5702C", "#E79023", "#B66600")}>
                        <Group style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "job_opportunities",
                label: "CONSULTAR VACANTES",
                link: "/job_opportunities",
                description: "Consultar Vacantes",
                rol: props.ROLE_HUMAN_RESOURCES,
                icono: (
                    <Icon style={style.styleFavorite("#A5702C", "#E79023", "#B66600")}>
                        <GroupAddIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },
    {
        id: "purchases",
        roleDisplay:
            props.ROLE_BUYS !== "none" || props.ROLE_EXPENSE !== "none" ? "block" : "none",
        stateIndex: 6,
        title: "COMPRAS",
        icon: ShoppingCartIcon,
        image: null,
        menuItems: [
            {
                id: "consultaFacturasProvedores_v2",
                label: "CONSULTAR DOCUMENTOS ENVIADOS",
                link: "/consultaFacturasProvedores_v2",
                description: "Consultar Documentos Envíados",
                rol: props.ROLE_BUYS,
                icono: (
                    <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
                        <Description style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "validador",
                label: "ENVÍO DE DOCUMENTOS",
                link: "/validador",
                description: "Envío de Documentos",
                rol: props.ROLE_BUYS,
                icono: (
                    <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
                        <AssignmentReturn style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "supplier_v2",
                label: "CONSULTAR PROVEEDORES",
                link: "/supplier_v2",
                description: "Consultar Proveedores",
                rol: props.ROLE_BUYS,
                icono: (
                    <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
                        <AssignmentReturn style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "catalog_expense_concept_v2",
                label: "CATÁLOGO DE GASTOS",
                link: "/catalog_expense_concept_v2",
                description: "Catálogo de gastos",
                rol: props.ROLE_EXPENSE_ADMINISTRATIVOS,
                icono: (
                    <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
                        <AutoAwesomeMosaic style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_rules_sending_expenses_v2",
                label: "REGLA DE GASTOS",
                link: "/consult_rules_sending_expenses_v2",
                description: "Regla de gastos",
                rol: props.ROLE_EXPENSE_ADMINISTRATIVOS,
                icono: (
                    <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
                        <LocalAtm style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "expenses_v2",
                label: "GASTOS NO DEDUCIBLES",
                link: "/expenses_v2",
                description: "Gastos no Deducibles",
                rol: props.ROLE_EXPENSE,
                icono: (
                    <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
                        <PriceChange style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "expensesExtranjero_v2",
                label: "GASTOS DEL EXTRANJERO",
                link: "/expensesExtranjero_v2",
                description: "Gastos del Extranjero",
                rol: props.ROLE_EXPENSE_EXTRANJERO,
                icono: (
                    <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
                        <Public style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "expensesReport_v2",
                label: "REPORTE DE GASTOS",
                link: "/expensesReport_v2",
                description: "Reporte de Gastos",
                rol: props.ROLE_EXPENSE_ADMINISTRATIVOS,
                icono: (
                    <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
                        <Assignment style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "expensesGrup_v2",
                label: "REPORTE DE GASTOS AGRUPADOS",
                link: "/expensesGrup_v2",
                description: "Reporte de Gastos Agrupados",
                rol: props.ROLE_EXPENSE_ADMINISTRATIVOS,
                icono: (
                    <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
                        <PieChart style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "policy_v2",
                label: "POLIZAS",
                link: "/policy_v2",
                description: "Polizas",
                rol: props.ROLE_POLICY,
                icono: (
                    <Icon style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}>
                        <ListAlt style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },
    {
        id: "invoices",
        roleDisplay:
            props.ROLE_INVOICING === "ROLE_INVOICING" ||
                props.ROLE_CARTA_PORTE === "ROLE_CARTA_PORTE" ||
                props.ROLE_COMPLEMENTO_PAGO === "ROLE_COMPLEMENTO_PAGO"
                ? "block"
                : "none",
        stateIndex: 7,
        title: "FACTURAS",
        icon: ReceiptIcon,
        image: null,
        menuItems: [
            {
                id: "consult_concept_v2",
                label: "CONSULTAR CONCEPTOS",
                link: "/consult_concept_v2",
                description: "Consultar Conceptos",
                rol: props.ROLE_INVOICING,
                icono: (
                    <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
                        <FindInPage style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "invoice_v2",
                label: "CONSULTAR FACTURAS",
                link: "/invoice_v2",
                description: "Consultar Facturas",
                rol: props.ROLE_INVOICING,
                icono: (
                    <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
                        <FactCheck style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "FacturasPPD_v2",
                label: "CONSULTAR FACTURAS PPD",
                link: "/FacturasPPD_v2",
                description: "Consultar Facturas PPD",
                rol: props.ROLE_INVOICING,
                icono: (
                    <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
                        <Plagiarism style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_taxes_v2",
                label: "CONSULTAR IMPUESTOS",
                link: "/consult_taxes_v2",
                description: "Consultar Impuestos",
                rol: props.ROLE_INVOICING,
                icono: (
                    <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
                        <CurrencyExchange style={style.styleFigure} />
                    </Icon>
                ),
            },
            // {
            //     id: "nomina",
            //     label: "CONSULTAR NOMINA",
            //     link: "/nomina",
            //     description: "Consultar Nómina",
            //     roleDisplay: props.ROLE_NOMINA,
            // },
            {
                id: "cartaporte",
                label: "REALIZAR CARTA PORTE",
                link: "/cartaporte",
                description: "Realizar Carta Porte",
                rol: props.ROLE_CARTA_PORTE,
                icono: (
                    <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
                        <ContentPasteGo style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "pago",
                label: "REALIZAR COMPLEMENTO DE PAGO",
                link: "/pago",
                description: "Realizar Complemento de Pago",
                rol: props.ROLE_COMPLEMENTO_PAGO,
                icono: (
                    <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
                        <RequestQuote style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "GenerarFactura",
                label: "REALIZAR FACTURA",
                link: "/GenerarFactura",
                description: "Realizar Factura",
                rol: props.ROLE_INVOICING,
                icono: (
                    <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
                        <NoteAdd style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_payment_report_v2",
                label: "REPORTE DE COMPLEMENTOS DE PAGO",
                link: "/consult_payment_report_v2",
                description: "Reporte Pagos",
                rol: props.ROLE_INVOICING,
                icono: (
                    <Icon style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}>
                        <RequestQuote style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },
    {
        id: "finance",
        roleDisplay: props.ROLE_FINANCE,
        stateIndex: 4,
        title: "FINANZAS",
        icon: PriceChangeIcon,
        image: null,
        menuItems: [
            {
                id: "consult_accounting_account_v2",
                label: "CUENTAS CONTABLES",
                link: "/consult_accounting_account_v2",
                description: "Cuentas Contables",
                rol: props.ROLE_FINANCE,
                icono: (
                    <Icon style={style.styleFavorite("#E9E934", "#E8E827", "#E8BA0F")}>
                        <Calculate style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "payment_accounts",
                label: "CUENTAS DE PAGO",
                link: "/payment_accounts",
                description: "Cuentas de Pago",
                rol: props.ROLE_FINANCE,
                icono: (
                    <Icon style={style.styleFavorite("#E9E934", "#E8E827", "#E8BA0F")}>
                        <Calculate style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },
    {
        id: "inventory",
        roleDisplay: props.ROLE_INVENTORY,
        stateIndex: 10,
        title: "INVENTARIOS",
        icon: InventoryIcon,
        image: null,
        menuItems: [
            {
                id: "products_line_v2",
                label: "LINEA DE PRODUCTOS",
                link: "/products_line_v2",
                description: "Línea de Productos",
                rol: props.ROLE_INVENTORY,
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <FormatLineSpacingIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "products_v2",
                label: "PRODUCTOS",
                link: "/products_v2",
                description: "Productos",
                rol: props.ROLE_INVENTORY,
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <IcecreamIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "price_list_v2",
                label: "LISTA DE PRECIOS",
                link: "/price_list_v2",
                description: "Lista de Precios",
                rol: props.ROLE_INVENTORY,
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <ListAltIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "stock_v2",
                label: "ALMACÉN DE PRODUCTOS",
                link: "/stock_v2",
                description: "Almacén de Productos",
                rol: props.ROLE_INVENTORY,
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <WarehouseIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "movements_v2",
                label: "MOVIMIENTO DE PRODUCTOS",
                link: "/movements_v2",
                description: "Movimiento de Productos",
                rol: props.ROLE_INVENTORY,
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <ExitToAppIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "product_load_v2",
                label: "CARGA DE PRODUCTOS",
                link: "/product_load_v2",
                description: "Carga de Productos",
                rol: props.ROLE_INVENTORY,
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <LinearScale style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },
    {
        id: "reports",
        roleDisplay: props.ROLE_SALES,
        stateIndex: 5,
        title: "REPORTES",
        icon: ContentPasteSharp,
        image: null,
        menuItems: [
            {
                id: "sales-deliveries",
                label: "COMPARACIÓN DE HORARIOS",
                link: "/sales-deliveries",
                description: "Comparación de horarios",
                icono: (
                    <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
                        <ReceiptLong style={style.styleFigure} />
                    </Icon>
                ),
                rol: props.ROLE_SALES,
            },
            {
                id: "NewDashboard_v2",
                label: "DETALLES DE VENTAS",
                link: "/NewDashboard_v2",
                description: "Detalles de Ventas",
                rol: props.ROLE_SALES,
                icono: (
                    <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
                        <ReceiptLong style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "component_route",
                label: "LOGÍSTICA DE RUTAS",
                link: "/component_route",
                description: "Logística de Rutas",
                rol: props.ROLE_SALES,
                icono: (
                    <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
                        <LocationOn style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "rutas",
                label: "MAPAS DE RUTAS",
                link: "/map_routes",
                description: "Mapas de Rutas",
                rol: props.ROLE_SALES,
                icono: (
                    <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
                        <LocationOn style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "comparative_reports",
                label: "PROMEDIOS DE VENTAS",
                link: "/comparative_reports",
                description: "Promedio de Ventas",
                rol: props.ROLE_SALES,
                icono: (
                    <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
                        <Summarize style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "report_sales",
                label: "REPORTE DE VENTAS",
                link: "/report_sales",
                description: "Reporte de Ventas",
                rol: props.ROLE_SALES,
                icono: (
                    <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
                        <Summarize style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_route_sales_report",
                label: "REPORTE DE VENTAS POR RUTA",
                link: "/consult_route_sales_report",
                description: "Reporte de Ventas por Ruta",
                rol: props.ROLE_SALES,
                icono: (
                    <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
                        <Summarize style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "Consult_report_sale_real_v2",
                label: "REPORTE DE VENTAS EN TIEMPO REAL",
                link: "/Consult_report_sale_real_v2",
                description: "Reporte de Ventas Real",
                rol: props.ROLE_SALES,
                icono: (
                    <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
                        <BarChart style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "summary_annual_v2",
                label: "REPORTE SUMARIA ANUAL",
                link: "/summary_annual_v2",
                description: "Reporte Sumaria Anual",
                rol: props.ROLE_SALES,
                icono: (
                    <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
                        <InsertChartOutlined style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "summary_weekly_v2",
                label: "REPORTE SUMARIA SEMANAL",
                link: "/summary_weekly_v2",
                description: "Reporte Sumaria Semanal",
                rol: props.ROLE_SALES,
                icono: (
                    <Icon style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}>
                        <LinearScale style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },

    {
        id: "business",
        roleDisplay: props.ROLE_BUSINESS_PARTNER,
        stateIndex: 8,
        title: "SOCIO DE NEGOCIOS",
        icon: GroupAddIcon,
        image: null,
        menuItems: [
            {
                id: "consult_discounts_v2",
                label: "CONSULTAR DESCUENTOS",
                link: "/consult_discounts_v2",
                description: "Consultar Descuentos",
                rol: props.ROLE_BUSINESS_PARTNER_DISCOUNT,
                icono: (
                    <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
                        <TrendingDown style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_promotions_v2",
                label: "CONSULTAR PROMOCIONES",
                link: "/consult_promotions_v2",
                description: "Consultar Promociones",
                rol: props.ROLE_BUSINESS_PARTNER_PROMOTIONS,
                icono: (
                    <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
                        <Discount style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_point_of_sale_v2",
                label: "CONSULTAR PUNTOS DE VENTA",
                link: "/consult_point_of_sale_v2",
                description: "Consultar Puntos de Venta",
                rol: props.ROLE_BUSINESS_PARTNER_POINT_OF_SALE,
                icono: (
                    <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
                        <PointOfSale style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_sectors_v2",
                label: "CONSULTAR SECTORES",
                link: "/consult_sectors_v2",
                description: "Consultar Sectores",
                rol: props.ROLE_BUSINESS_PARTNER_SECTOR,
                icono: (
                    <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
                        <TravelExplore style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "bussines_partnert_v2",
                label: "CONSULTAR SOCIO DE NEGOCIOS",
                link: "/bussines_partnert_v2",
                description: "Consultar Socio de Negocios",
                rol: props.ROLE_BUSINESS_PARTNER_BUSINESS_PARTNER,
                icono: (
                    <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
                        <PersonSearch style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_recovery_partner_v2",
                label: "RECUPERAR SOCIO DE NEGOCIOS",
                link: "/consult_recovery_partner_v2",
                description: "Recuperar Socio de Negocios",
                rol: props.ROLE_BUSINESS_PARTNER_RECOVER,
                icono: (
                    <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
                        <SupervisorAccount style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "register_general",
                label: "REGISTRO GENERAL",
                link: "/register_general",
                description: "Registro General y Punto de Venta",
                rol: props.ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION,
                icono: (
                    <Icon style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}>
                        <PersonAdd style={style.styleFigure} />
                    </Icon>
                ),
            },

        ],
    },

    {
        id: "unilever",
        roleDisplay: props.ROLE_UNILEVER,
        stateIndex: 9,
        title: "OPERACIONES CON UNILEVER",
        icon: null,
        image: OP_UNILEVER,
        menuItems: [
            {
                id: "contests",
                label: "CONCURSOS",
                link: "/contests",
                description: "Concursos",
                rol: props.ROLE_UNILEVER,
                icono: (
                    <Icon style={style.styleFavorite("#0072C6", "#0F1AC8", "#1E2A3A")}>
                        <Redeem style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_bonus",
                label: "HISTORIAL DE BONIFICACIONES",
                link: "/consult_bonus",
                description: "Historial de Bonificaciones",
                rol: props.ROLE_UNILEVER,
                icono: (
                    <Icon style={style.styleFavorite("#0072C6", "#0F1AC8", "#1E2A3A")}>
                        <Redeem style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "consult_report_bonuses_v2",
                label: "REPORTE DE BONIFICACIONES",
                link: "/consult_report_bonuses_v2",
                description: "Reporte de Bonificaciones",
                rol: props.ROLE_UNILEVER,
                icono: (
                    <Icon style={style.styleFavorite("#0072C6", "#0F1AC8", "#1E2A3A")}>
                        <Redeem style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "DOMY_DATA",
                label: "REPORTE DE CENSO",
                link: "/DOMY_DATA",
                description: "Reporte de Censo",
                rol: props.ROLE_UNILEVER,
                icono: (
                    <Icon style={style.styleFavorite("#0072C6", "#0F1AC8", "#1E2A3A")}>
                        <Redeem style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "DMS",
                label: "DMS",
                link: "/DMS_V2",
                description: "Reporte DMS",
                rol: props.ROLE_UNILEVER,
                icono: (
                    <Icon style={style.styleFavorite("#0072C6", "#0F1AC8", "#1E2A3A")}>
                        <Redeem style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },
    {
        id: "support",
        roleDisplay: "",
        stateIndex: 11,
        title: "SOPORTE",
        icon: ConstructionIcon,
        image: null,
        menuItems: [
            {
                id: "patch_notes",
                label: "ACTUALIZACIONES",
                link: "/patch_notes",
                description: "Ver las últimas actualizaciones del sistema",
                rol: "",
            },
            {
                id: "consult_capacitation",
                label: "CAPACITACIÓN",
                link: "/consult_capacitation",
                description: "Acceder a materiales de capacitación",
                rol: "",
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <OndemandVideoIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "help_center_v2",
                label: "CENTRO DE AYUDA",
                link: "/help_center_v2",
                description: "Visitar el centro de ayuda",
                rol: "",
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <HelpCenterIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "USER_MANUAL",
                label: "MANUALES DE USUARIO",
                link: "/USER_MANUAL",
                description: "Consultar los manuales de usuario",
                rol: "",
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <HelpCenterIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },
    {
        id: "cloud_backup",
        roleDisplay: "",
        stateIndex: 12,
        title: "RESPALDO A LA NUBE",
        icon: GoogleIcon,
        image: null,
        menuItems: [
            {
                id: "drive_to_drive",
                label: "DRIVE A DRIVE",
                link: "/drive_to_drive",
                description: "Sincronizar archivos de Google Drive a otro Drive",
                rol: "",
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <HelpCenterIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "cloudguard_backup",
                label: "CLOUDGUARD BACKUP",
                link: "/datasync",
                description: "Protege tus datos con CloudGuard Backup",
                rol: "",
                icono: (
                    <Icon style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}>
                        <HelpCenterIcon style={style.styleFigure} />
                    </Icon>
                ),
            },
        ],
    },


    {
        id: "userEmail",
        type_control: "text",
        title: "CONFIG",
        label: props.userEmail,
        action: null,
    },
    {
        id: "profile",
        type: "button",
        title: "CONFIG",
        label: "MI PERFIL",
        action: () => navigate("/profile"),
    },
    {
        id: "configuration",
        type_control: "button",
        title: "CONFIG",
        label: "CONFIGURACIÓN",
        action: () => setOpenconfigure(!openconfigure),
        menuItems: [
            {
                id: "warehouse",
                label: "ALMACÉN",
                action: () => navigate("/consult_warehouse_v2"),
                link: "/consult_warehouse_v2",
                rol: true,
                icono: (
                    <Icon style={style.styleFavorite("#535962", "#818181", "#393E47")}>
                        <Warehouse style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "company",
                label: "EMPRESA",
                action: () => navigate("/edit_company"),
                link: "/edit_company",
                rol: true,
                icono: (
                    <Icon style={style.styleFavorite("#535962", "#818181", "#393E47")}>
                        <Business style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "roles",
                label: "ROLES",
                action: () => navigate("/consult_roles"),
                link: "/consult_roles",
                rol: props.ROLE_ADMIN,
                icono: (
                    <Icon style={style.styleFavorite("#535962", "#818181", "#393E47")}>
                        <Category style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "routes",
                label: "RUTAS",
                action: () => navigate("/consult_route_v2"),
                link: "/consult_route_v2",
                rol: true,
                icono: (
                    <Icon style={style.styleFavorite("#535962", "#818181", "#393E47")}>
                        <Timeline style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "routeManagement",
                label: "GESTIÓN DE RUTAS",
                action: () => navigate("/route_management"),
                link: "/route_management",
                rol: true,
                icono: (
                    <Icon style={style.styleFavorite("#535962", "#818181", "#393E47")}>
                        <Warehouse style={style.styleFigure} />
                    </Icon>
                ),
            },
            {
                id: "users",
                label: "USUARIOS",
                action: () => navigate("/consult_users"),
                link: "/consult_users",
                rol: true,
                icono: (
                    <Icon style={style.styleFavorite("#535962", "#818181", "#393E47")}>
                        <Groups2 style={style.styleFigure} />
                    </Icon>
                ),
            }
        ]
    },
    {
        id: "logout",
        type_control: "button",
        title: "CONFIG",
        label: "CERRAR SESIÓN",
        action: handleCerrarSesion,
        rol: true,
    }
];
