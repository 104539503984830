import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import "./breadcrumbs.css";
import * as React from 'react';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export const BREADCRUMBS = (props) => {
  const [open, setOpen] = React.useState(true);
  // console.log(props?.total_rows?.getFilteredRowModel()?.rows?.length)
  function notificationsLabel(count) {
    if (count === 0) {
      return 'no notifications';
    }
    if (count > 99) {
      return 'more than 99 notifications';
    }
    return `${count} notifications`;
  }

  return (
    <div className='breadcrumbs-grid-area'>
      <div className="breadcrumbs-general">
        <div className='breadcrumb-access-level'>
          <a href="/">INICIO</a>
          {props.niveles.map((item, index) =>
            item.path === null ? (
              <React.Fragment key={index}>
                /<span>{item.label}</span>
              </React.Fragment>
            ) : (
              <React.Fragment key={index}>
                /<a href={item.path}>{item.label}</a>
              </React.Fragment>
            )
          )}
        </div>


        <div style={{ display: props.new_version === true ? open === true ? "" : "none" : "none" }}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            variant="filled" severity="info" style={{ color: "white", display: "" }} className="alert-new-version">
            La nueva experiencia del módulo {props.modul} ya está disponible. Hemos realizado un rediseño completo con nuevas funciones, estilos más profesionales y una interfaz más fácil de usar.
            Le invitamos a probar la nueva versión haciendo clic en la siguiente URL: <a href={props.path} className="color-url-new">[{props.modul}]</a>
            A partir del "01-03-2024", el antiguo módulo dejará de estar disponible. Asegúrese de actualizar a la nueva versión para disfrutar de todas las mejoras y evitar interrupciones en el servicio.
          </Alert>
        </div>
        <div style={{ display: props.old_version === true ? open === true ? "" : "none" : "none" }}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            variant="filled" severity="info" style={{ color: "white", display: "" }} className="alert-new-version">
            Bienvenido a la Nueva Interfaz.Continúa utilizando nuestra interfaz mejorada y comparte tus comentarios o sugerencias de mejora en: <a className="color-url-new" href="/help_center">[Soporte-Smit].</a>
            <br></br>
            Regresar a la interfaz antigua: <a href={props.path} className="color-url-new">[{props.modul}].</a>
          </Alert>
        </div>

        <div style={{ display: props.aviso === true ? open === true ? "" : "none" : "none" }}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            variant="filled" severity="info" style={{ color: "white", display: "" }} className="alert-new-version">
            Probar nueva version de consulta: <a className="color-url-new" href="/PRODUCT_LOAD_NEW_METHOD">[NUEVA VERSIÓN].</a>
          </Alert>
        </div>



        <div style={{ display: props.aviso_old === true ? open === true ? "" : "none" : "none" }}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            variant="filled" severity="info" style={{ color: "white", display: "" }} className="alert-new-version">
            Regresar a la versión productivo: <a className="color-url-new" href="/PRODUCT_LOAD_NEW_METHOD">[Versión Estandar].</a>
          </Alert>
        </div>
      </div>






      <div className={props.cards_secundary === true ? "card-container-2 card-new-cont" : "card-container card-new-cont"} style={{ display: props.cards_dashboard ? "" : "none" }}>


        <Stack direction="row" spacing={1}>

          <Chip color={"primary"} icon={<Badge color="secondary">
            <FilterListIcon />
          </Badge>} label={`FILTRADOS: ${props?.filter_row}`} />
          <Chip color={"success"} icon={<Badge color="secondary">
            <SearchIcon />
          </Badge>} label={`CONSULTADOS: ${props?.total}`} />
        </Stack>


      </div>
    </div>
  );
};