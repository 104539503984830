//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import CreateUser from "./CreateUser";
import EditUser from "./EditUser";
import { delete_user, get_users } from "../../services/configuration/configuration";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./users.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import TableContainer from "../../main_components/Table/TableContainer";

const TableUsers = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  var entrada = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }
  const reconsultar = (n) => {
    if (n === true) {
      setmodalT(true);
    } else {
      setmodalT(false);
    }
  }
  //proceso de consultar todos los Uusarios
  const handleSubmit = async () => {
    if (entrada === 0) {
      entrada = entrada + 1;
      setmenssage("Consultando Usuarios...");
      setmodalGeneral(true);
      setmodalT(true);
    } else {
      setmodalT(true);
    }
    setData([]);
    setopen(true);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var d = await get_users();
            setData(d === null ? [] : d.data.users);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
          } catch (err) {
            setData([]);
            setopen(false)
            setmodalGeneral(false);
            setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div>
            <img
              id="crearUser"
              src={CREATE}
              onClick={() => {
                CustomDialog(
                  <div>
                    <CreateUser handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></CreateUser>
                  </div>,
                  {
                    className: "modalpuntosventa",
                    title: "Crear nuevo Usuario",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
              title="Crear Usuario"
              className=""
              alt=""
            ></img>
          </div>
        ),

        id: "Acciones",
        Cell: ({ row }) => {
          const DeleteSector = async () => {

            setmodalT(true);
            setopen(true);
            setData([]);
            try {
              var rf_token = await refresh_token();
              if (rf_token === true) {
                setTimeout(async () => {
                  try {
                    const dt = await delete_user(row.original.id);
                    console.log(dt);
                    alertas("Usuario Eliminado", true);
                    handleSubmit();
                  } catch (err) {
                    console.log("error", err);
                    setopen(false);
                    console.log("error", err);
                    alertas("Error, no se pudo eliminar el Usuario", false);
                    handleSubmit();
                  }
                }, 1000);
              } else { }
            } catch (err) {
              console.log(err);
            }
          }
          return (
            <div>
              <img
                src={Delete}
                onClick={async () => {
                  const result = await Confirm('¿Esta usted seguro de eliminar el Usuario?', 'Eliminar Usuario', 'Si', 'No');
                  if (result) {
                    DeleteSector()
                  }
                }
                }

                title="Eliminar Usuario"
                alt=""
              ></img>
              &nbsp;
              <img
                src={Edit}
                onClick={
                  () => {
                    CustomDialog(
                      <div>
                        <EditUser row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></EditUser>
                      </div>,
                      {
                        className: "modalpuntosventa",
                        title: "Editar Usuario",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                  }
                }

                title="Editar Usuario"
                alt=""
              ></img>
            </div>
          );
        },
      },
      {
        Header: "Habilitado",
        accessor: (d) => {
          return d.user_enable === true ? "SI" : "NO"
        }
      },
      {
        Header: "Bloqueado",
        accessor: (d) => {
          return d.accountnonlocked === true ? "NO" : "SI"
        }
      },
      {
        Header: "Usuario",
        accessor: "username"
      },
      {
        Header: "Nombre",
        accessor: "fullusername"
      },
      {
        Header: "Empresa",
        accessor: (d) => {
          return d.id_company_fk === 1 ? "FCI9908032FA" : ""
        }
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Teléfono",
        accessor: "phone"
      },
      {
        Header: "Puesto",
        accessor: "job"
      },
      {
        Header: "Último ingreso",
        accessor: "last_login_time"
      }

    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR USUARIOS", path: null },
        ]
      }
      ></BREADCRUMBS>
      <div className="TableUsers">

        <div className="tableuserp">
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <>

            <StickyTable>
              <TableContainer
                paginado={"Usuarios"}
                consultas={"Usuarios Consultados"}
                exportar={true}
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                Gmodal={open}
                nametable={"Tabla Usuarios"}

              />
            </StickyTable>
          </>
        </div>
      </div>
    </>
  );
}

export default TableUsers;
