import { Component } from "react";
import { AuthState } from "@aws-amplify/ui-components";
import "../form_business_partner/resp.css";
import { MenuDrawer } from "../main_components/menu/PermanentMenu";
import { CssBaseline } from "@material-ui/core";
import getUserRoles from "./config/getUserRoles";
import LoginPage from "./config/loginPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Actualizado
import routes from "./config/routes";

export class index extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.AuthState === AuthState.SignedIn) {
      sessionStorage.setItem(
        "getAccesTokenRefresh",
        this?.props?.User?.["signInUserSession"]?.["accessToken"]?.["jwtToken"]
      );
      sessionStorage.setItem(
        "getIdTokenRefresh",
        this?.props?.User?.["signInUserSession"]?.["idToken"]?.["jwtToken"]
      );
      sessionStorage.setItem(
        "id_user",
        this?.props?.User?.["attributes"]?.["custom:id_user"]
      );
      sessionStorage.setItem(
        "roles_user",
        this?.props?.User?.["attributes"]?.["custom:roles"]
      );
      sessionStorage.setItem(
        "email_uset",
        this?.props?.User?.["attributes"]?.["email"]
      );
      localStorage.setItem(
        "email_user",
        this?.props?.User?.["attributes"]?.["email"]
      );

      const roles = getUserRoles(
        this?.props?.User?.["attributes"]?.["custom:roles"]
      );
      sessionStorage.setItem("PERMISOCANCEL", roles.ROLE_CANCEL_INVOICE);
      sessionStorage.setItem(
        "ROLE_REPORT_SALES_MANAGEMENT",
        roles.ROLE_REPORT_SALES_MANAGEMENT
      );
      sessionStorage.setItem(
        "ROLE_INVENTORY_PRODUCTS",
        roles.ROLE_INVENTORY_PRODUCTS
      );
      sessionStorage.setItem(
        "ROLE_INVENTORY_MOVEMENTS",
        roles.ROLE_INVENTORY_MOVEMENTS
      );
      sessionStorage.setItem(
        "ROLE_INVENTORY_PRODUCTS_LINE",
        roles.ROLE_INVENTORY_PRODUCTS_LINE
      );
      sessionStorage.setItem("PERMISOPOLICY", roles.ROLE_POLICY);
      sessionStorage.setItem(
        "PERMISOVERDETALLESGASTO",
        roles.ROLE_EXPENSE_ADMINISTRATIVOS
      );
      sessionStorage.setItem("ROLE_INVOICING", roles.ROLE_INVOICING);
      sessionStorage.setItem("exportPermis", roles.ROLE_EXPORT_INVOICE);
      sessionStorage.setItem("ROLE_SUPERVISOR", "ROLE_SUPERVISOR");


      const onSideBarStatic = () => {
        const sidebar = document.getElementById("tc");
        if (sidebar && sidebar.style) {
          sidebar.style.width = "calc(100% - 220px)";
          sidebar.style.left = "200px";
          sidebar.style.right = "20px";
        }
      };

      const onSideBarNoStatic = () => {
        const sidebar = document.getElementById("tc");

        if (sidebar && sidebar.style) {
          sidebar.style.width = "calc(100% - 105px)";
          sidebar.style.left = "85px";
          sidebar.style.right = "20px";
        }
      };

      const onSideBarHide = () => {
        const sidebar = document.getElementById("tc");
        if (sidebar && sidebar.style) {
          sidebar.style.width = "100%";
          sidebar.style.left = "1%";
          sidebar.style.right = "1%";
        }
      };

      const closeSession = () => {
        this.props.SetAuthState(AuthState.SignedOut);
      };

      const userEmail = this?.props?.User?.["attributes"]?.["email"];
      const userId = this?.props?.User?.["attributes"]?.["custom:id_user"];
      const name = this?.props?.User?.["attributes"]?.["name"];

      localStorage.setItem("user_name", name);

      return (
        <>
          <CssBaseline />

          {
            <div id="tc" className="tc">
              {this.props.User === null ? (
                <div> Loading User </div>
              ) : (
                <Router>
                  <MenuDrawer
                    closeSession={closeSession}
                    userEmail={userEmail}
                    userId={userId}
                    onSideBarHide={onSideBarHide}
                    onSideBarStatic={onSideBarStatic}
                    onSideBarNoStatic={onSideBarNoStatic}
                  
                    ROLES={roles}
                  />
                  <div className="tc w-100">
                    <Routes>
                      {routes.map((route, index) => (
                        <Route
                          key={index}
                          path={route.path}
                          element={<route.component />} // Actualizado
                        />
                      ))}
                    </Routes>
                  </div>
                </Router>
              )}
            </div>
          }
        </>
      );
    } else {
      return <LoginPage SetAuthState={this.props.SetAuthState}></LoginPage>;
    }
  }
}

export default index;
