import { useState, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { Checkbox } from "semantic-ui-react";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { put_promotions } from "../../../services/businesspartners/businesspartners";


function EditPromotion(props) {

  const dialog = useDialog();
  const notificationSystemRef = useRef();
  const [namepromotion, setnamepromotion] = useState(props.row.original.promo_name);
  const [client, setclient] = useState(props.row.original.type_business_partner);
  const [clientdisc, setclientedisc] = useState(props.row.original.customer_with_discount);
  const [cant, setcant] = useState(props.row.original.amount_required);
  const [enable, setenable] = useState(props.row.original.enable);
  const [fi, setfi] = useState(props.row.original.initial_date_promo);
  const [ff, setff] = useState(props.row.original.final_date_promo);
  const [bandera, setbandera] = useState(true);

  const handleChange = (event) => {
    if (enable === true) {
      setenable(false);
    } else {
      setenable(true);
    }
  };

  function Spinner() {
    return (
      <div className="spinner-border spinner-border-sm text-light" role="status">
        <span className="sr-only">Cargando...</span>
      </div>
    );
  }

  function alertascreate(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'tr',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'tr',
        autoDismiss: 60,
      });
    }
  }

  const uppromotion = async () => {

    setbandera(false);
    props.setmodalT(true);

    var data = {
      promoCode: props.row.original.promo_code,
      promoName: namepromotion,
      enable: enable,
      initialDatePromo: fi,
      finalDatePromo: ff,
      typeBusinessPartner: client,
      customerWithDiscount: clientdisc,
      amountRequired: cant
    }
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await put_promotions(props.row.original.id, data);
            console.log(dt);
            props.alertas("Promoción actualizada con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handlesubmit();
          } catch (err) {
            console.log("error post", err);
            catchErrorModul("Promociones", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
            alertascreate("No se pudo actualizar la promoción, vuelva a intentarlo", false);
            setbandera(true);
            props.setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) { }
  }

  return (
    <div style={{ "textAlign": "center" }}>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <TextField
          label="Nombre de la Promoción"
          required
          disabled={!bandera}
          value={namepromotion}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setnamepromotion(event.target.value)}
          InputLabelProps={{ shrink: true }}
        ></TextField>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <TextField
          select
          label="Tipo de Cliente"
          disabled={!bandera}
          value={props.row.original.client}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          SelectProps={{
            native: true,
          }}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setclient(event.target.value)}
        >
          <option key={1} value={"PROPIOS"}>{"Cliente Propio"}</option>
          <option key={2} value={"EXTERNOS"}>{"Cliente Unilever"}</option>
          <option key={3} value={"TODOS"}>{"Todos"}</option>
        </TextField>
        <TextField
          select
          disabled={!bandera}
          label="Cliente con Descuento"
          value={props.row.original.customer_with_discount}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          SelectProps={{
            native: true,
          }}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setclientedisc(event.target.value)}
        >
          <option key={1} value={"SI"}>{"Si"}</option>
          <option key={2} value={"NO"}>{"No"}</option>
          <option key={3} value={"TODOS"}>{"Todos"}</option>
        </TextField>
        <TextField
          label="Cantidad Requerida"
          disabled={!bandera}
          type={"number"}
          value={cant}
          required
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setcant(event.target.value)}
          InputLabelProps={{ shrink: true }}
        ></TextField>

        <Box>
          <Checkbox
            label="Disponible"
            disabled={!bandera}
            checked={enable}
            onChange={handleChange}
            inputProps={{ "aria-label": "primary checkbox" }}
            style={{ "padding-right": "0px", "padding-top": "8px", }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '98%', margin:"0 auto" }}>

        <Box sx={{  width: '98%' }} >
          <label className="">Fecha inicial</label>

          <input
            type="text"
            style={{ "width": "98%" }}
            disabled={!bandera}
            value={fi}
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </Box>

        <Box sx={{  width: '98%' }} >
          <label className="">Fecha Final</label>
          <input
            type="text"
            style={{ "width": "98%" }}
            disabled={!bandera}
            value={ff}
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </Box>
      </Box>










      <br></br>

      <Box>
        {
          bandera === true ?
            <Button
              style={{ "backgroundColor": "green" }}
              variant="contained"
              sx={{ m: 1.5, width: "30ch" }}
              onClick={uppromotion}
            >
              Actualizar Promoción
            </Button> :
            <Button
              style={{ "backgroundColor": "green", "height": "35px" }}
              variant="contained"
              disabled
              sx={{ m: 1.5, width: "30ch" }}
            ><Spinner /></Button>
        }
        <Button
          style={{ "backgroundColor": "red" }}
          variant="contained"
          disabled={!bandera}
          sx={{ m: 1.5, width: "20ch" }}
          onClick={() => {
            dialog.close();
          }
          }
        >
          cancelar
        </Button>
      </Box>
    </div>

  );
}

export default EditPromotion;
