import { Dialog, DialogTitle, Button, TextField, InputLabel, styled } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Select from '@mui/material/Select';

// Estilos personalizados (sin cambios)
export const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    },
}));



export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: '#0c4683',
    color: 'white',
    padding: '16px 24px',
    fontSize: '1.25rem',
    fontWeight: 500,
}));

export const StyledDialogTitleError = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: '#e9a952',
    color: 'white',
    padding: '16px 24px',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    display: 'flex', // Agregamos flexbox
    alignItems: 'center', // Alinear los ítems verticalmente
    '& p': {
        paddingTop: '18px',
        fontWeight: 'bold',
        marginLeft: '26px',
        color: 'white',
        position: 'absolute'
    },
}));


export const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px', // Bordes redondeados
        width: 'auto', // Ajusta el ancho
        height: '35px', // Ajusta la altura

        '& fieldset': {
            borderColor: '#e0e0e0',
            borderWidth: '1px',
        },
        '&:hover fieldset': {
            borderColor: '#6200ee',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6200ee',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '14px', // Tamaño de la etiqueta
        marginBottom: '4px',
        transform: 'translate(12px, -13px) scale(1)', // Alinear etiqueta encima
    },
    '& .MuiInputBase-input': {
        padding: '15px 14px', // Ajustar el padding interno
        '&::placeholder': {
            color: '#bdbdbd',
            opacity: 1, // Color del placeholder
        },
    },
}));

export const StyledTextFieldMulti = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px', // Bordes redondeados
        width: 'auto', // Ajusta el ancho
        minHeight: '35px', // Altura mínima para entrada normal
        alignItems: 'flex-start', // Alineación para multiline

        '& fieldset': {
            borderColor: '#e0e0e0',
            borderWidth: '1px',
        },
        '&:hover fieldset': {
            borderColor: '#6200ee',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6200ee',
        },
    },

    '& .MuiInputLabel-root': {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '14px', // Tamaño de la etiqueta
        marginBottom: '4px',
        transform: 'translate(12px, -13px) scale(1)', // Alinear etiqueta encima
    },

    // Ajustes para entradas multiline
    '& .MuiInputBase-input': {
        padding: '15px 14px', // Ajuste del padding interno
        '&::placeholder': {
            color: '#bdbdbd',
            opacity: 1, // Color del placeholder
        },
    },
    '& .MuiInputBase-inputMultiline': {
        padding: '15px 14px', // Ajuste del padding interno en multiline
        minHeight: 'auto', // Permitir que crezca según el contenido
    },
}));

export const StyledSelect = styled(Select)(({ theme, error }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        width: 'auto',
        height: '30px',
        '& fieldset': {
            borderColor: error ? '#d32f2f' : '#e0e0e0', // Rojo si hay error
            borderWidth: '1px',
        },
        '&:hover fieldset': {
            borderColor: error ? '#d32f2f' : '#6200ee',
        },
        '&.Mui-focused fieldset': {
            borderColor: error ? '#d32f2f' : '#6200ee',
        },
    },
    '& .MuiInputLabel-root': {
        color: error ? '#d32f2f' : 'black', // Cambiar color de la etiqueta
        fontWeight: 'bold',
        fontSize: '14px',
        marginBottom: '4px',
        transform: 'translate(12px, -12px) scale(1)',
    },
    '& .MuiInputBase-input': {
        padding: '18px 14px',
        '&::placeholder': {
            color: '#bdbdbd',
            opacity: 1,
        },
    },
}));
export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    color: 'black',        // Color negro
    fontWeight: 'bold',    // Fuente en negrita
    fontSize: '18.5px',      // Tamaño de fuente 14px
    top: '-7px',
    left: "-2px"

}));

export const StyledButtonCancel = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    padding: '8px 16px',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    padding: '10px 16px',
    border: '1.9px outset rgba(.12, 0, 0, 0.1) !important;'
}));


export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px', // Bordes redondeados como el TextField
        height: '30px', // Ajustar la altura
        '& fieldset': {
            borderColor: '#e0e0e0',
            borderWidth: '1px',
        },
        '&:hover fieldset': {
            borderColor: '#6200ee',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6200ee',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '14px', // Tamaño de la etiqueta
        marginBottom: '4px',
        transform: 'translate(12px, -13px) scale(1)', // Alinear etiqueta encima
    },
    '& .MuiInputBase-input': {
        padding: '13px 14px', // Ajustar el padding interno
        '&::placeholder': {
            color: '#bdbdbd',
            opacity: 1, // Color del placeholder
        },
    },
}));