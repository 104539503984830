import React, { useEffect, useMemo, useRef, useState } from 'react';
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { get_competition_unilever } from '../../services/unilever/unilever';
import CREATE from "../../imgComponents/create.png";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { CustomDialog } from "react-st-modal";
import { OPERATION_COMPETITION } from './tools/operation';
import AlertResponse from '../../main_components/alerts/alertResponse';
import { BREADCRUMBS } from '../../main_components/pagination/breadcrumbs';
import "./competition.css";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../main_components/methods_v2/export';
import RENDER_ROW_ACTION_MENU_ITEMS from './tools/render_row_action_menuItems';

export const COMPETITION = () => {

  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [total_rows, settotal_rows] = useState("");

  useEffect(() => {
    handlesubmit();
  }, [])


  const columns = useMemo(
    () => [
      {
        id: "name_contests",
        header: "Nombre del Concurso",
        accessorKey: "name_contests",
        
      },
      {
        id: "enable",
        header: "Estado",
        accessorKey: "enable",
        accessorFn: (d) => {
          return d?.enable === false ? "Terminado" : "Vigente"
        },
        muiTableBodyCellProps: {
          align: "Center"
        }
      },
      {
        id: "date_register",
        accessorKey: "date_register",
        header: "Fecha de Registro"
      },
      {
        id: "start_date",
        accessorKey: "start_date",
        header: "Fecha de Inicio"
      },
      {
        id: "end_date",
        accessorKey: "end_date",
        header: "Fecha de Terminación"
      },
      {
        id: "number_tickets",
        header: "Número de Tickets",
        accessorKey: "number_tickets",
        muiTableBodyCellProps: {
          align: "right"
        }
      }
    ],
    []
  );


  const handlesubmit = async () => {
    try {
      setIsLoading(true);
      setmodalT(true);
      const rf = await refresh_token();
      if (rf) {
        setTimeout(async () => {
          try {
            const d = await get_competition_unilever();
            setData(d?.data ? d?.data : []);
            setIsLoading(false);
            setmodalT(false);
          } catch (err) {
            console.log(err);
            setIsLoading(false);
            setmodalT(false);
          }
        }, 1500);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setmodalT(false);
    }
  }

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const update_identifiers =
    [
      { name_contests: 'string' },
      { start_date: 'string' },
      { end_date: 'String' },
      { enable: 'string' },
      { number_tickets: 'string' }
    ];

  function alertas(msj, status) {

    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }


  return (
    <div>
      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR CONCURSOS", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>

      <div className='new_table_v2'>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          onStateChange={handleStateChange}
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowActions
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          enableExpandAll={false}
          enableColumnDragging={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              Header: () => (
                <div>
                  <img
                    title="Agregar Concurso"
                    src={CREATE}
                    className=""
                    style={{ cursor: "pointer" }}
                    onClick={async () => {
                      const result = await CustomDialog(
                        <OPERATION_COMPETITION
                          operation={"CREATE"}
                          setmodalT={setmodalT}
                          handlesubmit={handlesubmit}
                          alertas={alertas}
                          data={data}
                        />,
                        {
                          className: "custom-modal",
                          title: "Agregar Concurso",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                    }}
                  ></img>
                </div>
              ),
              enableHiding: true,
            },
            'mrt-row-actions': {
              enableHiding: true,
              size: 80
            },
            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <RENDER_ROW_ACTION_MENU_ITEMS
              closeMenu={closeMenu}
              setmodalT={setmodalT}
              setmenssage={setmenssage}
              setmodalGeneral={setmodalGeneral}
              row={row}
              handlesubmit={handlesubmit}
              setreconsult={setreconsult}
              refresh_token={refresh_token}
              alertas={alertas}
            />
          ]}
          renderToolbarInternalActions={({ table, index }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Concursos"}
              update_identifiers={update_identifiers}
              handle_get_consult={handlesubmit}
              key={index}
            />
          )
          }
        />
      </div>
    </div>
  )
}
