//estilo Drawer abierto
export const styleOpen = {
  position: "fixed",
  top: "40px",
  zIndex: 200,
  width: "200px",
  height: "calc(100% - 40px)",
  overflowY: "auto",
  backgroundColor: "#1a374d",
  textAling: "center",
  fontSize: "14px",
};

//estilo Drawer cerrado
export const styleClose = {
  position: "fixed",
  top: "40px",
  zIndex: 200,
  overflowY: "auto",
  width: "60px",
  height: "calc(100% - 40px)",
  backgroundColor: "#1a374d",
  textAling: "center",
};

//Estilos para mostrar/ocultar los items
export const styleInhab = {
  color: "#1337DB",
  alignContent: "center",
  alignSelf: "center",
  alignItems: "center",
  fontSize: "0.9em",
  display: "none",
};

export const styleHab = {
  alignContent: "center",
  alignSelf: "center",
  alignItems: "center",
  display: "block",
  color: "white",
  fontSize: "0.9em",
};

//Icon style
export const iconStyle = {
  borderRadius: "50%",
  position: "relative",
  display: "block",
  float: "rigth",
  margin: "2px",
  cursor: "pointer",
  whiteSpace: "nowrap",
  filter: "drop-shadow(1px 1px 0px #000000)",
  color: "white",
};

//Estilos para estrella
export const selectedStar = {
  marginTop: "10px",
  cursor: "pointer",
  position: "absolute",
  display: "inline",
  color: "#E47911",
  fontSize: "0.8em",
  fontStyle: "bold",
  left: "10%",
  zIndex: 300,
};

export const unselectedStar = {
  marginTop: "10px",
  cursor: "pointer",
  position: "absolute",
  display: "inline",
  color: "#ffff",
  fontSize: "0.8em",
  fontStyle: "bold",
  left: "10%",
  zIndex: 300,
};

export const styleFavorite = (color1 = "", color2 = "", color3 = "") => {
  const estilo = {
    background: `linear-gradient(to bottom left, ${color1}, ${color2}, ${color3} )`,
    color: "white",
    marginTop: "2px",
    fontSize: "1.3em",
    borderRadius: "2px",
    paddingBottom: "2px",
  };
  return estilo;
};

export const styleFigure = {
  margin: "1px 0px 1px 0px",
  filter: "drop-shadow(1px 1px 0px #000000)",
};

export const styleIconStar = {
  justifySelf: "center",
  left: "0%",
  paddingLeft: "0%",
  display: "inline",
};

export const styleSearchBar = {
  position: "relative",
  marginLeft: "40px",
  left: "30px",
  right: "65px",
  width: "40% !important",
  height: "32px",
  maxHeight: "32px",
};

export const styleIconMenu = {
  color: "white",
  position: "relative",
  display: "block",
  float: "rigth",
  paddingRight: "5px",
  paddingTop: "5px",
  paddingBottom: "5px",
  top: "0%",
  height: "max-content",
  cursor: "pointer",
};

export const styleImg = {
  display: "flex",
  width: "max-content",
  position: "absolute",
  top: "0%",
  left: "0%",
  height: "40px",
};

export const styleConfig = {
  float: "left",
  backgroundColor: "#1a374d",
  position: "absolute",
  color: "white",
  width: "300px auto",
  height: "auto",
  overflowX: "hidden",
  zIndex: "500",
  top: "40px",
  right: "0%",
};

export const drawerFavorite = {
  position: "fixed",
  top: "5px",
  left: "650px",
  right: "65px",
  width: "30%",
  height: "fit-content",
  backgroundColor: "#1a374d",
  zIndex: "200",
};

export const styleSpan = {
  height: "30px",
  maxHeight: "30px",
  display: "inline",
  cursor: "pointer",
  whiteSpace: "nowrap",
  maxWidth: "90%",
  marginTop: "1px",
  scrollbarWidth: "thin !important",
  scrollbarColor: "#c1c1c1 #f5f5f5 !important",
};

export const styleConfiguracion = {
  padding: "0%",
  width: "100%",
};

export const styleConfigSections = {
  alignContent: "center",
  padding: "5px 5px 5px 10%",
  borderRadius: "4px",
  width: "100%",
  backgroundColor: "#214A5C",
  marginTop: "10px",
  marginBottom: "4px",
  marginRight: 0,
  fontSize: "1.0em",
};

export const styleToolbar = {
  paddingLeft: 0,
  color: "white",
  maxHeight: "40px",
  minHeight: "40px",
  backgroundColor: "#1a374d",
};
