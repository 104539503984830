import { CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import pdf from "../../../imgComponents/pdf.png";
import { OPERATIONS_JOB } from "./operations";
import { generatePDF } from "./create";
import { useNavigate } from "react-router-dom"; 
import people from "../../../imgComponents/socio.png";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {
    const navigate = useNavigate(); 

    const build = (item) => {
        console.log(item);
        navigate("/applicants", { state: item }); 
    };

    return (
        <div>
            <MenuItem
                key={0}
                onClick={async () => {
                    props.closeMenu();
                    build(props?.row?.original);
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={people}
                        width={"20px"}
                        title="Consultar Solicitudes"
                        className=""
                    />
                </ListItemIcon>
                Consultar Solicitudes
            </MenuItem>
            <MenuItem
                key={1}
                onClick={async () => {
                    props.closeMenu();
                    await CustomDialog(
                        <OPERATIONS_JOB
                            operation={"EDIT"}
                            row={props?.row}
                            handlesubmit={props?.handlesubmit}
                            alertas={props?.alertas}
                            setmodalT={props.setmodalT}
                        />,
                        {
                            className: "custom-modal-2",
                            title: "DETALLES DE VACANTE",
                            showCloseIcon: true,
                        }
                    );
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Detalles de Vacante"
                        className=""
                    />
                </ListItemIcon>
                Detalles de Vacante
            </MenuItem>
            <MenuItem
                key={2}
                onClick={() => {
                    props.closeMenu();
                    generatePDF(props?.row?.original);
                }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={pdf}
                        title="Descargar PDF"
                        className=""
                    />
                </ListItemIcon>
                Descargar PDF
            </MenuItem>
        </div>
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
