import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { post_brands, put_brands } from "../../../services/assets/assets";

function OPERATION_BRANDS(props) {

  const dialog = useDialog();
  const [nameBrand, setnameBrand] = useState(props.operation === "EDIT" ? props.row.original.name : null);
  const [typeB, settypeB] = useState(props.operation === "EDIT" ? props.row.original.type : "CONGELADOR");
  const [vnameBrand, setvnameBrand] = useState(false);
  const [band, setband] = useState(true);


  const updatebrand = async () => {

    var data = {
      "name": nameBrand,//String = Nombre de la marca
      "type": typeB//String = CONGELADOR | VEHÍCULO
    }
    props.setmodalT(true);
    setband(false);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await put_brands(props.row.original.id, data);
            console.log(dt);
            props.alertas("Marca actualizada con éxito", true);
            props.setmodalT(false);
            dialog.close();
            props.handleSubmit();
          } catch (err) {
            console.log(err);
            setband(true);
            catchErrorModul("Marcas", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
            console.log("error post", err);
            props.alertas("No se pudo actualizar la Marca, vuelva a intertarlo", false);
            props.setmodalT(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    };
  }

  const createnewbrand = async () => {
    console.log(typeB);
    if (nameBrand === null || nameBrand === "") {
      props.alertas("Parámetros faltantes", false);
      nameBrand === null || nameBrand === "" ? setvnameBrand(true) : setvnameBrand(false);
    } else {
      props.setmodalT(true);
      setband(false);
      var data = {
        "name": nameBrand,//String = Nombre de la marca
        "type": typeB//String = CONGELADOR | VEHÍCULO
      }
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              const dt = await post_brands(data);
              console.log(dt);
              props.alertas("Marca creada con éxito", true);
              props.setmodalT(false);
              dialog.close();
              props.handleSubmit();
            } catch (err) {
              console.log(err);
              setband(true);
              props.setmodalT(false);
              catchErrorModul("Marcas", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));

              if (err.response.status === 500) {
                props.alertas("No se pudo crear la Marca, vuelva a intertarlo", false);
              } else {
                props.alertas("Error al crear la Marca", false);
              }
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      };
    }
  }


  function Spinner() {
    return (
      <div className="spinner-border spinner-border-sm text-light" role="status">
        <span className="sr-only">Cargando...</span>
      </div>
    );
  }

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
        <TextField
          label="Nombre de la Marca"
          required
          disabled={!band}
          sx={{ m: 1.5, width: "100%" }}
          value={nameBrand}
          style={
            vnameBrand === true ? { "border": "2px solid red", "border-radius": "5px" } : {}
          }
          onSelect={() => setvnameBrand(false)}
          onBlur={
            nameBrand === "" || nameBrand === null ? () => setvnameBrand(true) : () => setvnameBrand(false)
          }
          onChange={(event) => setnameBrand(event.target.value.toUpperCase().slice(0, 40))}
          InputLabelProps={{ shrink: true }}

        ></TextField>
      </Box>
      <Box sx={{width: '96%', margin: "0 auto" }}>

        <TextField
          select
          value={typeB}
          label="Tipo"
          disabled={!band}
          defaultValue={""}
          InputLabelProps={{ shrink: true, }}
          SelectProps={{ native: true }}
          sx={{ width: "100%" }}
          size="small"
          onChange={(event) => settypeB(event.target.value)}
        >
          <option key={1} value={"CONGELADOR"}>
            {"CONGELADOR"}
          </option>
          <option key={2} value={"VEHÍCULO"}>
            {"VEHÍCULO"}
          </option>
        </TextField>

      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
        {
          band === true ?
            <Button
              variant="contained"
              style={{ "backgroundColor": "green" }}
              sx={{ m: 1.5, width: "100%" }}
              onClick={() => { props.operation === "EDIT" ? updatebrand() : createnewbrand() }}
            >
              {props.operation === "EDIT" ? "Actualizar" : "Registrar"}
            </Button> :

            <Button
              variant="contained"
              disabled
              style={{ "backgroundColor": "green", "height": "35px" }}
              sx={{ m: 1.5, width: "100%" }}
            ><Spinner /></Button>
        }

        <Button
          style={{ "backgroundColor": "red" }}
          variant="contained"
          sx={{ m: 1.5, width: "100%" }}
          disabled={!band}
          onClick={() => {
            dialog.close();
          }
          }
        >
          cancelar
        </Button>
      </Box>
    </div>
  );
}
export default OPERATION_BRANDS;
