//PAQUETERIAS
import { useEffect, useState, useMemo } from "react";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
//IMAGENES
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { get_promotions_details } from "../../services/businesspartners/businesspartners";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_ROW_ACTION_MENU_ITEMS from "./utilities/render_row_action_menuItems_details";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";

function DETAILS_PROMOTIONS_V2 (props) {

const [data, setData] = useState([]);
const [open, setopen] = useState(false);
const [isLoading, setIsLoading] = useState(true);
const [rowSelection, setRowSelection] = useState({});


useEffect(() => {
  handlesubmit();
},[]);

const update_identifiers =
[
  { product_code: 'string' },
  { product_name: 'string' },
  { promo_price: 'String' },
  { quantity: 'string' },
];

useEffect(() => {
  const handle = () => {
    setData(data);
  };
   handle();
},);

const handlesubmit = async () => {  
  setData([]);
  setopen(true);
  setIsLoading(true);

  try {
    var rf_token = await refresh_token();
    if(rf_token === true){
      setTimeout(async() => {
        try{
          var d = await get_promotions_details(props?.row?.original?.id);
          var ndt = update_null_identifiers(d?.data?.promotionsDetailsList, update_identifiers);
          setData(ndt);
          setopen(false);  
          setIsLoading(false);
        }catch(err){
          setData([]);
          setopen(false);
          setIsLoading(false);
        }
      }, 1000);
    }else{}
  } catch (error) {} 
}

  const columns = useMemo(
    () => [
      // {
      //   Header:()=>(
      //     <div>
      //       <img
      //         id="Agregardetalles"
      //         src={CREATE}
      //         onClick={async () => {
      //           const result = await CustomDialog(               
      //          <div>
      //             <AddproductPromotion detallespromotions={detallespromotions} reconsultar={props.reconsultar} alertas={props.alertas}></AddproductPromotion>
      //          </div>,
      //        {
      //          className: "modalpuntosventa",
      //          title:"Agregar Detalles",
      //          showCloseIcon: true,
      //          isCanClose:false,
      //        }
      //    );
      //  }}
      //         title="Agregar Detalles"
      //         alt=""
      //       ></img>
      //     </div>
      //   ),
      // id:"AccionesB",
      // Cell: ({ row }) => {  

      // const deletedetails = async () => {

      //   props.reconsultar(true);
      //   setData([]);
      //   setopen(true);
      //   try {
      //    var rf_token = await refresh_token();
      //    if(rf_token===true){
      //     setTimeout(async() => {
      //       try{
      //         const dt = await delete_promotion_details(row.original.id_promotios_fk,row.original.id );
      //         console.log(dt);
      //         props.alertas("Producto Relacionado a la Promoción Eliminado", true);
      //          detallespromotions();
      //          props.reconsultar(false); 
      //       }catch(err){
      //         console.log("error", err);
      //         props.alertas("No se pudo eliminar el producto relacionado a la promoción", false);
      //         props.reconsultar(false);
      //      }     
      //     }, 1000);
      //    }else{}
      //    } catch (err) {
      //     console.log(err);
      //    }
      // }
      //   return (
      //     <div>
      //       <img
      //         src={Delete}
      //         onClick={async() =>{
      //             const result = await Confirm('¿Esta usted seguro de eliminar el producto relacionado a la promoción?','Eliminar Producto','Si','No');
      //             if(result){
      //               deletedetails();
      //               }
      //             }            
      //           }
              
      //         title="Eliminar Producto"
      //         alt=""
      //       ></img>
      //       <img
      //         src={Edit}
      //         onClick={
      //           async () => {
      //             const result = await CustomDialog(               
      //            <div>
      //               <EditDetails detallespromotions={detallespromotions} row={row} reconsultar={props.reconsultar} alertas={props.alertas}></EditDetails>
      //            </div>,
      //          {
      //            className: "modalTitle",
      //            title:"Editar Producto",
      //            showCloseIcon: true,
      //            isCanClose:false,
      //          }
      //      ); 
      //     detallespromotions();
      //    }
      //   }
              
      //         title="Editar Producto"
      //         alt=""
        
      //       ></img>
      //     </div>
      //   );
      // }
      // },
      {
        id: "product_code",
        header:"Código",
        accessorKey: "product_code"
      },
      {
        id: "product_name",
        header:"Nombre",
        accessorKey:"product_name"
      },
      {
        id: "promo_price",
        header:"Precio",
        accessorKey: "promo_price",
        accessorFn: (d) => {
          return d.promo_price
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.promo_price)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.promo_price);
        },          
      },
      {
        id: "quantity",
        header:"Cantidad",
        accessorKey:"quantity"
      }
    ],
    []
  );

  return (
    <div className="">  
            <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
                muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <RENDER_ROW_ACTION_MENU_ITEMS
            closeMenu={closeMenu}
            handlesubmit={handlesubmit}
            row={row} 
            setmodalT={props.setmodalT} 
            alertas={props.alertas}
          />
        ]}
      >  
      </MaterialReactTable>
    </div>
  );
};

export default DETAILS_PROMOTIONS_V2;