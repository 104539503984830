import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import Delete from "../../../imgComponents/borrar.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import Pet_vehicle from "../put_vehicle";
import { delete_vehicle } from "../../../services/assets/assets";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector = async () => {
        props.setmodalT(true);
        props.setreconsult(true);
        try {
            var rf_token = await refresh_token();
            if (rf_token === true) {
                setTimeout(async () => {
                    try {
                        const d = await delete_vehicle(props?.row?.original?.id);
                        console.log(d);
                        props.alertas("Vehículo Eliminado", true);
                        props.setmodalT(false);
                        props.setreconsult(false);
                        props.handleSubmit();
                    } catch (err) {
                        console.log("error", err);
                        props.setmodalT(false);
                        props.setreconsult(false);
                        props.alertas("Error, no se pudo eliminar el Vehículo", false);
                    }
                }, 1000);
            } else { }
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <div>
            <MenuItem
                key={1}
                onClick={async () => {
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el vehículo?', 'Eliminar Vehículo', 'Si', 'No');
                    if (result) {
                        DeleteSector()
                    }
                }
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Vehículo"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Vehículo
            </MenuItem>
            <MenuItem
                key={2}
                onClick={async () => {
                    props.closeMenu();
                    await CustomDialog(
                        <Pet_vehicle
                            vehicular_config={props.row.original.vehicular_config}
                            license_plate={props.row.original.license_plate}
                            asegura_resp_civil={props.row.original.asegura_resp_civil}
                            poliza_resp_civil={props.row.original.poliza_resp_civil}
                            id_model_fk={props.row.original.id_model_fk}
                            description={props.row.original.description}
                            asegura_med_ambiente={props.row.original.asegura_med_ambiente}
                            poliza_med_ambiente={props.row.original.poliza_med_ambiente}
                            asegura_carga={props.row.original.asegura_carga}
                            poliza_carga={props.row.original.poliza_carga}
                            prima_seguro={props.row.original.prima_seguro}
                            id_route_fk={props.row.original.id_route_fk}
                            id_vehicle={props.row.original.id}
                            year_model={props.row.original.year_model}
                            id_brand_model_fk={props.row.original.id_brand_model_fk}
                            name_brand={props.row.original.brand}
                            permissible_gross_vehicle_weight={props.row.original.permissible_gross_vehicle_weight}
                            handleSubmit={props?.handleSubmit}
                            alertas={props?.alertas}
                            setmodalT={props?.setmodalT}
                        ></Pet_vehicle>,

                        {
                            className: "custom-modal-3",
                            title: "EDITAR VEHICULO",
                            showCloseIcon: true,
                        }
                    );
                }
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Vehículo"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Vehículo
            </MenuItem>

        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
