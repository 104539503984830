

import CREATE_UPDATE from "./set_bussines_p"

const BUSINESS_PARTNER_QR = () => {


    return (
        <div>
            <CREATE_UPDATE
                actualiza={false}
                method={"CREATE"}
                code={""}
                business_partner={sessionStorage.getItem("business_partner") === "null" ? "" : sessionStorage.getItem("business_partner")}
                tax_regime={sessionStorage.getItem("tax_regime") === "null" ? "" : sessionStorage.getItem("tax_regime")}
                name={sessionStorage.getItem("name") === "null" ? "" : sessionStorage.getItem("name")}
                first_last_name={sessionStorage.getItem("first_last_name") === "null" ? "" : sessionStorage.getItem("first_last_name")}
                second_last_name={sessionStorage.getItem("second_last_name") === "null" ? "" : sessionStorage.getItem("second_last_name")}
                balance={0}
                credit_days={0}
                business_partner_type={"AMBOS"}
                capital_regime={sessionStorage.getItem("capital_regime") === "null" ? "" : sessionStorage.getItem("capital_regime")}
                partner_type={""}
                person_type={""}
                cfdi_use={""}
                phone={""}
                metodo_pago={""}
                paymethod={""}
                allow_invoicing={""}
                street={sessionStorage.getItem("street") === "null" ? "" : sessionStorage.getItem("street")}
                outdoor_number={sessionStorage.getItem("outdoor_number") === "null" ? "" : sessionStorage.getItem("outdoor_number")}
                inside_number={sessionStorage.getItem("inside_number") === "null" ? "" : sessionStorage.getItem("inside_number")}
                neighborhood={sessionStorage.getItem("neighborhood") === "null" ? "" : sessionStorage.getItem("neighborhood")}
                zip_code={sessionStorage.getItem("zip_code") === "null" ? "" : sessionStorage.getItem("zip_code")}
                city={sessionStorage.getItem("city") === "null" ? "" : sessionStorage.getItem("city")}
                state={sessionStorage.getItem("state") === "null" ? "" : sessionStorage.getItem("state")}
                email={sessionStorage.getItem("email") === "null" ? "" : sessionStorage.getItem("email")}
                country={""}
                reference={""}
                address_type={""}
                subsidiary={""}
                tax_residence={""}
                rfc={sessionStorage.getItem("rfc")}
                num_reg_id_trib={""}
                town={""}
                partner_type_data={"PROPIO"}
                uso_cfdi_data={"G01"}
                state_data={sessionStorage.getItem("state")}
                address_type_data={"FISCAL"}
                tax_regime_data={"601"}
                subsidiary_data={"MATRIZ"}
                metodo_pago_data={"PUE"}
                reconsultar={true}
            ></CREATE_UPDATE>
        </div>
    );

}

export default BUSINESS_PARTNER_QR;