//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import { StickyTable } from "react-sticky-table";
import { CustomDialog } from "react-st-modal";
import { styled } from "@mui/material/styles";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
//COMPONENTES
import JOIN_INVOICE from "../join_invoice/join_invoice";
import TableContainer from "./TableContainer";
import { SelectColumnFilter } from "../../filters";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { refresh_token } from "../.../../../../main_components/tokens/tokenrefresh";
import { pagination } from "../../../main_components/pagination/pagination";
import { get_payment } from "../../../services/invoices/invoice";
import { get_series } from "../../../services/invoices/invoice";
import { generate_payment } from "../../../services/invoices/invoice";
import bank from "../../../dataComponets/bank.json";
import DELETE from "../../../imgComponents/borrar.png";

//IMG

//CSS
import "./style.css";
import "../generate_invoice/factura.css";
import "./pago.css";
import { BREADCRUMBS } from "../../../main_components/pagination/breadcrumbs";
const op4 = [
  { value: "", label: "Seleccione una opción" },
  { value: "01", label: "EFECTIVO (01)" },
  { value: "02", label: "CHEQUE NOMINATIVO (02)" },
  { value: "03", label: "TRANSFERENCIA ELECTRÓNICA DE FONDOS (03)" },
  { value: "99", label: "POR DEFINIR (99)" },
];

const op6 = [
  { value: "MXN", label: "PESO MÉXICANO" },
  { value: "USD", label: "DOLAR" },
  { value: "EU", label: "EURO" },
  { value: "YEN", label: "YEN" },
];

const rl0P = [
  { value: "", label: "Selecciona un tipo de relación" },
  { value: "04", label: "Sustitución de los CFDI previos" },
];

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
    height: 42,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const itemStyle = {
  padding: "2px",
  border: "1px solid silver",
  color: "Black",
  width: "70px",
  height: "20px",
};

const textStyle = {
  ...itemStyle,
  padding: "3px 2px",
};

const textStyle2 = {
  ...itemStyle,
  padding: "3px 2px",
};

function Pago() {
  const [partners, setpartners] = useState([]);
  const [issuing_bank, setissuing_bank] = useState([]);
  const [issuing_bankB, setissuing_bankB] = useState([]);
  const [obj_partners, setobj_partners] = useState([]);
  const [delete_rfc, setdelete_rfc] = useState("");
  const [serial_number, setserial_number] = useState();
  const [type_folio, settype_folio] = useState("");
  const [select_currency_type, setselect_currency_type] = React.useState("MXN");
  const [mode_payment, setmode_payment] = React.useState("01");
  const [series, setseries] = useState([]);
  const [uuid_rel, setuuid_rel] = useState(false);
  const [show_component, setshow_component] = useState(false);
  const [isVisibility, setisVisibility] = useState(false);
  const [type_input_hour_payment, settype_input_hour_payment] = useState("text");
  const [message, setmessage] = React.useState("");
  const [payment_type, setpayment_type] = React.useState(1);
  const [operation_number, setoperation_number] = React.useState("");
  const [foreing_bank, setforeing_bank] = React.useState("");
  const [paying_account, setpaying_account] = React.useState("");
  const [beneficiary_account, setbeneficiary_account] = React.useState("");
  const [edit_amount, setedit_amount] = React.useState("");
  const [edit_email, setedit_email] = React.useState("");
  const [count, setcount] = React.useState(0);
  const [data, setData] = useState([]);
  const [data_original, setdata_original] = useState([]);
  const [paying_account_error_message, setpaying_account_error_message] = useState("");
  const [skip_page_reset, setskip_page_reset] = React.useState(false);
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");
  const [is_loading, setis_loading] = useState(false);
  const [search_string, setsearch_string] = useState("");
  const [total_quantity, settotal_quantity] = React.useState([]);
  const [sum_amount, setsum_amount] = React.useState([]);
  const [cdp_env, setcdp_env] = useState([]);
  const [payment_count, setpayment_count] = useState(1);
  const [final_data, setfinal_data] = React.useState([{ id: "elminarEsto" }]);
  const [refresh_table, setRfreshtable] = React.useState([]);

  var date = new Date();
  var day = `${date.getDate()}`.padStart(2, "0");
  var month = `${date.getMonth() + 1}`.padStart(2, "0");
  var year = date.getFullYear();
  var hora = `${date.getHours()}`.padStart(2, "0");
  var minutos = `${date.getMinutes()}`.padStart(2, "0");
  const [fi2, setfi2] = useState(`${year}-${month}-${day}T${hora}:${minutos}`);

  const handle_get_payment = async () => {
    setis_loading(true);
    setData([]);

    try {
      const dt = await get_payment(fi, ff, partners?.rfc);
      console.log(dt.data)
      setData(dt.data);
      setfinal_data([{ id: "elminarEsto" }]);
      setis_loading(false);

    } catch (err) {
      setData([]);
      console.log("error", err);
      setis_loading(false);
    }
  };







  function numType() {
    var indice2 = series.findIndex((filio) => filio.serie === serial_number);
    if (indice2 === -1) {
      try {
        settype_folio(series[0].folio);
      } catch (err) {
        console.log(err);
      }
    } else {
      settype_folio(series[indice2]?.folio);
    }
  }



  const handleOnSearch = (string, results) => { setsearch_string(string); };
  const handleOnClear = () => { setsearch_string(""); };

  const handleOnSelect = (item) => {
    setpartners(item);
    sessionStorage.setItem(partners.business_partner, "NameBusinespartner");
    setdelete_rfc("");
  };

  const handleOnSelectissuing_bank = (item) => { setissuing_bank(item); };
  const handleOnSelectissuing_bankB = (item) => { setissuing_bankB(item); };

  const handle_series = async () => {
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            const dt = await get_series("P");
            setseries(dt.data);
          } catch (err) {
            console.log("error", err);
            setseries([]);
          }
        }, 1000);
      } else { 
        
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handle_get_businesspartners = async () => {
    setis_loading(true);
    setmessage("Ingresando...");
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {
            var bp = await pagination(3, "bp", null);
            setobj_partners(bp);
            setfinal_data([{ id: "elminarEsto" }]);
            setis_loading(false);
          } catch (err) {
            console.log("error", err);
            setis_loading(false);
            setmessage("");
            setobj_partners({});
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const validCuentaOrdenate = (event) => {
    if (mode_payment === "02") {
      if (paying_account?.length === 11) {
        if (/^[0-9]{11}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {11} dígitos o {18} dígitos"
          );
        } else {
          setpaying_account_error_message("");
        }
        setpaying_account_error_message("");
      } else if (paying_account?.length === 18) {
        if (/^[0-9]{18}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {11} dígitos o {18} dígitos"
          );
        } else {
          setpaying_account_error_message("");
        }
        setpaying_account_error_message("");
      } else {
        setpaying_account_error_message(
          "La cuenta ordénente, con forma de pago 02 (Cheque nominativo), debe contener un patrón de números del [0-9] y una longitud de 11 dígitos o 18 dígitos."
        );
      }
    }

    if (mode_payment === "03") {
      if (paying_account?.length === 10) {
        setpaying_account_error_message("");
        if (/^[0-9]{10}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else if (paying_account?.length === 16) {
        setpaying_account_error_message("");
        if (/^[0-9]{16}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else if (paying_account?.length === 18) {
        setpaying_account_error_message("");
        if (/^[0-9]{18}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else {
        setpaying_account_error_message(
          "La cuenta ordénente, con forma de pago 03 (Transferencia electrónica de fondos), debe contener un patrón de números del [0-9] y una longitud de 10, 16 o 18 dígitos."
        );
      }
    }
    if (mode_payment === "04") {
      if (paying_account?.length === 16) {
        setpaying_account_error_message("");
        if (/^[0-9]{16}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener números del [0-9] y una longitud de {16} dígitos"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else {
        setpaying_account_error_message(
          "La cuenta ordénente, con forma de pago 04 (Tarjeta de crédito), debe contener un patrón de números del [0-9] y una longitud 16 dígitos."
        );
      }
    }

    if (mode_payment === "05") {
      if (paying_account.length === 10) {
        setpaying_account_error_message("");
        if (/^[0-9]{10,11}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else if (/^[A-Z0-9_]{10,50}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else if (paying_account.length === 11) {
        setpaying_account_error_message("");
        if (/^[0-9]{10,11}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else if (paying_account.length === 15) {
        setpaying_account_error_message("");
        if (/^[0-9]{15,16}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else if (paying_account.length === 16) {
        setpaying_account_error_message("");
        if (/^[0-9]{15,15}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else if (paying_account.length === 18) {
        setpaying_account_error_message("");
        if (/^[0-9]{10,11}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else {
        setpaying_account_error_message(
          "La cuenta ordénente, con forma de pago 05 (Monedero electrónico), debe contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
        );
      }
    }

    if (mode_payment === "06") {
      if (paying_account.length === 10) {
        setpaying_account_error_message("");
        if (/^[0-9]{10}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{10}"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else {
        setpaying_account_error_message(
          "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{10}"
        );
      }
    }

    if (mode_payment === "28") {
      if (paying_account.length === 16) {
        setpaying_account_error_message("");
        if (/^[0-9]{16}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{16}"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else {
        setpaying_account_error_message(
          "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{16}"
        );
      }
    }

    if (mode_payment === "29") {
      if (paying_account.length === 15) {
        setpaying_account_error_message("");
        if (/^[0-9]{15,16}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{15,16}"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else if (paying_account.length === 16) {
        setpaying_account_error_message("");
        if (/^[0-9]{15,16}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{15,16}"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else {
        setpaying_account_error_message(
          "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{15,16}"
        );
      }
    }

    if (mode_payment === "28") {
      if (paying_account.length === 10) {
        setpaying_account_error_message("");
        if (/^[0-9]{10}$/g.test(paying_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{10}"
          );
        } else {
          setpaying_account_error_message("");
        }
      } else {
        setpaying_account_error_message(
          "El patrón de la cuenta ordenante debe contener el siguiente formato [0-9]{10}"
        );
      }
    }
  };

  const validCuentaBeneficiario = (event) => {
    if (mode_payment === "02") {
      if (beneficiary_account.length === 11) {
        if (/^[0-9]{11}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {11} dígitos o {18} dígitos"
          );
        }
      } else if (beneficiary_account.length === 18) {
        if (/^[0-9]{18}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {11} dígitos o {18} dígitos"
          );
        }
      } else {
        setpaying_account_error_message(
          "La cuenta beneficiario debe contener un patrón de números del [0-9] y una longitud de 11 dígitos o 18 dígitos."
        );
      }
    }

    if (mode_payment === "03") {
      if (beneficiary_account.length === 10) {
        if (/^[0-9]{10}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        }
      } else if (beneficiary_account.length === 16) {
        if (/^[0-9]{16}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        }
      } else if (beneficiary_account.length === 18) {
        if (/^[0-9]{18}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {10, 16 o 18} dígitos"
          );
        }
      } else {
        setpaying_account_error_message(
          "La cuenta beneficiario debe contener un patrón de números del [0-9] y una longitud de 10, 16 o 18 dígitos."
        );
      }
    }
    if (mode_payment === "04") {
      if (beneficiary_account.length === 16) {
        if (/^[0-9]{16}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener números del [0-9] y una longitud de {16} dígitos"
          );
        }
      } else {
        setpaying_account_error_message(
          "La cuenta beneficiario debe contener un patrón de números del [0-9] y una longitud 16 dígitos."
        );
      }
    }

    if (mode_payment === "05") {
      if (beneficiary_account.length === 10) {
        if (/^[0-9]{10,11}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        } else if (/^[A-Z0-9_]{10,50}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        }
      } else if (beneficiary_account.length === 11) {
        if (/^[0-9]{10,11}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        }
      } else if (beneficiary_account.length === 15) {
        if (/^[0-9]{15,16}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        }
      } else if (beneficiary_account.length === 16) {
        if (/^[0-9]{15,15}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        }
      } else if (beneficiary_account.length === 18) {
        if (/^[0-9]{10,11}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario deber contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
          );
        }
      } else {
        setpaying_account_error_message(
          "La cuenta beneficiario debe contener el siguiente formato [0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}"
        );
      }
    }

    if (mode_payment === "06") {
      if (beneficiary_account.length === 10) {
        console.log();
        if (/^[0-9]{10}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{10}"
          );
        }
      } else {
        setpaying_account_error_message(
          "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{10}"
        );
      }
    }

    if (mode_payment === "28") {
      if (beneficiary_account.length === 16) {
        console.log();
        if (/^[0-9]{16}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{16}"
          );
        }
      } else {
        setpaying_account_error_message(
          "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{16}"
        );
      }
    }

    if (mode_payment === "29") {
      if (beneficiary_account.length === 15) {
        if (/^[0-9]{15,16}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{15,16}"
          );
        }
      } else if (beneficiary_account.length === 16) {
        if (/^[0-9]{15,16}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{15,16}"
          );
        }
      } else {
        setpaying_account_error_message(
          "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{15,16}"
        );
      }
    }

    if (mode_payment === "28") {
      if (beneficiary_account.length === 10) {
        console.log();
        if (/^[0-9]{10}$/g.test(beneficiary_account) === false) {
          setpaying_account_error_message(
            "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{10}"
          );
        }
      } else {
        setpaying_account_error_message(
          "El patrón de la cuenta beneficiario debe contener el siguiente formato [0-9]{10}"
        );
      }
    }
  };
  const handle_generate_payment = async () => {

    if (fi2 === undefined) {
      alert("El campo de fecha tiene un formato incorrecto. Por favor, revíselo.");
      return false
    }
    if (fi2 === "") {
      alert("El campo de fecha tiene un formato incorrecto. Por favor, revíselo.");

      return false
    }
    if (fi2 === null) {
      alert("El campo de fecha tiene un formato incorrecto. Por favor, revíselo.");

      return false
    }
    if (total_quantity === 0) {
      const result = final_data.filter((d) => d.id !== "elminarEsto");
      var mostar1;
      if (show_component === false) {
        mostar1 = null;
      } else {
        mostar1 = "";
      }

      var dato = {
        pagos: [
          {
            fechapago: fi2 + ":00",

            formadepagop: mode_payment,

            monedap: select_currency_type,

            tipocambiop: payment_type,

            monto: Number(edit_amount),

            numoperacion: mostar1 || operation_number || null,

            rfcemisorctaord: mostar1 || issuing_bank.value || null,

            nombancoordext: mostar1 || issuing_bank?.name || null,

            ctaordenante: mostar1 || paying_account || null,

            rfcemisorctaben: mostar1 || issuing_bankB.value || null,

            ctabeneficiario: mostar1 || beneficiary_account || null,

            tipocadpago: null,

            certpago: null,

            cadpago: null,

            sellopago: null,

            doctorelacionado: result,
          },
        ],

        receptor: {
          id: partners.id,

          nombre: partners.business_partner,

          rfc: partners.rfc,

          usocfdi: partners.cfdi_use,

          residenciafiscal: partners.tax_residence || null,

          numregidtrib: partners.num_reg_id_trib || null,

          regimenfiscalreceptor: partners.tax_regime,

          domiciliofiscalreceptor: partners.zip_code,
        },

        cfdirelacionados:
          uuid_rel === undefined
            ? null
            : uuid_rel === false
              ? null
              : [
                {
                  tiporelacion: uuid_rel?.motivo,
                  uuid: uuid_rel?.uuid_sat,
                },
              ],
        email: edit_email,
      };

      setis_loading(true);
      setmessage("Generando complemento de pago");

      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
               await generate_payment(dato);
              alert("Se genero correctamente el complemento de pago");
              setmessage("");
              window.location = "/pago";
            } catch (err) {
              setmessage("");
              catchErrorModul(
                "Generar Pago",
                JSON.stringify(dato),
                JSON.stringify(
                  err?.response
                )
              );
              setis_loading(false);
              alert("Erro:" + JSON.stringify(err?.response?.data));
              window.location = "/pago";
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("La diferencia debe ser 0, revise su monto de pago");
    }
  };

  // console.log(neweditPago);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const handleedit_amount = (event) => {
    setedit_amount(event.target.value);
  };

  const handleedit_email = (event) => {
    setedit_email(event.target.value);
  };

  console.log(final_data);

  const updateMyData = (rowIndex, columnId, value) => {
    setskip_page_reset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const remove = (rowIndex) => {
    setData((old) => old.filter((_, index) => index !== rowIndex));
  };

  const handleRemoveItem = (e) => {
    const id = e.target.getAttribute("name");
    console.log(id);
    console.log(cdp_env);
    // eslint-disable-next-line eqeqeq
    setcdp_env(cdp_env.filter((item) => item.id_pay != id));
    console.log(cdp_env);
  };

  const handlesenddata = async () => {

    var nddd = cdp_env?.map((d) => d.doctorelacionado);
    console.log(data_original)
    var nddd2 = data_original.map((d) => {

      var json = {
        iddocumento: d.uuid_sat,
        amount_of_debt: d.amount_of_debt,
        userimppagado: 0,
      }
      return json
    });


    console.log(nddd2);

    const arregloUnido = nddd.flatMap((arregloInterno) => arregloInterno);

    console.log(arregloUnido);

    var arrPay = nddd2.concat(arregloUnido);
    console.log(arrPay);

    var isValid = handle_sum_payment_taxes(arrPay);

    if (isValid === true) {
      setis_loading(true);
      setmessage("Generando complemento de pago");
      var dataPay = {
        pagos: cdp_env,
        receptor: {
          id: partners.id,
          nombre: partners.business_partner,
          rfc: partners.rfc,
          usocfdi: partners.cfdi_use,
          residenciafiscal: partners.tax_residence || null,
          numregidtrib: partners.num_reg_id_trib || null,
          regimenfiscalreceptor: partners.tax_regime,
          domiciliofiscalreceptor: partners.zip_code,
        },

        cfdirelacionados:
          uuid_rel === undefined
            ? null
            : uuid_rel === false
              ? null
              : [
                {
                  tiporelacion: uuid_rel?.motivo,
                  uuid: uuid_rel?.uuid_sat,
                },
              ],
        email: edit_email,
      };
      console.log(dataPay);

      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          setTimeout(async () => {
            try {
              await generate_payment(dataPay);
              alert("Se genero correctamente el complemento de pago");
              setmessage("");
              window.location = "/pago";
            } catch (err) {
              setmessage("");
              catchErrorModul(
                "Generar Pago",
                JSON.stringify(dataPay),
                JSON.stringify(
                  err?.response
                )
              );
              setis_loading(false);
              alert("Erro:" + JSON.stringify(err?.response?.data));
              window.location = "/pago";
            }
          }, 1000);
        } else { }
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("the query is not executed");
    }
  };
  function handle_sum_payment_taxes(obj) {
    const idDocSum = {};
    const idDocSumdebit = {};
    let isValid = true;

    for (let i = 0; i < obj.length; i++) {
      const iddoc = obj[i].iddocumento;
      if (idDocSum[iddoc] === undefined) {
        idDocSum[iddoc] = obj[i]?.userimppagado === undefined ? 0 : obj[i]?.userimppagado;
      } else {
        idDocSum[iddoc] += obj[i].userimppagado === undefined ? 0 : obj[i].userimppagado;
      }
    }

    for (let i = 0; i < obj.length; i++) {
      const iddoc = obj[i].iddocumento;
      if (idDocSumdebit[iddoc] === undefined) {
        idDocSumdebit[iddoc] = obj[i]?.amount_of_debt === undefined ? 0 : obj[i]?.amount_of_debt;
      } else {
        idDocSumdebit[iddoc] += obj[i]?.amount_of_debt === undefined ? 0 : obj[i]?.amount_of_debt;
      }
    }

    for (const iddoc in idDocSum) {
      if (idDocSumdebit !== undefined && idDocSumdebit[iddoc] >= idDocSum[iddoc]) {
        console.log(idDocSumdebit[iddoc] + ">=" + idDocSum[iddoc])
      }
      else {
        alert(`La suma de los pagos para iddocumento ${iddoc}: ${idDocSum[iddoc]} es mayor que la deuda (${idDocSumdebit[iddoc]})`);
        isValid = false;

      }
    }
    return isValid;

  }


  console.log(fi2 + ":00")

  const handleSaveData = (event) => {
    setisVisibility(true);
    setcount(0);
    if (total_quantity === 0) {
      const result = final_data.filter((d) => d.id !== "elminarEsto");
      var mostar1;
      if (show_component === false) {
        mostar1 = null;
      } else {
        mostar1 = "";
      }

      var dta = {
        fechapago: fi2 + ":00",
        formadepagop: mode_payment,
        monedap: select_currency_type,
        tipocambiop: payment_type,
        monto: Number(edit_amount),
        numoperacion: mostar1 || operation_number || null,
        rfcemisorctaord: mostar1 || issuing_bank.value || null,
        nombancoordext: mostar1 || issuing_bank?.name || null,
        ctaordenante: mostar1 || paying_account || null,
        rfcemisorctaben: mostar1 || issuing_bankB.value || null,
        ctabeneficiario: mostar1 || beneficiary_account || null,
        tipocadpago: null,
        certpago: null,
        cadpago: null,
        sellopago: null,
        doctorelacionado: result,
      };

      setcdp_env([...cdp_env, { id_pay: payment_count, ...dta }]);

      setpayment_count(payment_count + 1);
      setedit_amount(0);
      settotal_quantity(0);
    } else {
      alert("La diferencia debe ser 0, revise sus montos de pago");
    }
  };

  useEffect(() => {
    setskip_page_reset(false);
  }, [data]);

  useEffect(() => {
    handle_get_businesspartners();
    handle_series();
  }, []);

  useEffect(() => {
    setData([]);
    setfinal_data([]);
    setcdp_env(cdp_env);
  }, [cdp_env]);

  console.log(final_data);

  const columns = useMemo(
    () => [
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Serie",
        accessor: "serie",
        Filter: SelectColumnFilter,
        filter: "equals",
        id: "edipa",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Folio",
        accessor: "folio",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Fecha",
        accessor: "invoice_date",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },

      {
        Header: "Cliente",
        accessor: "name_business_partner",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Importe",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
        },
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Deudas",
        accessor: (d) => {
          return d.amount_of_debt
            ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.amount_of_debt)
            : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.amount_of_debt);
        },
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Moneda",
        accessor: "currency",
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Parcialidad",
        accessor: "installment",
        Cell: function Cell(cell) {
          return <span>{cell.value}</span>;
        },
      },

      {
        id: "selection2",

        Header: ({ getToggleAllRowsSelectedProps }) => {
          function refreshtable() {
            setRfreshtable(!refresh_table);
          }

          return (
            <div className="TCDPT">
              <table className="TCDPT ">
                <td className="td1">
                  <p className="pagoColor">Pago</p>
                </td>
                <td className="td1">
                  <p className="pagoColor">Diferencia</p>
                </td>
                <td className="td1">
                  <p className="pagoColor">Equivalencia</p>
                </td>
                <td>
                  <div>
                    <input
                      type="checkbox"
                      {...getToggleAllRowsSelectedProps()}
                      onClick={() => refreshtable(refresh_table)}
                    />
                  </div>
                </td>
              </table>
            </div>
          );
        },

        Cell: ({ row, value, onChange }) => {
          const [mode, setMode] = React.useState("read");
          const [text, setText] = React.useState(value ?? "");
          const [textdr, setTextdr] = React.useState(value ?? "");
          const [nImporte, setnImporte] = useState(0);
          var rowDate = row.getToggleRowSelectedProps();
          const inputElement =
            document?.getElementById("input-element-id")?.value;
          const inputElement2 = document?.getElementById(
            "input-element-id-cambioT"
          )?.value;

          function select_payment() {
            if (rowDate.checked === false) {
              setText(row.original.amount_of_debt);
              setTextdr(1);
              if (final_data[row.index]?.length !== 0) {
                final_data[row.index] = {
                  id: row.index,
                  iddocumento: row.original.uuid_sat,
                  serie: row.original.serie,
                  folio: row.original.folio,
                  monedadr: row.original.currency,
                  equivalenciadr: 1,
                  numparcialidad:
                    row.original.installment === 0
                      ? 1
                      : row.original.installment + 1,
                  impsaldoant: row.original.amount_of_debt,
                  imppagado: row.original.amount_of_debt,
                  impsaldoinsoluto: 0,
                };
              } else {
                setfinal_data([
                  ...final_data,
                  {
                    id: row.index,
                    iddocumento: row.original.uuid_sat,
                    serie: row.original.serie,
                    folio: row.original.folio,
                    monedadr: row.original.currency,
                    equivalenciadr: 1,
                    numparcialidad:
                      row.original.installment === 0
                        ? 1
                        : row.original.installment + 1,
                    impsaldoant: row.original.amount_of_debt,
                    imppagado: row.original.amount_of_debt,
                    impsaldoinsoluto: 0,
                  },
                ]);
              }
            } else {
              setText("");
              setTextdr("");
              final_data[row.index] = {
                id: "elminarEsto",
              };
              setfinal_data(final_data);
            }
          }

          function folio() {
            if (row.isSelected === true) {
              setText(row.original.amount_of_debt);
              setTextdr(1);

              if (final_data[row.index]?.length !== 0) {
                final_data[row.index] = {
                  id: row.index,
                  iddocumento: row.original.uuid_sat,
                  serie: row.original.serie,
                  folio: row.original.folio,
                  monedadr: row.original.currency,
                  equivalenciadr: 1,
                  numparcialidad:
                    row.original.installment === 0
                      ? 1
                      : row.original.installment + 1,
                  impsaldoant: row.original.amount_of_debt,
                  imppagado: row.original.amount_of_debt,
                  impsaldoinsoluto: 0,
                };
              } else {
                setfinal_data([
                  ...final_data,
                  {
                    id: row.index,
                    iddocumento: row.original.uuid_sat,
                    serie: row.original.serie,
                    folio: row.original.folio,
                    monedadr: row.original.currency,
                    equivalenciadr: 1,
                    numparcialidad:
                      row.original.installment === 0
                        ? 1
                        : row.original.installment + 1,
                    impsaldoant: row.original.amount_of_debt,
                    imppagado: row.original.amount_of_debt,
                    impsaldoinsoluto: 0,
                  },
                ]);
              }
            } else {
              setText("");
              setTextdr("");
              final_data[row.index] = {
                id: "elminarEsto",
              };
              setfinal_data(final_data);
            }
          }

          useEffect(() => {
            const handlerestar = () => {
              setfinal_data(final_data);
            };
            handlerestar();
          });
          useEffect(() => {
            folio();
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [refresh_table]);
          useEffect(() => {
            const handlerestar = () => {
              if (Number(inputElement2) === 1) {
                const sumar = final_data
                  ?.map((saldo) =>
                    parseFloat(
                      isNaN(saldo?.imppagado) === true ? 0 : saldo.imppagado
                    )
                  )
                  .reduce((previous, current) => {
                    return previous + current;
                  }, 0);
                settotal_quantity(Number(inputElement) - sumar.toFixed(2));
              } else {
                const montoP = Number(inputElement);

                settotal_quantity(montoP - nImporte);
              }
            };
            handlerestar();
          });

          useEffect(() => {
            const handlerestar = () => {
              const sumar = final_data
                ?.map((saldo) =>
                  parseFloat(
                    isNaN(saldo?.imppagado) === true ? 0 : saldo.imppagado
                  )
                )
                .reduce((previous, current) => {
                  return previous + current;
                }, 0);
              setsum_amount(sumar.toFixed(2));
              setnImporte(sumar.toFixed(2));
            };
            handlerestar();
          });
          useEffect(() => {
            const handlerestar = () => {
              const resultFilter = final_data?.filter(
                (d) => d.id !== "elminarEsto"
              );
              setcount(resultFilter.length);
            };
            handlerestar();
          });
          useEffect(() => {
            const resultFilter = final_data?.filter(
              (d) => d.id !== "elminarEsto"
            );
            setfinal_data(resultFilter);
          }, [final_data]);

          if (mode === "edit") {
            const handleInputChange = (e) => {
              setText(e.target.value);
            };
            const handleInputChange2 = (e) => {
              setText(" " + row.original.amount_of_debt);
            };

            const handleInputChangedr = (e) => {
              setTextdr(e.target.value);
            };
            const handleInputChange2dr = (e) => {
              setTextdr(" " + 1);
            };

            const handleSaveClick = () => {
              setMode("read");

              setfinal_data(
                Number(text) < row.original.amount_of_debt
                  ? Number(row.original.amount_of_debt - text).toFixed(2)
                  : 0
              );

              if (Number(text) > row.original.amount_of_debt) {
                setText(0);
                if (final_data[row.index]?.length !== 0) {
                  final_data[row.index] = {
                    id: row.index,
                    iddocumento: row.original.uuid_sat,
                    serie: row.original.serie,
                    folio: row.original.folio,
                    monedadr: row.original.currency,
                    equivalenciadr: 1,
                    numparcialidad:
                      row.original.installment === 0
                        ? 1
                        : row.original.installment + 1,
                    impsaldoant: row.original.amount_of_debt,
                    imppagado: Number(0),
                    impsaldoinsoluto: Number(
                      Number(row.original.amount_of_debt - 0).toFixed(2)
                    ),
                  };
                } else {
                  setfinal_data([
                    ...final_data,
                    {
                      id: row.index,
                      iddocumento: row.original.uuid_sat,
                      serie: row.original.serie,
                      folio: row.original.folio,
                      monedadr: row.original.currency,
                      equivalenciadr: 1,
                      numparcialidad:
                        row.original.installment === 0
                          ? 1
                          : row.original.installment + 1,
                      impsaldoant: row.original.amount_of_debt,
                      imppagado: Number(0),
                      impsaldoinsoluto: Number(
                        Number(row.original.amount_of_debt - 0).toFixed(2)
                      ),
                    },
                  ]);
                }
              } else {
                if (final_data[row.index]?.length !== 0) {
                  final_data[row.index] = {
                    id: row.index,
                    iddocumento: row.original.uuid_sat,
                    serie: row.original.serie,
                    folio: row.original.folio,
                    monedadr: row.original.currency,
                    equivalenciadr: Number(textdr),
                    numparcialidad:
                      row.original.installment === 0
                        ? 1
                        : row.original.installment + 1,
                    impsaldoant: row.original.amount_of_debt,
                    imppagado: Number(text) * Number(textdr),
                    impsaldoinsoluto: Number(
                      Number(row.original.amount_of_debt - text).toFixed(2)
                    ),
                  };
                } else {
                  setfinal_data([
                    ...final_data,
                    {
                      id: row.index,
                      iddocumento: row.original.uuid_sat,
                      serie: row.original.serie,
                      folio: row.original.folio,
                      monedadr: row.original.currency,
                      equivalenciadr: Number(textdr),
                      numparcialidad:
                        row.original.installment === 0
                          ? 1
                          : row.original.installment + 1,
                      impsaldoant: row.original.amount_of_debt,
                      imppagado: Number(text) * Number(textdr),
                      impsaldoinsoluto: Number(
                        Number(row.original.amount_of_debt - text).toFixed(2)
                      ),
                    },
                  ]);
                }

                if (onChange) {
                  onChange(text);
                }
              }
            };
            return (
              <>
                <div className="contenedorPagosSelect">
                  <div className="campoPago1" style={itemStyle}>
                    <input
                      type="number"
                      value={text || row.original.amount_of_debt}
                      style={textStyle}
                      onChange={handleInputChange}
                      onClick={handleInputChange2}
                      onBlur={handleSaveClick}
                    />
                  </div>

                  <div className="campoPago2" style={itemStyle}>
                    <input
                      type="number"
                      value={textdr || 1}
                      style={textStyle}
                      onChange={handleInputChangedr}
                      onClick={handleInputChange2dr}
                      onBlur={handleSaveClick}
                    />
                  </div>
                </div>
              </>
            );
            // );(Number(text-row.original.total))
          }
          if (mode === "read") {
            const handleEditClick = () => {
              if (row.isSelected === true) {
                setMode("edit");
              }
            };
            return (
              <div >
                <table>
                  <tr>
                    <td>
                      <div

                        style={textStyle}
                        onClick={handleEditClick}
                      >
                        {text}
                      </div>
                    </td>
                    <td>
                      {" "}
                      <h10>
                        {isNaN(Number(text)) === true
                          ? "0.00"
                          : Number(text) <= row.original.amount_of_debt
                            ? Number(row.original.amount_of_debt - text).toFixed(
                              2
                            ) === row.original.amount_of_debt
                              ? "0.00"
                              : Number(
                                row.original.amount_of_debt - text
                              ).toFixed(2)
                            : "0.00"}
                      </h10>
                    </td>
                    <td>
                      <div

                        style={textStyle2}
                        onClick={handleEditClick}
                      >
                        {textdr}
                      </div>
                    </td>
                    <td>
                      {" "}
                      <IndeterminateCheckbox
                        className="NoInput"
                        onChange={select_payment}
                        onClick={select_payment}
                        {...row.getToggleRowSelectedProps()}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            );
          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh_table]
  );

  return (
    <div>

      <BREADCRUMBS niveles={
        [
          { label: "REALIZAR COMPLEMENTO DE PAGO", path: null },
        ]
      }
      ></BREADCRUMBS>


      <div className="containerpayment">

        <div className="fact-20 rPago">
          <Button
            sx={{ m: 2, width: "100%" }}
            variant="contained"
            onClick={async () => {
              const result = await CustomDialog(
                <JOIN_INVOICE relacionar={true} type={rl0P} rfc={partners.rfc}></JOIN_INVOICE>,
                {
                  className: "modalCancelR",
                  title: "Relacionar Factura",
                  showCloseIcon: true,
                }
              );
              setuuid_rel(result);
            }}
          >
            {uuid_rel === undefined
              ? "Relacionar"
              : uuid_rel === false
                ? "Relacionar"
                : "UUID_SAT:" +
                uuid_rel?.uuid_sat +
                " Motivo:" +
                uuid_rel?.motivo}
          </Button>
        </div>
      </div>

      <div className="ContenerConponents">
        <div >
          <div
            style={{
              display: sessionStorage.getItem(
                "ROLE_COMPLEMENTO_PAGO_SHOW_FOLIO"
              ),
            }}
          >
            <div className="containerPagoResponse">
              <div className="pag-1 color-1">
                <ValidationTextField
                  label="Tipo de comprobante"
                  variant="outlined"
                  placeholder="Folio"
                  sx={{ m: 2, width: "100%" }}
                  value={"COMPLEMENTO DE PAGO"}
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <div className="pag-2 rseriep">
                <TextField
                  id="Serie"
                  select
                  label="Tipo de serie"
                  value={serial_number}
                  sx={{ m: 2, width: "100%" }}
                  onChange={(e) => setserial_number(e.target.value)}
                  size="small"
                  name="CFDIUse"
                  onClick={numType}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {series.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.serie}
                    </option>
                  ))}
                </TextField>
              </div>

              <div className="pag-3 color-3">
                <ValidationTextField
                  label="Folio"
                  variant="outlined"
                  placeholder="Folio"
                  sx={{ m: 2, width: "100%" }}
                  value={type_folio}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
          </div>

          <div className="containerPagoResponse-1">
            <div className="pag-4 color-1">
              <div className="contpartnersShear">
                <ReactSearchAutocomplete
                  items={obj_partners}
                  fuseOptions={{ keys: ["business_partner"] }}
                  resultStringKeyName="business_partner"
                  onSearch={handleOnSearch}
                  onSelect={handleOnSelect}
                  onClear={handleOnClear}
                  inputsearch_string={search_string}
                  showIcon={true}
                  placeholder="Buscar socio de negocios..."
                  styling={{
                    height: "34px",
                    border: "1px solid darkgreen",
                    borderRadius: "4px",
                    backgroundColor: "white",
                    boxShadow: "none",
                    hoverBackgroundColor: "lightgreen",
                    color: "black",
                    iconColor: "black",
                    lineColor: "black",
                    placeholderColor: "black",
                    clearIconMargin: "3px 8px 0 0",
                    zIndex: 10,
                  }}
                />
              </div>
            </div>

            <div className="pag-5 color-2">
              <ValidationTextField
                label="RFC"
                variant="outlined"
                placeholder="RFC"
                sx={{ m: 2, width: "100%" }}
                value={delete_rfc || partners?.rfc}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>

          <div className="containerPagoResponse-2">
            <div className="pag-6 color-1">
              <TextField
                select
                label="Forma de pago"
                value={mode_payment}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(e) => setmode_payment(e.target.value)}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {op4.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>

            <div className="pag-7 color-2">
              <TextField
                select
                label="Tipo de moneda"
                value={select_currency_type}
                sx={{ m: 1.5, width: "100%" }}
                onChange={(e) => setselect_currency_type(e.target.value)}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {op6.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>

            <div className="pag-8 color-1">
              <ValidationTextField
                label="Tipo decambio"
                variant="outlined"
                id="input-element-id-cambioT"
                placeholder="Tipo de cambio"
                sx={{ m: 1.5, width: "100%" }}
                onChange={(event) => setpayment_type(event.target.value)}
                value={payment_type}
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <div className="pag-9 color-2">
              <input
                type={type_input_hour_payment}
                className="fiPago"
                onFocus={(e) => {
                  settype_input_hour_payment("datetime-local");
                }}
                placeholder={fi2}
                onChange={(event) => setfi2(event.target.value)}
              ></input>
            </div>
          </div>

          <div className="containerPagoResponse-3">
            <div className="pag-10 color-1">
              <ValidationTextField
                label="Campos seleccionados"
                variant="outlined"
                placeholder="Campos seleccionados"
                sx={{ m: 2, width: "100%" }}
                onChange={handleedit_amount}
                value={count}
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <div className="pag-11 color-2">
              <ValidationTextField
                label="Monto"
                variant="outlined"
                id="input-element-id"
                placeholder="Monto"
                sx={{ m: 2, width: "100%" }}
                onChange={(event) => setedit_amount(event.target.value)}
                value={edit_amount}
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <div className="pag-12 color-1">
              <ValidationTextField
                label="Importe"
                variant="outlined"
                placeholder="Importe"
                id="input-total_quantity"
                sx={{ m: 2, width: "100%" }}
                value={final_data?.length === 0 ? 0 : sum_amount}
                InputLabelProps={{ shrink: true }}
              />
            </div>

            <div className="pag-13 color-2">
              <ValidationTextField
                label="Diferencia"
                variant="outlined"
                id="input-total_quantity"
                placeholder="Diferencia"
                sx={{ m: 2, width: "100%" }}
                value={total_quantity}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>

          <div className="containerPagoResponse-4">
            <div className="pag-14 color-1">
              <ValidationTextField
                label="Correo electrónico"
                variant="outlined"
                placeholder="exmple@example.com"
                sx={{ m: 1.5, width: "100%" }}
                onChange={handleedit_email}
                value={edit_email}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>

          <div className="containerPagoResponse-5">
            <div className="pag-15 color-1">
              <Button
                onClick={() => setshow_component(!show_component)}
                sx={{ m: 2, width: "100%" }}
                style={{ background: "orange" }}
                variant="contained"
              >
                {show_component
                  ? `Datos extra del complemento de pago`
                  : `Datos extra del complemento de pago`}
              </Button>
            </div>

            <div className="pag-16 color-1">
              <Button
                style={{
                  display: isVisibility === true ? "none" : "",
                  background: "green",
                }}
                onClick={handle_generate_payment}
                sx={{ m: 2, width: "100%" }}
                variant="contained"
              >
                Generar Recibo
              </Button>
              <Button
                onClick={handlesenddata}
                style={{
                  display: isVisibility === false ? "none" : "",
                  background: "green",
                }}
                sx={{ m: 2, width: "100%" }}
                variant="contained"
              >
                Generar Recibos
              </Button>
            </div>
            <div className="pag-17 color-1">
              <Button
                onClick={() => handleSaveData()}
                sx={{ m: 2, width: "100%" }}
                variant="contained"
              >
                Guardar pago
              </Button>
            </div>
          </div>

          <div className={show_component ? "show-element" : null}>
            {show_component && (
              <div>
                <div className="containerPagoResponse-6">
                  <div className="pag-17 color-1">
                    <ValidationTextField
                      label="Número de operación"
                      variant="outlined"
                      placeholder="Número de operación"
                      sx={{ m: 2, width: "100%" }}
                      onChange={(e) => setoperation_number(e.target.value)}
                      value={operation_number}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>

                <div className="containerPagoResponse-7">
                  <div className="pag-18 color-1">
                    <ReactSearchAutocomplete
                      items={bank}
                      fuseOptions={{ keys: ["label"] }}
                      resultStringKeyName="label"
                      onSelect={handleOnSelectissuing_bank}
                      maxResults={4}
                      showIcon={true}
                      placeholder="Buscar banco emisor..."
                      styling={{
                        height: "34px",
                        border: "1px solid darkgreen",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        boxShadow: "none",
                        hoverBackgroundColor: "lightgreen",
                        color: "black",
                        iconColor: "black",
                        lineColor: "black",
                        placeholderColor: "black",
                        clearIconMargin: "3px 8px 0 0",
                        zIndex: 9,
                      }}
                    />
                  </div>
                  <div className="pag-19 color-1">
                    <ValidationTextField
                      label="RFC de la entidad emisora"
                      variant="outlined"
                      placeholder="RFC"
                      sx={{ m: 2, width: "100%" }}
                      value={issuing_bank.value}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className="pag-20 color-1">
                    <ValidationTextField
                      label="Nombre del banco extranjero"
                      variant="outlined"
                      placeholder="Nombre del banco extranjero"
                      sx={{ m: 2, width: "100%" }}
                      onChange={(e) => setforeing_bank(e.target.value)}
                      value={foreing_bank || issuing_bank.name}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>

                <div className="containerPagoResponse-8">
                  <div className="pag-21 color-1">
                    <ReactSearchAutocomplete
                      items={bank}
                      fuseOptions={{ keys: ["label"] }}
                      resultStringKeyName="label"
                      onSelect={handleOnSelectissuing_bankB}
                      maxResults={4}
                      showIcon={true}
                      placeholder="Buscar banco beneficiado..."
                      styling={{
                        height: "34px",
                        border: "1px solid darkgreen",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        boxShadow: "none",
                        hoverBackgroundColor: "lightgreen",
                        color: "black",
                        iconColor: "black",
                        lineColor: "black",
                        placeholderColor: "black",
                        clearIconMargin: "3px 8px 0 0",
                        zIndex: 8,
                      }}
                    />
                  </div>

                  <div className="pag-22 color-1">
                    <ValidationTextField
                      label="RFC de la entidad emisora"
                      variant="outlined"
                      placeholder="RFC"
                      sx={{ m: 2, width: "100%" }}
                      value={issuing_bankB.value}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>

                <div className="containerPagoResponse-9">
                  <div className="pag-23 color-1">
                    <ValidationTextField
                      label="Cuenta ordenante"
                      variant="outlined"
                      placeholder="Cuenta ordenante"
                      sx={{ m: 1.5, width: "100%" }}
                      onChange={(e) => setpaying_account(e.target.value)}
                      onBlur={validCuentaOrdenate}
                      value={paying_account}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>

                  <div className="pag-24 color-1">
                    <ValidationTextField
                      label="Cuenta beneficiario"
                      variant="outlined"
                      placeholder="Cuenta beneficiario"
                      sx={{ m: 1.5, width: "100%" }}
                      onBlur={validCuentaBeneficiario}
                      onChange={(e) => setbeneficiary_account(e.target.value)}
                      value={beneficiary_account}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <h2 className="mensajeErroCuOrd">
                  {paying_account_error_message}
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="contenedorConcept"
        style={{ display: isVisibility === false ? "none" : "" }}
      >
        <table>
          <thead>
            <tr className="cabezeracolor">
              <th className="unidadConcept">#</th>
              <th className="unidadConcept">Fecha</th>
              <th className="descripcionConcept">Forma de pago</th>
              <th className="cantidadConcept">Moneda</th>
              <th className="precioConcept">Monto</th>
              <th className="accionesConcept">*</th>
            </tr>
          </thead>
          <tbody>
            {cdp_env.map((row, index) => (
              <>
                <tr>
                  <td className="tdunidad">{index}</td>
                  <td className="tdunidad">{row.fechapago}</td>
                  <td className="tddescripcion">{row.formadepagop}</td>
                  <td>{row.monedap}</td>
                  <td className="tdimpuestos">{row.monto}</td>
                  <td className="borrarcolum">
                    <img
                      alt=""
                      src={DELETE}
                      name={row.id_pay}
                      onClick={handleRemoveItem}
                      className="borrarcl"
                    ></img>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div className="table_payment">


        <MODAL_TABLE modalGeneral={is_loading} modalT={is_loading} message={message || "Buscando..."}></MODAL_TABLE>

        <div>
          <StickyTable>
            <div className="div2cM01">
              <input
                type="text"
                className="fi"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder="Fecha inicial"
                onChange={(event) => setfi(event.target.value)}
              ></input>

              <input
                type="text"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder="Fecha Final"
                onChange={(event) => setff(event.target.value)}
              ></input>

              <button
                className="button"
                onClick={handle_get_payment}
                type="button"
              >
                Buscar
              </button>
            </div>
            {/* <button onClick={resetData}>Reset Data</button> */}

            <TableContainer
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              updateMyData={updateMyData}
              skip_page_reset={skip_page_reset}
              remove={remove}
            />
          </StickyTable>
        </div>
      </div>
    </div>
  );
}

export default Pago;
