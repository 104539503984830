//PAQUETERIAS
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import NotificationSystem from "react-notification-system";
import { CustomDialog, Confirm } from "react-st-modal";
import dayjs from 'dayjs';

// import { FixedSizeList as List } from 'react-window';
//COMPONENTES
import { refresh_token } from '../../../../main_components/tokens/tokenrefresh';
import { custom_multiple_filter, calculate_total } from '../../../../main_components/methods_v2/auxiliary_functions';
import { date_current, previous_date } from "../../../../main_components/date/date";
import SEARCH_FILTERS from './tools/shearch_filter';
import MODAL_TABLE from '../../../../main_components/modal/modal_Table';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from '../../../../main_components/methods_v2/export';
import { BREADCRUMBS } from "../../../../main_components/pagination/breadcrumbs";
import AlertResponse from '../../../../main_components/alerts/alertResponse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { get_cashclosing } from '../../../../services/cashclosing/cashclosing';
import { catchErrorModul } from '../../../../main_components/catchErr/catchError';
import SUMMARY_CASHCLOSING_V2 from './tools/summary_cashclosing';
import WAREHOSE_CASHCLOSING_V2 from './tools/warehose_cashclosing.jsx';
import { download_invoice } from '../../../../services/invoices/invoice';
import { base64toBlob } from '../../../../main_components/donwloadfuncion/download';
import { get_sale_cashclosing, cancel_sale_cashclosing, generate_invoice_cashclosing, toinvoice_fromdocument, toinvoice_cashclosing, save_cashclosing, close_cashclosing } from '../../../../services/cashclosing/cashclosing';
import PUT_CASHCLOSING from './detail/put_sale_cashclosing';
import DETAIL_SALE from './tools/detail_sale';
import RETURNS_SALES from './tools/returns_sales';
import PDF from "../../../../imgComponents/pdf.png";
import RECARGA from "../../../../imgComponents/actualizar.png";
import INVOICE from "../../../../imgComponents/invoice.png";
import REFACTURAR from "../../../../imgComponents/re-facturar.png";
import CONFIG from "../../../../imgComponents/config.png";
import VIEW from "../../../../imgComponents/lupa.png";
import CANCELSALE from "../../../../imgComponents/aaa.png";

import { get_route } from '../../../../services/configuration/configuration';
import { get_sectors_route } from '../../../../services/businesspartners/businesspartners';
const CONSULT_CASHCLOSING_SALE_V2 = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [fi, setfi] = useState(dayjs(previous_date));
  const [ff, setff] = useState(dayjs(date_current));
  const [data, setData] = useState([]);
  const [usStateList, setusStateList] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [query_filters, setquery_filters] = useState({});
  const [menssage, setmenssage] = useState("");
  const [total_rows, settotal_rows] = useState("");



  const [id_sale_type_fk_dt, setid_sale_type_fk_dt] = useState("");
  const [sectors_data, setsectors_data] = useState([]);
  const [relation_sector, setrelation_sector] = useState([]);
  const [id_route_cashclosing, setid_route_cashclosing] = useState("");

  const [g_gifts, setg_gifts] = useState(0);
  const [g_payed, setg_payed] = useState(0);
  const [g_invoiced, setg_invoiced] = useState(0);
  const [g_secundary_sales, setg_secundary_sales] = useState(0);
  const [g_not_payed, setg_not_payed] = useState(0);
  const [g_cash_secondary_sales, setg_cash_secondary_sales] = useState(0);
  const [g_subtotal1, setg_subtotal1] = useState(0);
  const [g_total_sales, setg_total_sales] = useState(0);

  const [g_total, setg_total] = useState(0);
  const [g_subtotal, setg_subtotal] = useState(0);
  const [g_tax, setg_tax] = useState(0);

  const [g_debts, setg_debts] = useState(0);
  const [g_discount, setg_discount] = useState(0);
  const [g_paid, setg_paid] = useState(0);

  const [dataSinFil, setdataSinFil] = useState([]);
  const [ticketS, setticketS] = useState("");

  //Filtros resumen de liquidación
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const [filtersbonusdevView, setfiltersbonusdevView] = useState(true);
  const [filterVPCo, setfilterVPCo] = useState(false);
  const [filterVPCr, setfilterVPCr] = useState(false);
  const [filterVSCo, setfilterVSCo] = useState(false);
  const [filterVSCr, setfilterVSCr] = useState(false);
  const [filterVO, setfilterVO] = useState(false);

  //Venta primaria crédito
  const [vPSBonificacion, setvPSBonificacion] = useState(0);
  const [vPSDevolucion, setvPSDevolucion] = useState(0);
  const [vPCTotal, setvPCTotal] = useState(0);
  const [vPCAdeudo, setvPCAdeudo] = useState(0);
  const [vPCPagado, setvPCPagado] = useState(0);
  //Venta primaria contado
  const [vPSContadoBonificacion, setvPSContadoBonificacion] = useState(0);
  const [vPSContadoDevolucion, setvPSContadoDevolucion] = useState(0);
  const [vPContadoTotal, setvPContadoTotal] = useState(0);
  const [vPContadoAdeudo, setvPContadoAdeudo] = useState(0);
  const [vPContadoPagado, setvPContadoPagado] = useState(0);
  //Venta primaria crédito
  const [vSSBonificacion, setvSSBonificacion] = useState(0);
  const [vSSDevolucion, setvSSDevolucion] = useState(0);
  const [vSCTotal, setvSCTotal] = useState(0);
  const [vSCAdeudo, setvSCAdeudo] = useState(0);
  const [vSCPagado, setvSCPagado] = useState(0);
  //Venta secundaria contado
  const [vSSContadoBonificacion, setvSSContadoBonificacion] = useState(0);
  const [vSSContadoDevolucion, setvSSContadoDevolucion] = useState(0);
  const [vSContadoTotal, setvSContadoTotal] = useState(0);
  const [vSContadoAdeudo, setvSContadoAdeudo] = useState(0);
  const [vSContadoPagado, setvSContadoPagado] = useState(0);

  //Venta primaria crédito
  const [obBonificacion, setobBonificacion] = useState(0);
  const [obDevolucion, setobDevolucion] = useState(0);
  const [obTotal, setobTotal] = useState(0);
  const [obAdeudo, setobAdeudo] = useState(0);
  const [obPagado, setobPagado] = useState(0);

  const [reinvoice_data2, setreinvoice_data2] = useState([]);


  const [warehose_reconsult, setwarehose_reconsult] = useState(false);



  const notificationSystemRef = useRef();
  // console.log(sessionStorage.getItem("routeSale"))

  const update_identifiers =
    [
      { bonus: "number" },
      { cash: "number" },
      { cash_secondary_sales: "number" },
      { close: "string" },
      { credit: "number" },
      { date_register: "string" },
      { deleted_logical: "string" },
      { enable: "string" },
      { fdate: "string" },
      { gifts: "number" },
      { idate: "string" },
      { invoiced: "number" },
      { not_payed: "number" },
      { payed: "number" },
      { route: "string" },
      { secundary_sales: "number" },
      { subtotal1: "number" },
      { subtotal: "number" },
      { discount: "number" },
      { debt: "number" },
      { payet: "number" },
      { total: "number" },
      { tax: "number" },

      { total_sales: "number" },
      { "route-list": "string" }

    ];

  const update_null_identifiers = (original_obj, identifiers_to_update) => {
    return original_obj.map((item) => {
      for (const identifier in item) {
        if (item.hasOwnProperty(identifier)) {
          const isToUpdate = identifiers_to_update.some((update) => update[identifier] !== undefined);
          if (isToUpdate && item[identifier] === null) {
            item[identifier] = typeof identifiers_to_update.find((update) => update[identifier]) === 'number' ? 0 : '';
          }
        }
      }
      return item;
    });
  };


  const handle_type_sale_route = async () => {
    const routeSale = sessionStorage.getItem("routeSale");

    const dt = await get_route();
    const dta_sect = await get_sectors_route(routeSale);
    setsectors_data(dta_sect.data.sectors)
    var dta = dt.data.routes.filter((d) => d.route === routeSale).map((d) => { return { id_sale_type_fk: d?.id_sale_type_fk, id_route: d.route } });

    setid_sale_type_fk_dt(dta[0]?.id_sale_type_fk);
    setid_route_cashclosing(dta[0]?.id_route)
  }

  useEffect(() => {
    handle_type_sale_route();
  }, [])

  const handle_get_cashclosing = async (query_filters_flag, query_filters_data) => {
    setData([]);
    setIsLoading(true);
    try {
      var dt;
      var dta;
      if (query_filters_flag) {
        setfi(dayjs(query_filters_data.di));
        setff(dayjs(query_filters_data.df));
        dt = await get_cashclosing(query_filters_data.fi, query_filters_data.ff);
      } else {
        setquery_filters({ di: fi.format('YYYY-MM-DD'), df: ff.format('YYYY-MM-DD') });
        dt = await get_cashclosing(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'));
      }
      const dataorder = dt.data.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
      dta = update_null_identifiers(dataorder, update_identifiers);
      const list = Array.from(new Set(dta?.map(obj => obj.route)));
      setusStateList(list);
      setg_invoiced(dta?.map(obj => obj.invoiced).filter(d => d !== null));
      setg_payed(dta?.map(obj => obj.payed).filter(d => d !== null));
      setg_not_payed(dta?.map(obj => obj.not_payed).filter(d => d !== null));
      setg_gifts(dta?.map(obj => obj.gifts).filter(d => d !== null));
      setg_secundary_sales(dta?.map(obj => obj.secundary_sales).filter(d => d !== null));
      setg_cash_secondary_sales(dta?.map(obj => obj.cash_secondary_sales).filter(d => d !== null));
      setg_subtotal1(dta?.map(obj => obj.subtotal1).filter(d => d !== null));
      setg_total_sales(dta?.map(obj => obj.total_sales).filter(d => d !== null));



      setData(dta);
    } catch (err) {
      console.log("error", err);
      setData([]);
    } finally {
      setIsLoading(false)
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const handle_success_filter = (a, b, c) => {
    handle_get_cashclosing(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={c} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  };





  //consulta todas las ventas
  const handleSubmit = async (event) => {
    event?.preventDefault();
    setData([]);
    setmodalT(true);
    setmodalGeneral(false);
    setIsLoading(true);

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {

        try {
          const dt = await get_sale_cashclosing(sessionStorage.getItem("dateiSale"), sessionStorage.getItem("datefSale"), sessionStorage.getItem("routeSale"));
          setmodalT(false);
          setIsLoading(false);
          const data2 = update_null_identifiers(dt.data.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0), update_identifiers);
          // dta = update_null_identifiers(dataorder, update_identifiers);
          const list = Array.from(new Set(data2?.map(obj => obj.route)));
          setusStateList(list);

          setg_total(data2?.map(obj => obj.total).filter(d => d !== null));
          setg_subtotal(data2?.map(obj => obj.subtotal).filter(d => d !== null));
          setg_tax(data2?.map(obj => obj.tax).filter(d => d !== null));
          setg_debts(data2?.map(obj => obj.debt).filter(d => d !== null));
          setg_discount(data2?.map(obj => obj.discount).filter(d => d !== null));
          setg_paid(data2?.map(obj => obj.payed).filter(d => d !== null));

          setData(data2);
          setdataSinFil(data2);

          // OBSEQUIOS TOTALES
          const obsequioD = dt.data.filter((sale) => sale.saletype === "OBSEQUIO");
          handelObsequio(obsequioD);
          // NO OBSEQUIOS
          const noObsequio = dt.data.filter((sale) => sale.saletype !== "OBSEQUIO");

          // VENTA PRIMARIA TOTALES
          handelVPContado(noObsequio.filter((sale) => sale.generate_debt_pos === true && sale.pay_condition_pos === "CONTADO" && sale.partner_type === "PROPIO"));

          // VENTA PRIMARIA CREDITO TOTALES

          handelVPCredito(noObsequio.filter((sale) => sale.generate_debt_pos === true && sale.pay_condition_pos !== "CONTADO" && sale.partner_type === "PROPIO"));

          // VENTA SECUNDARIA TOTALES
          const ventaSecundaria = noObsequio.filter((sale) => sale.generate_debt_pos === false);
          const ventaSecundaria2 = noObsequio.filter((sale) => sale.generate_debt_pos === true);

          // VENTA SECUNDARIA CONTADO TOTALES

          const vSContado = ventaSecundaria.filter((sale) => sale.pay_condition_pos === "CONTADO").filter((sale) => sale.partner_type !== "PROPIO");
          const vSContado2 = ventaSecundaria2.filter((sale) => sale.pay_condition_pos === "CONTADO").filter((sale) => sale.partner_type === "EXTERNO");

          const vSContadoAll = vSContado.concat(vSContado2);

          handelVSContado(vSContadoAll);
          // VENTA SECUNDARIA CREDITO TOTALES

          const vSCredito = ventaSecundaria.filter((sale) => sale.pay_condition_pos !== "CONTADO").filter((sale) => sale.partner_type !== "PROPIO");
          const vSCredito2 = ventaSecundaria2.filter((sale) => sale.pay_condition_pos !== "CONTADO").filter((sale) => sale.partner_type === "EXTERNO");
          const vSCreditoAll = vSCredito.concat(vSCredito2);

          handelVSCredito(vSCreditoAll);

          const factureAll = data2.reduce((result, sale) => {
            if (((sale.rfc_business_partner === "XAXX010101000" && sale.no_invoice === false) || (sale.rfc_business_partner !== "XAXX010101000" && sale.no_invoice === true)) && sale.generate_debt_pos === true && sale.never_invoice === false && sale.pay_condition_pos === "CONTADO" && sale.saletype !== "NO VENTA" && sale.saletype !== "OTRO" && sale.saletype !== "PEDIDO_EMPLEADO" && sale.saletype !== "PEDIDO_EMPLADO" && sale.saletype !== "CONGELADOR" && sale.partner_type === "PROPIO") { result.push(sale); }
            return result;
          }, []);

          const reinvoice = factureAll.map((item) => { return item?.folio_invoice; });
          const reinvoice_data = reinvoice.filter((d) => d !== null);
          setreinvoice_data2(reinvoice_data)

        } catch (err) {
          console.log("error", err);
          setData([]);
          setmodalT(false);
          setIsLoading(false);

        } finally {
          setIsLoading(false);
        }

      } else { }
    } catch (err) {
      console.log(err);
    }

  };

  //Calcula todas las ventas primarias de contado
  const handelVPContado = (vPContado) => {
    setvPContadoTotal(vPContado?.map((sales) => parseFloat(sales?.total)).reduce((p, c) => p + c, 0));
    setvPContadoAdeudo(vPContado?.map((sales) => parseFloat(sales?.debt)).reduce((p, c) => p + c, 0));
    setvPContadoPagado(vPContado?.map((sales) => parseFloat(sales?.payed)).reduce((p, c) => p + c, 0));
    //BONIFICACION / DEVOLUCION TOTALES
    const bonDevContado = vPContado.filter((sale) => sale?.returns !== null).map((item) => item.returns);
    setvPSContadoBonificacion(bonDevContado.reduce((p, c) => p + c.reduce((a, b) => (b.type === "BONIFICACION" ? a + b.total : a), 0), 0));
    setvPSContadoDevolucion(bonDevContado.reduce((p, c) => p + c.reduce((a, b) => (b.type === "DEVOLUCION" ? a + b.total : a), 0), 0));
  };

  //Calcula todas las ventas primarias de crédito
  const handelVPCredito = (vPCredito) => {
    const sumvPCreditoTotal = vPCredito?.map((sales) => parseFloat(sales?.total)).reduce((previous, current) => { return previous + current; }, 0);
    setvPCTotal(sumvPCreditoTotal);
    const sumvPCreditoAdeudo = vPCredito?.map((sales) => parseFloat(sales?.debt)).reduce((previous, current) => { return previous + current; }, 0);
    setvPCAdeudo(sumvPCreditoAdeudo);
    const sumvPCreditoPagado = vPCredito?.map((sales) => parseFloat(sales?.payed)).reduce((previous, current) => { return previous + current; }, 0);
    setvPCPagado(sumvPCreditoPagado);

    //BONIFICACION / DEVOLUCION TOTALES
    const bonDevCreditoFilter = vPCredito.filter((sale) => sale?.returns !== null);
    const bonDevCredito = bonDevCreditoFilter.map((item) => { const container = item.returns; return container; });
    // console.log(bonDevCredito);
    const bonDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "BONIFICACION" ? item[i].total : 0;
        return container;
      }
      return 0;
    });
    // // console.log(bonDevCreditoAAPP);
    const totalbonDevCreditoAAPP = bonDevCreditoAAPP.reduce((a, b) => a + b, 0);
    // // console.log(totalbonDevCreditoAAPP);
    setvPSBonificacion(totalbonDevCreditoAAPP);

    const devDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "DEVOLUCION" ? item[i].total : 0;
        return container;
      }
      return 0;

    });
    // // console.log(devDevCreditoAAPP);
    const totaldevDevCreditoAAPP = devDevCreditoAAPP.reduce((a, b) => a + b, 0);
    // console.log(totaldevDevCreditoAAPP);
    setvPSDevolucion(totaldevDevCreditoAAPP);
  };
  //Calcula todas las ventas secudarias de contado
  const handelVSContado = (vSContado) => {
    const sumvPContadoTotal = vSContado?.map((sales) => parseFloat(sales?.total)).reduce((previous, current) => { return previous + current; }, 0);
    setvSContadoTotal(sumvPContadoTotal);
    const sumvPContadoAdeudo = vSContado?.map((sales) => parseFloat(sales?.debt)).reduce((previous, current) => { return previous + current; }, 0);
    setvSContadoAdeudo(sumvPContadoAdeudo);
    const sumvPContadoPagado = vSContado?.map((sales) => parseFloat(sales?.payed)).reduce((previous, current) => { return previous + current; }, 0);
    setvSContadoPagado(sumvPContadoPagado);

    //BONIFICACION / DEVOLUCION TOTALES
    const bonDevContadoFilter = vSContado.filter((sale) => sale?.returns !== null);
    const bonDevContado = bonDevContadoFilter.map((item) => { const container = item.returns; return container; });
    const bonDevContadoAAPP = bonDevContado.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "BONIFICACION" ? item[i].total : 0;
        return container;
      }
      return 0;

    });
    const totalbonDevContadoAAPP = bonDevContadoAAPP.reduce((a, b) => a + b, 0);
    setvSSContadoBonificacion(totalbonDevContadoAAPP);
    const devDevContadoAAPP = bonDevContado.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "DEVOLUCION" ? item[i].total : 0;
        return container;
      }
      return 0;
    });
    const totaldevDevContadoAAPP = devDevContadoAAPP.reduce((a, b) => a + b, 0);
    setvSSContadoDevolucion(totaldevDevContadoAAPP);
  };
  //Calcula todas las ventas secudarias de crédito
  const handelVSCredito = (vSCredito) => {
    const sumvPCreditoTotal = vSCredito?.map((sales) => parseFloat(sales?.total)).reduce((previous, current) => { return previous + current; }, 0);
    setvSCTotal(sumvPCreditoTotal);
    const sumvPCreditoAdeudo = vSCredito?.map((sales) => parseFloat(sales?.debt)).reduce((previous, current) => { return previous + current; }, 0);
    setvSCAdeudo(sumvPCreditoAdeudo);
    const sumvPCreditoPagado = vSCredito?.map((sales) => parseFloat(sales?.payed)).reduce((previous, current) => { return previous + current; }, 0);
    setvSCPagado(sumvPCreditoPagado);

    //BONIFICACION / DEVOLUCION TOTALES
    const bonDevCreditoFilter = vSCredito.filter(
      (sale) => sale?.returns !== null
    );

    const bonDevCredito = bonDevCreditoFilter.map((item) => {
      const container = item.returns;
      return container;
    });
    // console.log(bonDevCredito);

    const bonDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "BONIFICACION" ? item[i].total : 0;
        return container;
      }
      return 0;

    });
    // // console.log(bonDevCreditoAAPP);
    const totalbonDevCreditoAAPP = bonDevCreditoAAPP.reduce((a, b) => a + b, 0);
    // // console.log(totalbonDevCreditoAAPP);
    setvSSBonificacion(totalbonDevCreditoAAPP);

    const devDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "DEVOLUCION" ? item[i].total : 0;
        return container;
      }
      return 0;

    });
    // // console.log(devDevCreditoAAPP);
    const totaldevDevCreditoAAPP = devDevCreditoAAPP.reduce((a, b) => a + b, 0);
    // console.log(totaldevDevCreditoAAPP);
    setvSSDevolucion(totaldevDevCreditoAAPP);
  };
  //Calcula todas las ventas de obsequio
  const handelObsequio = (obsequioD) => {
    const sumvPCreditoTotal = obsequioD?.map((sales) => parseFloat(sales?.total)).reduce((previous, current) => { return previous + current; }, 0);
    setobTotal(sumvPCreditoTotal);
    const sumvPCreditoAdeudo = obsequioD?.map((sales) => parseFloat(sales?.debt)).reduce((previous, current) => { return previous + current; }, 0);
    setobAdeudo(sumvPCreditoAdeudo);
    const sumvPCreditoPagado = obsequioD?.map((sales) => parseFloat(sales?.payed)).reduce((previous, current) => { return previous + current; }, 0);
    setobPagado(sumvPCreditoPagado);
    //BONIFICACION / DEVOLUCION TOTALES
    const bonDevCreditoFilter = obsequioD.filter((sale) => sale?.returns !== null);
    const bonDevCredito = bonDevCreditoFilter.map((item) => { const container = item.returns; return container; });
    const bonDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "BONIFICACION" ? item[i].total : 0;
        return container;
      }
      return 0;
    });
    const totalbonDevCreditoAAPP = bonDevCreditoAAPP.reduce((a, b) => a + b, 0);
    setobBonificacion(totalbonDevCreditoAAPP);

    const devDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "DEVOLUCION" ? item[i].total : 0;
        return container;
      }
      return 0;
    });
    // // console.log(devDevCreditoAAPP);
    const totaldevDevCreditoAAPP = devDevCreditoAAPP.reduce((a, b) => a + b, 0);
    // console.log(totaldevDevCreditoAAPP);
    setobDevolucion(totaldevDevCreditoAAPP);
  };
  //Reconsulta todas las ventas
  const handleReconsulta = (result) => {
    if (result === true) {
      handleSubmit();
      setwarehose_reconsult(true);
      setTimeout(() => {
        setwarehose_reconsult(false)
      }, 1000);
    } else {
    }
  };

  //Filtrar ventas primarias de contado
  const handleFIlterSalePrimaryC = () => {
    const vPContado = dataSinFil.filter((sale) => sale.saletype !== "OBSEQUIO" && sale.generate_debt_pos === true && sale.partner_type === "PROPIO" && sale.pay_condition_pos === "CONTADO");
    console.log(vPContado.map((d) => { return { ticket: d.ticket } }))
    console.log(dataSinFil.map((d) => { return { ticket: d.ticket } }))

    if (data.length !== vPContado.length) {
      setIsLoading(true);
      setData(vPContado);
      setfilterVPCo(true);
      setfilterVPCr(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } else {
      setIsLoading(true);
      setData(dataSinFil);
      setfilterVPCo(false);
      setfilterVPCr(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
      setIsLoading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  };
  const handleFIlterSalePrimaryCredit = () => {
    const vPCredito = dataSinFil.filter((sale) => sale.saletype !== "OBSEQUIO" && sale.generate_debt_pos === true && sale.partner_type === "PROPIO" && sale.pay_condition_pos !== "CONTADO");
    console.log(vPCredito.map((d) => { return { ticket: d.ticket } }))

    if (data.length !== vPCredito.length) {
      setIsLoading(true);
      setData(vPCredito);
      setfilterVPCr(true);
      setfilterVPCo(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } else {
      setIsLoading(true);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
      setData(dataSinFil);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  };
  const handleFIlterSaleSecundaryC = () => {
    const factureAll = dataSinFil.filter((sale) => sale.saletype !== "OBSEQUIO" && sale.pay_condition_pos === "CONTADO" && ((sale.generate_debt_pos === false && sale.partner_type !== "PROPIO") || (sale.generate_debt_pos === true && sale.partner_type === "EXTERNO")));
    console.log(factureAll.map((d) => { return { ticket: d.ticket } }))


    if (data.length !== factureAll.length) {
      setIsLoading(true);
      setData(factureAll);
      setfilterVSCo(true);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } else {
      setIsLoading(true);
      setData(dataSinFil);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  };
  const handleFIlterSaleSecundaryCredit = () => {
    const factureAll = dataSinFil.filter((sale) =>
      sale.saletype !== "OBSEQUIO" && ((sale.generate_debt_pos === false && sale.partner_type !== "PROPIO") || (sale.generate_debt_pos === true && sale.partner_type === "EXTERNO")) && sale.pay_condition_pos !== "CONTADO");
    console.log(factureAll.map((d) => { return { ticket: d.ticket } }))


    if (data.length !== factureAll.length) {
      setIsLoading(true);
      setData(factureAll);
      setfilterVSCr(true);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVO(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } else {
      setIsLoading(true);
      setData(dataSinFil);
      setfilterVSCr(false);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVO(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  };
  const handleFIlterDebit = () => {
    const noObsequio = dataSinFil.filter((sale) => sale.saletype === "OBSEQUIO");
    console.log(noObsequio.map((d) => { return { ticket: d.ticket } }))

    if (data.length !== noObsequio.length) {
      setIsLoading(true);
      setfilterVO(true);
      setfilterVSCr(false);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setData(noObsequio);
      setTimeout(() => {
        setIsLoading(false);
      }, 600);
    } else {
      setIsLoading(true);
      setfilterVO(false);
      setfilterVSCr(false);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setData(dataSinFil);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  };


  //Factura Oportuno
  const handel_toinvoce = async () => {
    const factureAll = dataSinFil.reduce((result, sale) => {
      if (((sale.rfc_business_partner === "XAXX010101000" && sale.no_invoice === false) || (sale.rfc_business_partner !== "XAXX010101000" && sale.no_invoice === true)) && sale.generate_debt_pos === true && sale.never_invoice === false && sale.pay_condition_pos === "CONTADO" && sale.saletype !== "NO VENTA" && sale.saletype !== "OTRO" && sale.saletype !== "PEDIDO_EMPLEADO" && sale.saletype !== "PEDIDO_EMPLADO" && sale.saletype !== "CONGELADOR" && sale.partner_type === "PROPIO") { result.push(sale); }
      return result;
    }, []);

    const ventasNoCanceladas = factureAll.filter((venta) => {
      const sumaReturns = venta.returns
        ? venta.returns.reduce((total, devolucion) => total + parseFloat(devolucion.total), 0)
        : 0;
      return !(sumaReturns >= parseFloat(venta.total));
    });

    const valid = ventasNoCanceladas.map((item) => { return item.complete; });
    const validFalseComplete = valid.filter((sale) => sale === false);
    const bonDevContadoAAPP = ventasNoCanceladas.map((item) => { return item.id; });
    const reinvoice = ventasNoCanceladas.map((item) => { return item?.folio_invoice; });
    const reinvoice_data = reinvoice.filter((d) => d !== null);

    var dta;

    if (validFalseComplete?.length > 0) {
      alert("Tienes una venta oportuno sin completar");
    } else {

      if (reinvoice_data?.length === 0) {
        dta = { sales: bonDevContadoAAPP };
      } else {
        dta = { sales: bonDevContadoAAPP, reinvoice: true };
      }

      setmodalT(true);


      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          try {
            var result = await toinvoice_cashclosing(dta);
            alert("Exito ventas facturadas correctamente");
            setmodalT(false);
            handleReconsulta(true);
          } catch (err) {
            alert("Error.," + JSON.stringify(err?.response?.data));
            catchErrorModul("Corte de Caja => Factura Oportuno", JSON.stringify(dta), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));;
            setmodalT(false);
          }
        } else { }
      } catch (err) {
        console.log(err);
      }
    }
  };


  function checkAndRemoveTickets(data) {
    const ticketsToKeep = data.filter(ticketObject => {
      if (ticketObject.returns && ticketObject.returns.length > 0) {
        const totalReturns = ticketObject.returns.reduce((sum, ret) => sum + ret.total, 0);
        return ticketObject.total > totalReturns;
      }
      return true;
    });
    return ticketsToKeep;
  }

  const handel_toinvoce_fromdocument = async () => {
    const factureAll = dataSinFil.reduce((result, sale) => {
      if (sale.saletype !== "NO VENTA" && sale.saletype !== "OTRO" && sale.saletype !== "PEDIDO_EMPLEADO" && sale.saletype !== "PEDIDO_EMPLADO" && sale.saletype !== "CONGELADOR" && sale.partner_type === "PROPIO") { result.push(sale); }
      return result;
    }, []);
    const valid = factureAll.map((item) => { return item.complete; });
    const validFalseComplete = valid.filter((sale) => sale === false);

    const invoice_cp_cancel = factureAll.map((item) => { return { folio_invoice: item.folio_invoice, ticket: item.ticket, total: item.total, returns: item.returns } });
    if (validFalseComplete?.length > 0) {
      alert("Tienes una venta sin completar");
    } else {
      const updatedData = checkAndRemoveTickets(invoice_cp_cancel);
      const invoice_cp = updatedData?.map((item) => { return { folio_invoice: item.folio_invoice, ticket: item.ticket } });


      setmodalT(true);
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          try {
            // var result = await toinvoice_fromdocument(invoice_cp,sessionStorage.getItem("routeSale"));
            var result = await toinvoice_fromdocument(invoice_cp, id_route_cashclosing);

            alert("Éxito, las cartas porte se estaran generando.");
            setmodalT(false);
            handleReconsulta(true);
          } catch (err) {
            console.log(err);
            console.log(err?.code);

            if (err?.response?.status === 500) {
              alert(err?.response?.data?.errorMessage);
            }
            else if (err?.response?.status === 502) {
              alert("Las cartas se están generando, recibirás un correo electrónico al finalizar.")
            }
            else if (err?.response?.status === 504) {
              alert("Las cartas se están generando, recibirás un correo electrónico al finalizar.")
            }
            else if (err?.code === "ERR_NETWORK") {
              alert("Las cartas se están generando, recibirás un correo electrónico al finalizar.")
            }
            else if (err?.response?.status === 401) {
              alert("Token expirado.")
            }
            else {
              alert("Error no contemplado, favor de comunicarse con soporte.")
            }
            setmodalT(false);
          }
        } else { }
      } catch (err) {
        console.log(err);
      }
    }
  };

  //Guardar Liquidación
  const handel_save_cashclosing = async () => {
    setmodalT(true);
    setData([]);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          const dt = await save_cashclosing(sessionStorage.getItem("dateiSale"), sessionStorage.getItem("datefSale"), sessionStorage.getItem("routeSale"), sessionStorage.getItem("selectVendro"), {});
          setmodalT(false);
          handleReconsulta(true)
          alert("Liquidación guardada correctamente");
        } catch (err) {
          setmodalT(false);
          catchErrorModul("Corte de Caja => Guardar Liquidación", JSON.stringify(data), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
          alert("Error.," + JSON.stringify(err?.response?.data));
        }

      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  //Cerrar Liquidación
  const handelCashclosing = async () => {
    const filterSaleType = dataSinFil.filter((d) => d.id_sale_type_fk === 1 || d.id_sale_type_fk === 2 || d.id_sale_type_fk === 3);
    const validtype = filterSaleType.filter((d) => d.complete === false);
    if (validtype.length === 0) {
      setmodalT(true);
      setData([]);
      try {
        var rf_token = await refresh_token();
        if (rf_token === true) {
          try {
            const dt = await close_cashclosing(sessionStorage.getItem("dateiSale"), sessionStorage.getItem("datefSale"), sessionStorage.getItem("routeSale"), {});
            alert("Exito ventas cerradas");
            setmodalT(false);
            handleReconsulta(true);
          } catch (err) {
            setmodalT(false);
            catchErrorModul("Corte de Caja => Cerrar Liquidación", JSON.stringify({}), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
            alert("Error.," + JSON.stringify(err?.response?.data));
          }

        } else { }
      } catch (err) {
        console.log(err);
      }

    } else {
      alert('No se puede cerrar la liquidación., Causa: hay ventas de tipo "PREVENTA, OBSEQUIO, VENTA DIRECTA", sin completar.')
    }

  };

  //Factura Venta
  const handel_generate_invoice = async (id_sale, invoice) => {
    var dtjson;
    setmodalT(true);

    if (invoice === null) {

      dtjson = {};
    } else {

      dtjson = { reinvoice: true };
    }

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          const dt = await generate_invoice_cashclosing(id_sale, dtjson);
          alert("Exito venta facturada correctamente");
          handleReconsulta(true);
          setmodalT(false);
        } catch (err) {
          alert("Error.," + JSON.stringify(err?.response?.data));
          setmodalT(false);
        }

      } else {
        setmodalT(false);
      }
    } catch (err) {
      setmodalT(false);

      console.log(err);
    }
  };

  //Descargar PDF
  const handleDonwload = async (typedocumentexmlorpdf, id_invoce, ticket) => {
    setmodalT(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          const data = await download_invoice(typedocumentexmlorpdf, null, id_invoce);
          var dt = data.data.file;
          var blob = base64toBlob(dt);
          const blobUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = blobUrl;
          var arr = ticket;
          downloadLink.download = arr + `.${typedocumentexmlorpdf}`;
          downloadLink.click();
          setmodalT(false);
        } catch (err) {
          setmodalT(false);
        }
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  //Cancelar Venta
  const handle_cancel_sale = async (id) => {
    setmodalT(true);
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        try {
          await cancel_sale_cashclosing(id, sessionStorage.getItem("selectwarehouse"));
          setmodalT(false);
          window.location = "/cashclosing_sale_v2";

        } catch (err) {
          catchErrorModul("Corte de Caja => Cancelar Venta", JSON.stringify({ id_warehouse_fk: id }), JSON.stringify(err?.response === undefined ? "Token expirado" : err?.response));
          alert("Error.," + JSON.stringify(err?.response?.data));
          setmodalT(false);
        }
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handlechecked = (event) => { setfiltersColumsView(event.target.checked); };

  const handle_filter_bonus_dev = (e) => {
    if (e === false) {
      const datan = dataSinFil.filter((d) => d.returns !== null);
      setData(datan);
    } else {
      setData(dataSinFil);
    }
  };

  function throttle(func, limit) {
    let inThrottle;

    return function throttledFunction(...args) {
      if (!inThrottle) {
        func(...args);
        inThrottle = true;

        setTimeout(() => inThrottle = false, limit);
      }
    };
  }

  // Función que se ejecuta en respuesta al evento 'scroll'
  function handleScroll() {
    // Tu código aquí
  }

  // Aplicar throttling al manejador de eventos 'scroll'
  const throttledScroll = throttle(handleScroll, 250); // Limita la ejecución a una vez cada 250ms

  window.addEventListener('scroll', throttledScroll);


  const columns = useMemo(
    () => [

      {
        id: "Acciones",
        header: "Acciones",
        size: 145,
        id: "Acciones",
        enableColumnFilterModes: false,
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => {



          return (
            <div>
              <img
                alt=""
                style={{
                  display:
                    row.original.generate_debt_pos === true
                      ? row.original.saletype !== "NO VENTA"
                        ? row.original.saletype !== "OTRO"
                          ? row.original.saletype !== "PEDIDO_EMPLEADO"
                            ? row.original.saletype !== "CONGELADOR"
                              ? row.original.complete === true
                                ? row.original.partner_type === "PROPIO"
                                  ? row.original.total !== row.original.returns?.map((sales) => parseFloat(sales?.total)).reduce((p, c) => p + c, 0)
                                    ? sessionStorage.getItem("ROLE_SUPERVISOR")
                                    : "none"
                                  : "none"
                                : "none"
                              : "none"
                            : "none"
                          : "none"
                        : "none"
                      : "none",
                  cursor: "pointer"
                }}
                src={row.original.folio_invoice === null ? INVOICE : REFACTURAR}
                className="withImg"
                title={row.original.folio_invoice === null ? "Facturar" : "Re-facturar"}
                onClick={async () => {
                  if (
                    sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                    const result = await Confirm(row.original.folio_invoice === null ? "¿Esta usted seguro de facturar esta venta?" : "¿Esta usted seguro de re-facturar la venta?", "Facturar Venta", "Si", "No");
                    if (result) { handel_generate_invoice(row.original.id, row.original.folio_invoice); } else { }
                  } else { alert("No tienes permisos para facturar"); }
                }}
              ></img>

              {/* <img
                alt=""
                style={{
                  display:
                    row.original.folio_invoice === null
                      ? row.original.saletype !== "NO VENTA"
                        ? row.original.saletype !== "OTRO"
                          ? row.original.saletype !== "PEDIDO_EMPLEADO"
                            ? row.original.saletype !== "CONGELADOR"
                              ? row.original.complete === true
                                ? row.original.partner_type === "PROPIO"
                                  ? row.original.returns
                                    ?.map((sales) => parseFloat(sales?.total))
                                    .reduce((p, c) => p + c, 0) >=
                                    row.original.total
                                    ? ""
                                    : "none"
                                  : "none"
                                : "none"
                              : "none"
                            : "none"
                          : "none"
                        : "none"
                      : "none",
                  cursor: "pointer"
                }}
                src={CANCEL}
                className="withImg"
                title="Venta Cancelada"
              ></img> */}

              <img
                alt=""
                style={{ display: row.original.id_invoice_fk !== null ? "" : "none", cursor: "pointer" }}
                src={PDF}
                title="Descargar PDF"
                onClick={async () => {
                  const result = await Confirm("¿Esta usted seguro de descargar esta factura?", "Descargar Factura", "Si", "No");
                  if (result) { handleDonwload("pdf", row.original.id_invoice_fk, row.original.ticket); } else { }
                }}
              ></img>
              <span title="Consultar Detalles de Devoluciones" style={{ display: row.original.returns?.length > 0 ? "" : "none", }}>
                <ExpandMoreIcon style={{ cursor: "pointer" }}
                  onClick={async () => {
                    await CustomDialog(
                      <RETURNS_SALES row={row.original.returns} handleReconsulta={handleSubmit} ></RETURNS_SALES>,
                      {
                        className: "modalTItle",
                        title: "Detalles de venta",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                    // eslint-disable-next-line no-lone-blocks
                  }}
                ></ExpandMoreIcon>
              </span>
              <br></br>
              <img
                alt=""
                src={CONFIG}
                title="Configurar venta"
                style={{ display: sessionStorage.getItem("ROLE_SUPERVISOR"), cursor: "pointer" }}
                onClick={async () => {
                  const result = await CustomDialog(
                    <PUT_CASHCLOSING downloadable={row.original.downloadable} special_folio_number={row.original.special_folio_number} no_invoice={row.original.no_invoice} never_invoice={row.original.never_invoice} paper={row.original.paper} id_sale={row.original.id} date={dayjs(row.original.date)} handleSubmit_reconsul={handleSubmit}></PUT_CASHCLOSING>,
                    {
                      className: "custom-modal",
                      title: "Configurar venta",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(result);
                  }
                }}
              ></img>
              <img
                alt=""
                src={VIEW}
                title="Detalles de venta"
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  await CustomDialog(
                    <DETAIL_SALE id={row.original.id} typeSale={"SALE"}></DETAIL_SALE>,
                    {
                      className: "modalTItle",
                      title: "Detalles de venta",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks

                }}
              ></img>

              <img
                alt=""
                src={CANCELSALE}
                title="Cancelar Venta"
                style={{ display: sessionStorage.getItem("ROLE_SUPERVISOR"), cursor: "pointer" }}
                className="imgcancelsale"
                onClick={async () => {
                  const result = await Confirm("¿Esta usted seguro de cancelar esta venta?", "Cancelar Venta", "Si", "No");
                  if (result) {
                    if (row.original.id_invoice_fk === null) {
                      handle_cancel_sale(row.original.id);
                    } else {
                      alert("Esta venta no se puede eliminar porque no se ha cancelado la factura/nota de crédito relacionada")
                    }
                  } else { }
                }}
              ></img>

            </div >
          );
        }

      },

      {
        header: "Ruta",
        accessorKey: "route-list",
        accessorFn: (d) => { return d.route },
        size: 145,
        id: "route-list",
        filterVariant: 'multi-select',
        filterSelectOptions: usStateList,
        filtferFn: custom_multiple_filter,
        enableColumnFilterModes: false,
        muiTableBodyCellProps: {
          align: 'center',
        },
        Footer: () => (
          <div>
            <div>Subtotal: </div>
            <div>Gran Total: </div>
          </div>
        ),
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        id: "ticket",
        header: "Ticket",
        accessorKey: "ticket",
        size: 145,
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        id: "scan",
        header: "Congelador",
        accessorKey: "scan",
        size: 145,
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        id: "code_pos",
        header: "Código Punto de venta",
        accessorKey: "code_pos",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "name_pos",
        header: "Punto de venta",
        accessorKey: "name_pos",
        muiTableBodyCellProps: {
          align: 'justify',
        },
      },
      {
        id: "name_business_partner",
        header: "Socio de Negocios",
        accessorKey: "name_business_partner",
        muiTableBodyCellProps: {
          align: 'justify',
        },
      },
      {
        id: "date",
        header: "Fecha",
        accessorKey: "date",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "saletype",
        header: "Tipo",
        accessorKey: "saletype",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "rfc_business_partner",
        header: "RFC",
        accessorKey: "rfc_business_partner",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "folio_invoice",
        header: "Factura",
        accessorKey: "folio_invoice",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Tipo de socio",
        accessorKey: "partner_type",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "subtotal",
        header: "Sub Total",
        accessorKey: "subtotal",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.subtotal)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_subtotal))}</div>
            </div>
          )
        },
      },
      {
        id: "discount",
        header: "Descuentos",
        accessorKey: "discount",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.discount)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_discount))}</div>
            </div>
          )
        },
      },
      {
        id: "tax",
        header: "Impuestos",
        accessorKey: "tax",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.tax)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_tax))}</div>
            </div>
          )
        },
      },

      {
        id: "total",
        header: "Total",
        accessorKey: "total",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.total)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_total))}</div>
            </div>
          )
        },
      },

      {
        id: "debt",
        header: "Adeudo",
        accessorKey: "debt",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.debt)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_debts))}</div>
            </div>
          )
        },
      },
      {
        id: "payed",
        header: "Pagado",
        accessorKey: "payed",
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('es-MX', {
              style: 'currency',
              currency: 'MXN',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        Footer: (d) => {
          const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj.payed)
          return (
            <div style={{ textAlign: "right" }}>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(subtotal))}</div>
              <div>{Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(calculate_total(g_paid))}</div>
            </div>
          )
        },
      },
      {
        id: "payment_method",
        header: "Método de Pago",
        accessorKey: "payment_method",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "pay_condition_pos",
        header: "Crédito",
        accessorKey: "pay_condition_pos",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        id: "name_person_user",
        header: "Vendedor",
        accessorKey: "name_person_user",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "complete",
        header: "Completa",
        accessorKey: "complete",
        accessorFn: (d) => {
          return d.complete === false ? "❌" : "✅";
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'select',
        enableColumnFilterModes: false

      },

      {
        id: "generate_debt_pos",
        header: "Gen. Deuda",
        accessorKey: "generate_debt_pos",
        accessorFn: (d) => {
          return d.generate_debt_pos === false ? "NO" : "SI";
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'select',
        enableColumnFilterModes: false
      },
      {
        id: "no_invoice",
        header: "Oportuno",
        accessorKey: "no_invoice",
        accessorFn: (d) => {
          return d.no_invoice === true ? "SI" : "NO";
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'select',
        enableColumnFilterModes: false
      },
      {
        id: "never_invoice",
        header: "Nunca Facturar",
        accessorKey: "never_invoice",
        accessorFn: (d) => {
          return d.never_invoice === true ? "SI" : "N0";
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'select',
        enableColumnFilterModes: false
      },
      {
        id: "close",
        header: "Venta Cerrada",
        filterVariant: 'select',
        accessorKey: "close",
        accessorFn: (d) => {
          return d.close === true ? "SI" : "NO";
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'select',
        enableColumnFilterModes: false

      },
    ],
    [g_gifts, g_secundary_sales, g_cash_secondary_sales, data, usStateList],
  );

  useEffect(() => {
    handleSubmit();
  }, []);




  return (
    <div style={{ width: "100%", margin: "0 auto" }} className='cashclosing_sale_v2 '>
      <BREADCRUMBS niveles={
        [
          { label: "CORTE DE CAJA", path: "/cashclosing_v2" },
          { label: "RESUMEN DE LIQUIDACIÓN RUTA:" + sessionStorage.getItem("routeSale"), path: null },
        ]
      }
        cards_dashboard={false}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        modul="CONSULTAR_FACTURAS_V1"
        path="/consultaCFDI"
      ></BREADCRUMBS>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}

      <SUMMARY_CASHCLOSING_V2
        handleFIlterSalePrimaryC={handleFIlterSalePrimaryC}  //Filtra Ventas Primarias de Contado
        filterVPCo={filterVPCo}
        vPContadoTotal={vPContadoTotal}
        vPSContadoDevolucion={vPSContadoDevolucion}
        vPSContadoBonificacion={vPSContadoBonificacion}
        vPContadoPagado={vPContadoPagado}
        vPContadoAdeudo={vPContadoAdeudo}
        handleFIlterSalePrimaryCredit={handleFIlterSalePrimaryCredit} //Filtra Ventas Primarias de Crédito
        filterVPCr={filterVPCr}
        vPCTotal={vPCTotal}
        vPSDevolucion={vPSDevolucion}
        vPSBonificacion={vPSBonificacion}
        vPCPagado={vPCPagado}
        vPCAdeudo={vPCAdeudo}
        handleFIlterSaleSecundaryC={handleFIlterSaleSecundaryC} //Filtra Ventas Secundarias de Contado
        filterVSCo={filterVSCo}
        vSContadoTotal={vSContadoTotal}
        vSSContadoDevolucion={vSSContadoDevolucion}
        vSSContadoBonificacion={vSSContadoBonificacion}
        vSContadoPagado={vSContadoPagado}
        vSContadoAdeudo={vSContadoAdeudo}
        handleFIlterSaleSecundaryCredit={handleFIlterSaleSecundaryCredit}  //Filtra Ventas Secundarias de Crédito
        filterVSCr={filterVSCr}
        vSCTotal={vSCTotal}
        vSSDevolucion={vSSDevolucion}
        vSSBonificacion={vSSBonificacion}
        vSCPagado={vSCPagado}
        vSCAdeudo={vSCAdeudo}
        handleFIlterDebit={handleFIlterDebit}   //Filtra Ventas Obsequio
        filterVO={filterVO}
        obTotal={obTotal}
        obDevolucion={obDevolucion}
        obBonificacion={obBonificacion}
        obPagado={obPagado}
        obAdeudo={obAdeudo}


      ></SUMMARY_CASHCLOSING_V2>
      <br />
      <BREADCRUMBS niveles={
        [
          { label: "CORTE DE CAJA", path: "/cashclosing_v2" },
          { label: "VENTAS DE LIQUIDACIÓN RUTA:" + sessionStorage.getItem("routeSale"), path: null },
        ]

      }
        cards_secundary={true}
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>


      <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping

        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowSelection
        // onFilterChange={}
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{
          rowSelection, isLoading: isLoading,
        }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering

        // enableRowVirtualization={true}
        // enableColumnVirtualization={true}
        // rowVirtualizerOptions={{
        //   overscan: 10,  // Ajusta según la necesidad
        // }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 10 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        onStateChange={handleStateChange}
        enableExpandAll={false}
        enableColumnDragging={false}
        initialState={{ showGlobalFilter: true, showColumnFilters: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}


        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        // muiTableContainerProps={{
        //   component: React.forwardRef((props, ref) => (
        //     <List
        //       height={400} // Ajusta según tus necesidades
        //       itemCount={data.length}
        //       itemSize={35} // Ajusta según el diseño
        //       width="100%"
        //       {...props}
        //       ref={ref}
        //     />
        //   )),
        //   sx: { width: '100%', height: '400px' }
        // }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            Header: () => (
              <div>
                <img style={{ cursor: "pointer" }} title='Re-Consultar' src={RECARGA} onClick={() => handleReconsulta(true)}></img>
              </div>
            ),
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}

        muiExpandButtonProps={({ row }) => ({
          children: row?.original?.returns?.length === undefined ? <span title='SIN DEVOLUCIÓNES'><AssignmentLateIcon></AssignmentLateIcon></span> : row?.original?.returns?.length === 0 ? <span title='SIN DEVOLUCIÓNES'><AssignmentLateIcon></AssignmentLateIcon></span> : <span title='DETALLES DE DOVOLUCIONES'><ExpandMoreIcon></ExpandMoreIcon></span>,
        })}

        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={({ row, table }) => {
          const { density } = table.getState();
          const heightStyle = {
            height: row.getIsPinned()
              ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69}px`
              : undefined,
          };

          const colorStyle = row?.original?.returns?.length > 0 ? row.original.returns?.map((sales) => parseFloat(sales?.total)).reduce((p, c) => p + c, 0) >= row.original.total ? { backgroundColor: '#eba4a4', color: 'white' } : { backgroundColor: 'rgb(228 221 231)', color: 'white' } : row.original.complete === false && row.original.saletype !== "NO VENTA" && row.original.saletype !== "" && row.original.saletype !== "OTRO" && row.original.saletype !== "PEDIDO_EMPLEADO" && row.original.saletype !== "PEDIDO_EMPLADO" && row.original.saletype !== "CONGELADOR" && row.original.partner_type === "PROPIO" ? { backgroundColor: 'rgb(245 224 173) !important', color: 'black !important' } : { backgroundColor: 'white !important', color: 'black !important' };

          return {
            sx: {
              ...heightStyle,
              ...colorStyle,
            },
          };
        }}



        renderTopToolbarCustomActions={({ }) => (
          <SEARCH_FILTERS
            fi={fi}
            setfi={setfi}
            ff={ff}
            setff={setff}
            handle_get_sales={handle_get_cashclosing}
            handle_filter_bonus_dev={handle_filter_bonus_dev}
            handelCashclosing={handelCashclosing}
            handel_save_cashclosing={handel_save_cashclosing}
            handel_toinvoce_fromdocument={handel_toinvoce_fromdocument}
            handel_toinvoce={handel_toinvoce}
            sectors_data={sectors_data}
            id_route_cashclosing={id_route_cashclosing}
            id_sale_type_fk_dt={id_sale_type_fk_dt}
          ></SEARCH_FILTERS>
        )}
        renderToolbarInternalActions={({ table, idenx }) => (
          <RENDER_TOOLBAR_INTERNAL_ACTIONS
            key={idenx + "CONSULT_CASHCLOSING_SALE_V2"}
            share_filter={false}
            settotal_rows={settotal_rows}
            handle_success_filter={handle_success_filter}
            total_rows={total_rows !== "" ? total_rows : 0}
            table={table} file_name={"FACTURAS DEL " + fi.format('YYYY-MM-DD') + " AL " + ff.format('YYYY-MM-DD')}
            update_identifiers={update_identifiers}
          />
        )
        }
      />

      {/* </LocalizationProvider> */}

      <br />
      <WAREHOSE_CASHCLOSING_V2 warehose_reconsult={warehose_reconsult}></WAREHOSE_CASHCLOSING_V2>
    </div >
  );
};

export default CONSULT_CASHCLOSING_SALE_V2;