import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

interface Comment {
    date: string;
    user: string;
    message: string;
    id: string;
    dateObject: Date;
}

const formatComment = (comment: string): Comment | null => {
    try {
        const parts = comment.split("|").map(item => item.trim());

        const date = parts[0] || "";
        const idMatch = parts.find(part => part.startsWith("id:")) || "";
        const userMatch = parts.find(part => part.startsWith("username:")) || "";
        const messageIndex = parts.indexOf(userMatch) + 1;
        const message = parts.slice(messageIndex).join(" ").trim();

        if (date && idMatch && userMatch && message) {
            const id = idMatch.replace("id:", "").trim();
            const user = userMatch.replace("username:", "").trim();
            return {
                date,
                user,
                message,
                id: `ID: ${id}`,
                dateObject: new Date(date)
            };
        }

        return null;
    } catch (error) {
        console.error("Error formatting comment:", error);
        return null;
    }
};

const CommentsComponent = ({ commentsText = "" }: { commentsText: string }) => {
    const [formattedComments, setFormattedComments] = useState<Comment[]>([]);

    useEffect(() => {
        if (commentsText) { // Asegura que commentsText no sea undefined o vacío
            const comments = commentsText.split("||")
                .map(comment => formatComment(comment))
                .filter(Boolean) as Comment[];

            const sortedComments = comments.sort((a, b) => b.dateObject.getTime() - a.dateObject.getTime());
            setFormattedComments(sortedComments);
        }
    }, [commentsText]);

    return (
        <Box
            sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                maxHeight: "300px",
                overflowY: "auto",
                padding: "16px",
                backgroundColor: "#f9f9f9",
            }}
        >
            {formattedComments.length === 0 ? (
                <Typography variant="body2" color="text.secondary">
                    No comments available or unable to display comments.
                </Typography>
            ) : (
                formattedComments.map((comment, index) => (
                    <Card
                        key={index}
                        variant="outlined"
                        sx={{
                            mb: 2,
                            border: "none",
                            boxShadow: "none",
                            borderBottom: "1px solid #e0e0e0",
                            padding: "10px 0",
                            position: "relative",
                        }}
                    >
                        <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                fontWeight: 'bold'
                            }}
                        >
                            {comment.id}
                        </Typography>

                        <CardContent sx={{ padding: 0 }}>

                            <Typography variant="subtitle2" color="primary" sx={{ fontWeight: 'bold' }}>
                                {comment.user}
                            </Typography>

                            <Typography variant="body2" color="text.primary" sx={{ mb: 1 }}>
                                {comment.message}
                            </Typography>

                            <Typography variant="caption" color="text.secondary">
                                {comment.date}
                            </Typography>
                        </CardContent>
                    </Card>
                ))
            )}
        </Box>
    );
};

export default CommentsComponent;
