import { SignUp } from "aws-amplify-react/lib/Auth";
import { AuthState } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import axios from "axios";
import "./login.css";
import validator from "validator";
import fondo from "../img-login/fondo-OD2-Act.webp";
import MuiAlert from "@material-ui/lab/Alert";

export class CustomSignUp extends SignUp {
  constructor(props) {
    super(props);

    this.state = {
      Username: "",
      Password: "",
      RePassword: "",
      Mail: "",
      Name: "",
      RFC: "",
      ID_COMPANY: "",
      ID_USER: "",
      ROLES: "",
      ISVALID: "",
      ISVALID2: false,
    };

    this.signUp = this.signUp.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  handleFormSubmission(evt){
    var emailRegex =
      /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    if (this.state.Username.length > 0) {
      if (
        validator.isStrongPassword(this.state.Password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        if (
          this.state.Password === this.state.RePassword &&
          this.state.Password !== ""
        ) {
          if (emailRegex.test(this.state.Mail)) {
            var config = {
              method: "get",
              url: "https://blntcbmh9g.execute-api.us-east-1.amazonaws.com/prod/validate",
              params: { email: this.state.Mail, rfc: this.state.RFC },
            };

            axios(config)
              .then((response) => {
                this.setState({ ISVALID2: true });
              })
              .catch(function (error) {
                console.log(error);
                console.log(error?.response);
                console.log(error?.response?.data?.errorMessage);
                var erroDM = error?.response?.data?.errorMessage;
                if (erroDM === undefined) {
                  alert(
                    "Error al obtener sus datos, verifique que su correo esté autorizado para registrarse en od2"
                  );
                } else {
                  alert(erroDM + " verifique sus datos");
                }
                this?.setState({ ISVALID2: false });
              });

            if (this.state.ISVALID2 === true) {
              this?.signUp();
              // alert("entradata");
            } else {
            }
          } else {
            alert("El correo electronico esta mal estructurado reviselo");
          }
        } else {
          alert("Las contraseñas no son iguales");
        }
      } else {
        alert(
          "La contraseña debe contener al menos 8 carcateres, una minuscula, una mayuscula, un número y un simbolo especial"
        );
      }
    } else {
      alert("Error: Debe subministrar un nombre de usuario");
    }
  }

  mostrarContrasena() {
    var tipo = document.getElementById("password-field");
    if (tipo.type === "password") {
      tipo.type = "text";
    } else {
      tipo.type = "password";
    }
  }

  mostrarContrasena2() {
    var tipo = document.getElementById("password-field2");
    if (tipo.type === "password") {
      tipo.type = "text";
    } else {
      tipo.type = "password";
    }
  }

  async signUp() {
    const username = this.state.Username;
    const password = this.state.Password;
    const email = this.state.Mail;
    const rfc = this.state.RFC;
    const name = this.state.Name;
    console.log(email);
    try {

      var config = {
        method: "get",
        url: "https://blntcbmh9g.execute-api.us-east-1.amazonaws.com/prod/validate",
        params: { email: email, rfc: rfc },
      };

      axios(config)
        .then(function (response) {
          const id_company = response.data.id_company;
          const id_user = response.data.id_user;
          const roles = response.data.roles;
          const { user } = Auth.signUp({
            username,
            password,
            attributes: {
              email,
              name,
              "custom:rfc_company": rfc,
              "custom:id_user": `${id_user}`,
              "custom:roles": roles,
              "custom:id_company": `${id_company}`,
            },
          });
          this.props.SetUserName(user["username"]);
          this.props.SetAuthState(AuthState.ConfirmSignUp);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error?.response);
          console.log(error?.response?.data?.errorMessage);
          var erroDM = error?.response?.data?.errorMessage;
          if (erroDM !== undefined) {
            alert(
              "Error al obtener sus datos, verifique que su correo esté autorizado para registrarse en od2"
            );
          }
        });
      this.props.SetAuthState(AuthState.ConfirmSignUp);
    } catch (err) {
      console.log(err);
      console.log(err?.response);
      console.log(err?.response?.data);

      if (err.code === "UsernameExistsException") {
        alert("Usted no está autorizado para registrarse");
      } else if (err.code === "") {
      } else {
        alert("Usted no está autorizado para registrarse");

        this.setState({ error: "An error has occurred." });
        console.error(err);
      }
    }
  }

  render() {
    return (
      <div className='container_login'>
          <div className='grid-login'>
            <div className='formsesion'>
              <div className='camplogin'>
              < div className="div-lg2">
                <p className='bienvenido'>Registrarse</p>
                {/* <p className='text-login'>Inicia sesión para continuar</p> */}
              </div>
                
              <div className='grid-form-login'>
              <div className='form-register'>
                <label className='label-usuario'>Nombre de usuario</label>
                <input
                  className="form-control text-center txtmin "
                  type="text"
                  placeholder="Nombre de usuario"
                  onChange={(e) =>
                    this.setState({
                      Username: e.target.value?.toLowerCase()?.trim(),
                    })
                  }
                  value={this.state.Username}
                ></input>
              </div>
              <div className='form-register'>
                <label className='label-usuario'>Nombre completo</label>
                <input
                  className="text-center form-control"
                  type="text"
                  placeholder="Nombre completo"
                  onChange={(e) =>
                    this.setState({ Name: e.target.value.toUpperCase() })
                  }
                  value={this.state.Name}
                ></input>
              </div>
              <div className='form-register'>
                <label className='label-usuario'>RFC</label>
                <input
                  className="text-center form-control"
                  type="text"
                  placeholder="RFC de la empresa"
                  onChange={(e) =>
                    this.setState({ RFC: e.target.value.toUpperCase().trim() })
                  }
                  value={this.state.RFC}
                ></input>
              </div>
              <div className='form-register'>
                <label className='label-usuario'>Correo</label>
                <input
                  className="text-center form-control"
                  type="email"
                  placeholder="Correo electronico"
                  onChange={(e) =>
                    this.setState({ Mail: e.target.value.trim() })
                  }
                  value={this.state.Mail}
                ></input>
              </div>
              <div className='form-pass'>
                  
                  <div className='pass1grid'>
                  <label className='label-contraseña'>Contraseña</label>
                  <input
                  id="password-field"
                  className="text-center form-control"
                  type="password"
                  placeholder="Contraseña"
                  onChange={(e) =>
                    this.setState({ Password: e.target.value.trim()})
                  }
                  value={this.state.Password}
                ></input>
                <span
                  className="material-symbols-outlined  field-icon "
                  onClick={this.mostrarContrasena}
                >
                  visibility
                </span>
                  </div>
                </div>
                <div className='form-pass'>
                  <div className='pass1grid'>
                 
                  <label className='label-contraseña'>Confirmar Contraseña</label>
                  <input
                  id="password-field2"
                  className="text-center form-control"
                  type="password"
                  placeholder="Confirmar contraseña"
                  onChange={(e) =>
                    this.setState({ RePassword: e.target.value.trim() })
                  }
                  value={this.state.RePassword}
                ></input>
                <span
                  className="material-symbols-outlined  field-icon "
                  onClick={this.mostrarContrasena2}
                >
                  visibility
                </span>
                </div>
                </div> 
                <div className=''>
                    <button className='boton-registrarse'  
                      onClick={this.handleFormSubmission}
                    >
                      Registrarse
                    </button>
                    <button className='boton-login' 
                      onClick={()=>this.props.SetAuthState(AuthState.SignIn)}>
                      Iniciar Sesión
                    </button> 
                  </div>
              </div>
                
              </div>
            </div>
            <div className='imglogin'>
              <img src={fondo}></img>
            </div>
          </div>
        </div>
    );
  }
}

export default CustomSignUp;



      {/* <div className="colorfondo">
        <div className="parent clearfix">
          <div className="bg-illustration"></div>

          <div className="login">
            <div className="container">
              <br></br>
              <h2 className="titleOD2">Registrarse </h2>
              <div className="form-group">
                <input
                  className="form-control text-center txtmin "
                  type="text"
                  placeholder="Nombre de usuario"
                  onChange={(e) =>
                    this.setState({
                      Username: e.target.value?.toLowerCase()?.trim(),
                    })
                  }
                  value={this.state.Username}
                ></input>
              </div>
              <div className="form-group">
                <input
                  className="text-center form-control"
                  type="text"
                  placeholder="Nombre completo"
                  onChange={(e) =>
                    this.setState({ Name: e.target.value.toUpperCase() })
                  }
                  value={this.state.Name}
                ></input>
              </div>

              <div className="form-group">
                <input
                  className="text-center form-control"
                  type="text"
                  placeholder="RFC de la empresa"
                  onChange={(e) =>
                    this.setState({ RFC: e.target.value.toUpperCase().trim() })
                  }
                  value={this.state.RFC}
                ></input>
              </div>

              <div className="form-group">
                <input
                  className="text-center form-control"
                  type="email"
                  placeholder="Correo electronico"
                  onChange={(e) =>
                    this.setState({ Mail: e.target.value.trim() })
                  }
                  value={this.state.Mail}
                ></input>
              </div>

              <div className="form-group">
                <input
                  id="password-field"
                  className="text-center form-control"
                  type="password"
                  placeholder="Contraseña"
                  onChange={(e) =>
                    this.setState({ Password: e.target.value.trim() })
                  }
                  value={this.state.Password}
                ></input>
                <span
                  className="material-symbols-outlined  field-icon "
                  onClick={this.mostrarContrasena}
                >
                  visibility
                </span>
              </div>

              <div className="form-group">
                <input
                  id="password-field2"
                  className="text-center form-control"
                  type="password"
                  placeholder="Confirmar contraseña"
                  onChange={(e) =>
                    this.setState({ RePassword: e.target.value.trim() })
                  }
                  value={this.state.RePassword}
                ></input>
                <span
                  className="material-symbols-outlined  field-icon "
                  onClick={this.mostrarContrasena2}
                >
                  visibility
                </span>
              </div>
              <div className="form-group">
                <a
                  className="form-control btn btn-primary"
                  onClick={this.handleFormSubmission}
                >
                  Registrarse
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}