import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios';
import { StickyTable } from "react-sticky-table";
import TableContainer from '../main_components/Table/TableContainer';
import "./samsara.css";
import { SelectColumnFilter } from '../components_cfdi/filters';

export const ASSETS_SAMSARA = () => {

  const [data, setdata] = useState([]);
  const [datav, setdatav] = useState([]);

  const [fi, setfi] = useState("2023-10-04T13:40:41.000Z");
  const [ff, setff] = useState("2023-11-04T13:40:41.000Z");
  const [open, setopen] = useState(false);

  useEffect(() => {
    handle_vehicles();
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Conductor",
        accessor: (d) => {
          return d?.driver?.staticAssignedDriver?.name
        }
      },
      {
        Header: "Tipo",
        Filter: SelectColumnFilter,
        accessor: (d) => {
          return d?.driver?.tags[0]?.name
        }
      },
      {
        Header: "Vehículo",
        accessor: (d) => {
          return d?.vehicle?.name
        }
      },
      {
        Header: "Distancia Recorrida Metros",
        accessor: "distanceTraveledMeters"
      },
      {
        Header: "Eficiencia Mpge",
        accessor: "efficiencyMpge"
      },
      {
        Header: "Energía Utilizada KWH",
        accessor: "energyUsedKwh"
      },
      {
        Header: "Duración Inactividad Motor Ms",
        accessor: "engineIdleTimeDurationMs"
      },
      {
        Header: "Duración Funcionamiento Motor Ms",
        accessor: "engineRunTimeDurationMs"
      },
      {
        Header: "Emisiones de Carbono Kg",
        accessor: "estCarbonEmissionsKg"
      },
      {
        Header: "Combustible Consumido ml",
        accessor: "fuelConsumedMl"
      },
      {
        Header: "Costo Energía Combustible Estimado",
        accessor: (d) => {
          return d?.estFuelEnergyCost?.amount
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handle_vehicles = async () => {
    const url = "https://0kzo07oa2b.execute-api.us-east-1.amazonaws.com/prod/vehicles";
    try {
      const response = await axios.get(url, {
        headers: {
          //Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          //"access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      console.log(response?.data?.body);
      setdatav(response?.data?.body);
    } catch (err) {
      console.log(err);
    }
  }

  const handlesubmit = async () => {

    console.log(fi);
    console.log(ff);
    setdata([]);

    const url = `https://0kzo07oa2b.execute-api.us-east-1.amazonaws.com/prod/samsara_energy?startDate=${fi}&endDate=${ff}`;
    try {
      const response = await axios.get(url, {
        headers: {
          //Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          //"access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })

      console.log(response?.data?.data?.vehicleReports);
      analisis(response?.data?.data?.vehicleReports);
      // setdata(response?.data?.data?.vehicleReports);
    } catch (err) {
      console.log(err);
    }
  }

  const analisis = (d) => {
    d?.map(item => {
      datav?.map(jtem => {
        if (item?.vehicle?.id === jtem?.id) {
          console.log("entra");
          item.driver = jtem;
        }
      })
    })
    setdata(d);
  }

  return (
    <div className='samsara-container'>
      <div className='container-filtros-samsara'>
        <div className="fil1">
          <input
            type="datetime-local"
            className="fi"
            placeholder="Fecha inicial"
            onChange={(event) => {
              const selectedDateTime = new Date(event.target.value);
              const offset = selectedDateTime.getTimezoneOffset();
              const mexicoDateTime = new Date(selectedDateTime.getTime() - (offset * 60000)).toISOString();
              setfi(mexicoDateTime);
            }}
          ></input>
        </div>
        <div className="fil2">
          <input
            type="datetime-local"
            className="ff"
            placeholder="Fecha Final"
            onChange={(event) => {
              const selectedDateTime = new Date(event.target.value);
              const offset = selectedDateTime.getTimezoneOffset();
              const mexicoDateTime = new Date(selectedDateTime.getTime() - (offset * 60000)).toISOString();
              setff(mexicoDateTime);
            }}
          ></input>
        </div>
        <div className='fil3'>
          <button onClick={() => handlesubmit()}>Buscar</button>
        </div>
      </div>

      <div className='tabla-samsara'>
          <TableContainer
            paginado={""}
            consultas={"  "}
            exportar={true}
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            Gmodal={open}
          />
      </div>
    </div>
  )
}
