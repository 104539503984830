import ReceiptIcon from '@mui/icons-material/Receipt';
import TableChartIcon from '@mui/icons-material/TableChart';
import DAYS from "../../../dataComponets/days.json";
import BOOLEAN from "../../../dataComponets/true-false.json";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';

export const inf_points = [
    {
        label: "Código",
        accessor: "code",
        sm: 3,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "general",
        sectionTitle: "Información General",
        change:false,
        required:true,
        maxLength:100,
        minLength:0,
        visible:true,
        method:"ALL",
        icon: ReceiptIcon
    },
    {
        label: "Forzar Código",
        accessor: "force_code",
        sm: 3,
        dfl: false,
        type: "checkbox",
        data_select: [],
        name_select: "Habilitado",
        section: "general",
        change:true,
        required:true,
        visible:true,
        maxLength:1000,
        minLength:0,
        method:"ALL"
    },
    {
        label: "Nombre",
        accessor: "name",
        sm: 18,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "general",
        sectionTitle: "Información General",
        change:true,
        required:true,
        maxLength:100,
        minLength:0,
        visible:true,
        method:"ALL",
    },
    {
        label: "Código Unilever",
        accessor: "outer_code",
        sm: 6,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "general",
        sectionTitle: "Información General",
        change:true,
        required:true,
        maxLength:100,
        minLength:0,
        visible:true,
        method:"ALL",
    },
    {
        label: "Número de tienda",
        accessor: "special_number_folio",
        sm: 15,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "general",
        sectionTitle: "Información General",
        change:true,
        required:true,
        maxLength:100,
        minLength:0,
        visible:true,
        method:"ALL",
    },
    {
        label: "Nota",
        accessor: "note",
        sm: 15,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "general",
        sectionTitle: "Información General",
        change:true,
        required:true,
        maxLength:100,
        minLength:0,
        visible:true,
        method:"ALL",
    },
    {
        label: "Correo Electrónico",
        accessor: "email",
        sm: 20,
        dfl: "",
        type: "text",
        data_select: [],
        name_select: "",
        section: "general",
        sectionTitle: "Información General",
        change:true,
        required:true,
        maxLength:100,
        minLength:0,
        visible:true,
        method:"ALL",
    },
    {
        label: "Número Teléfonico",
        accessor: "phone",
        sm: 10,
        dfl: 0,
        type: "number",
        data_select: [],
        name_select: "",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",
    },
    {
        label: "Genera Deuda",
        accessor: "generates_debt",
        sm: 8,
        dfl: 0,
        type: "select",
        data_select: [],
        name_select: "Genera Deuda",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Límite de Crédito",
        accessor: "creditLimit",
        sm: 8,
        dfl: 0,
        type: "number",
        data_select: [],
        name_select: "",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Saldo",
        accessor: "balance",
        sm: 7,
        dfl: 0,
        type: "number",
        data_select: [],
        name_select: "",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Condición de Pago",
        accessor: "idConditionPay",
        sm: 7,
        dfl: 0,
        type: "select",
        data_select: [],
        name_select: "Condición de Pago",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Socio de Negocios",
        accessor: "idBusinessPartner",
        sm: 20,
        dfl: 0,
        type: "text",
        data_select: [],
        name_select: "",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Días de Visita",
        accessor: "visitDays",
        sm: 5,
        dfl: 0,
        type: "select",
        data_select: [DAYS],
        name_select: "Días de Visita",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Frecuencia de Visita",
        accessor: "frequency",
        sm: 5,
        dfl: 0,
        type: "select",
        data_select: [DAYS],
        name_select: "Frecuencia de Visita",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Lista de Precios",
        accessor: "idPriceList",
        sm: 8,
        dfl: 0,
        type: "select",
        data_select: [DAYS],
        name_select: "Lista de Precios",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Folios Especiales",
        accessor: "idSpecialNumberFolio",
        sm: 8,
        dfl: 0,
        type: "select",
        data_select: [DAYS],
        name_select: "Folios Especiales",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Canal",
        accessor: "idChannel",
        sm: 7,
        dfl: 0,
        type: "select",
        data_select: [DAYS],
        name_select: "Canal",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Subcanal",
        accessor: "idSubchannel",
        sm: 7,
        dfl: 0,
        type: "select",
        data_select: [DAYS],
        name_select: "Subcanal",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Ruta",
        accessor: "idRoute",
        sm: 8,
        dfl: 0,
        type: "select",
        data_select: [DAYS],
        name_select: "Ruta",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Sector",
        accessor: "idSector",
        sm: 8,
        dfl: 0,
        type: "select",
        data_select: [DAYS],
        name_select: "Sector",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Orden de Sector",
        accessor: "orderSector",
        sm: 7,
        dfl: 0,
        type: "number",
        data_select: [],
        name_select: "",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Clasificación",
        accessor: "classification",
        sm: 7,
        dfl: 0,
        type: "select",
        data_select: [DAYS],
        name_select: "Clasificación",
        section: "general", 
        sectionTitle: "Generales",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
    },
    {
        label: "Latitud",
        accessor: "lat",
        sm: 10,
        dfl: 0,
        type: "txt",
        data_select: [],
        name_select: "",
        section: "address", 
        sectionTitle: "Dirección",
        change:true,
        required:false,
        maxLength:1000,
        minLength:0,
        visible:true,
        method:"ALL",   
        icon: AddLocationAltIcon
    },
    
    
];


