import ReactDOM from "react-dom"; // This is already correct
import "./index.scss";
import "./index.css";
import App from "./App";
import Amplify from "aws-amplify";
import aws_config from "./aws-exports";
import { CssBaseline } from "@material-ui/core";
import React from "react"; // Make sure to import React

Amplify.configure(aws_config);

const container = document.getElementById("root");

// Use ReactDOM.render instead of createRoot
ReactDOM.render(
  <React.StrictMode>
    <CssBaseline>
      <App />
    </CssBaseline>
  </React.StrictMode>,
  container
);
