import { inf_points } from "./inf_points";

export const validate_data = (name: string, value: any, formData: any) => {
    var error = '';
    var infPoints;
    switch (name) {
        case 'code':
            if (!value) {
                error = 'El campo es "Código", es obligatorio';
            }
        break;
    }
    return { error: error, objetUpdate: infPoints === undefined ? inf_points : infPoints };
};



export const processed_format = (formData: any) => {
    const dataToSubmit = { ...formData };
    delete dataToSubmit.pointofsale;
    delete dataToSubmit.system_commentary;
    delete dataToSubmit.date_register;

    Object.keys(dataToSubmit).forEach((key) => {
        switch (key) {
            case 'date_paid':
                // Formatear la fecha a "YYYY-MM-DD"
                // dataToSubmit[key] = dayjs(dataToSubmit[key]).format('YYYY-MM-DD');
                break;
            default:
                // No hacer nada si no es un campo que necesita procesamiento especial
            break;
        }
    });

    console.log(dataToSubmit)

    return dataToSubmit;
};

export const updated_form_data = (key: any, value: any, formData: any) => {
    const update_form = {
        ...formData,
        [key]: value,
        person_type: key === 'rfc'
            ? value.length === 13 ? "FÍSICA" : "MORAL"
            : formData.person_type,
    };
    return update_form;
};

export const create_object = {
    code: null,
    editable: false,
    name: null,
    outerCode: null,
    generatesDebt: true,
    creditLimit: 0.0,
    balance: 0.0,
    note: null,
    latitude: null,
    longitude: null,
    numberOxxo: null,
    idSpecialNumberFolio: null,
    idChannel: null,
    idSubchannel: null,
    idRoute: null,
    idBusinessPartner: null,
    idConditionPay: null,
    idPriceList: null,
    email: null,
    phone: null,
    frequency: null,
    visitDays: null,
    classification: null,
    calle: null,
    numExt: null,
    numInt: null,
    colonia: null,
    codigoPostal: null,
    municipio: null,
    localidad: null,
    estado: null,
    pais: "MÉXICO",
    referencia: null,
    sucursal: "ENTREGA",
    tipoEntrega: "MATRIZ",
    idSector: null,
    orderSector: null,
    cabinet_access: true,
    own_cabinet: false
}
