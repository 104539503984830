// Importaciones de paquetes
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";

// Importaciones de componentes

import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
// import SEARCH_FILTERS from "./tools/shearch_filter";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";


import { MaterialTableContainerProps, RowSelection } from "./interface/interface";

//IMG / ICONS




const MATERIAL_TABLE_CONTAINER: React.FC<MaterialTableContainerProps> = (props) => {

    const [rowSelection, setRowSelection] = useState<RowSelection>({});

    return (
        <div style={{ width: "100%", margin: "0 auto" }} className="new_table_v2">
            <BREADCRUMBS
                niveles={props?.breadcrumbs}
            ></BREADCRUMBS>

            <MODAL_TABLE
                open={true}
                message={""}
                message2={""}
                modalGeneral={false}
                modalT={props?.modalT}
            ></MODAL_TABLE>

            <MaterialReactTable
                columns={props?.columns}
                data={props?.data}
                enableGrouping
                enableFacetedValues
                enableStickyHeader
                enableStickyFooter
                enableRowPinning
                enableColumnFilterModes
                getRowId={(row) => row?.id}
                onRowSelectionChange={setRowSelection}
                localization={MRT_Localization_ES}
                state={{
                    rowSelection, isLoading: props?.isLoading, showColumnFilters: true, columnPinning: {
                        left: ['mrt-row-expand', 'mrt-row-select'],
                        right: ['mrt-row-actions'],
                    },
                }}
                enableColumnResizing
                enableColumnPinning
                enableColumnOrdering
                enableExpandAll={false}
                initialState={{ showGlobalFilter: true }}
                muiCircularProgressProps={{ color: "secondary", thickness: 5, size: 55 }}
                muiSkeletonProps={{ animation: "pulse", height: 28 }}
                rowVirtualizerOptions={{ overscan: 5 }}
                columnVirtualizerOptions={{ overscan: 2 }}
                muiTableContainerProps={{
                    sx: { maxHeight: "69vh", "@media (max-height: 810px)": { maxHeight: "65vh" } }
                }}
                muiTablePaperProps={{ sx: { margin: 'auto', width: '100%' } }}

                positionToolbarAlertBanner="bottom"
                paginationDisplayMode="pages"
                rowPinningDisplayMode="sticky"
                layoutMode="grid"
                muiTableHeadCellProps={{
                    sx: { flex: "0 0 auto", whiteSpace: "normal !important" }

                }}
                // muiTableHeadCellProps={{
                //     sx: {
                //         '& .MuiTableHeadCell-Content': {
                //             display: 'flex',
                //             flexDirection: 'column-reverse', // Invierte el orden para que las acciones estén arriba
                //             alignItems: 'center',
                //         },
                //         '& .MuiTableHeadCell-Content-Actions': {
                //             marginBottom: '8px', // Agrega espacio debajo de las acciones, si necesario
                //         }
                //     }
                // }}

                muiTableBodyCellProps={{ sx: { flex: "0 0 auto", whiteSpace: "normal !important" } }}
                muiPaginationProps={{ color: "primary", shape: "rounded" }}
                displayColumnDefOptions={{
                    "mrt-row-pin": {
                        enableHiding: true,
                        Header: () => (props?.component_header)
                    },

                    "mrt-row-expand": {
                        enableHiding: true,

                    },
                    "mrt-row-select": {
                        enableHiding: true,
                    },
                }}
                muiTableBodyRowProps={({ row, table }) => {
                    const { density } = table.getState();
                    return {
                        sx: {
                            height: row.getIsPinned()
                                ? `${density === "compact"
                                    ? 30
                                    : density === "comfortable"
                                        ? 35
                                        : 69
                                }px`
                                : undefined,
                        },
                    };
                }}


                renderTopToolbarCustomActions={({ }) => (props?.search_filters)}
                renderToolbarInternalActions={({ table }) => (
                    <RENDER_TOOLBAR_INTERNAL_ACTIONS
                        table={table}
                        file_name={props?.file_name_export}
                        update_identifiers={props?.update_identifiers}
                    />
                )}
            />
        </div>
    );
};

export default MATERIAL_TABLE_CONTAINER;
