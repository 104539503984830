//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import { CustomDialog, Confirm } from "react-st-modal";
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { catchErrorModul } from "../../../../../main_components/catchErr/catchError";
import { generate_invoice_return_cashclosing, delete_bon_dev } from "../../../../../services/cashclosing/cashclosing";
import { download_invoice } from "../../../../../services/invoices/invoice";
import { base64toBlob } from "../../../../../main_components/donwloadfuncion/download";
//COMPONENTES

//ESTILOS

import VIEW from "../../../../../imgComponents/lupa.png";
import DETAIL_SALE from "./detail_sale";
import INVOICE from "../../../../../imgComponents/invoice.png";
import DELETE from "../../../../../imgComponents/borrar.png";
import PDF from "../../../../../imgComponents/pdf.png";

function RETURNS_SALES(props) {
  const [data, setData] = useState(props.row);
  const [data_original, setdata_original] = useState(props.row);
  const [data_update, setdata_update] = useState(props.row);
  const [isLoading, setisLoading] = useState(false);

  const [modalT, setmodalT] = useState(false);



  const handelGenerateFactureReturn = async (id_sale_return) => {
    const bonD = {};

    try {
      const dt = await generate_invoice_return_cashclosing(id_sale_return, bonD);
      console.log(dt);
      alert("Éxito,venta facturada correctamente");
      props.handleReconsulta();
      setmodalT(false);
    } catch (err) {
      catchErrorModul("Corte de Caja => Facturar Devolución/Bonificación", JSON.stringify(bonD), JSON.stringify(err?.response));
      alert("Error.," + JSON.stringify(err?.response?.data));
      setmodalT(false);
    }
  };



  const handleDonwload = async (typedocumentexmlorpdf, id_invoce, ticket) => {
    setmodalT(true);
    try {
      const data = await download_invoice(typedocumentexmlorpdf, null, id_invoce);
      var dt = data.data.file;
      var blob = base64toBlob(dt);
      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      var arr = ticket;
      downloadLink.download = arr + `.${typedocumentexmlorpdf}`;
      downloadLink.click();
      setmodalT(false);

    } catch (err) {
      setmodalT(false);
    }

  };

  const handle_delete_bon_dev = async (id) => {
    setmodalT(true);
    setisLoading(true)
    try {
      const dt = await delete_bon_dev(id);
      console.log(data);
      const allfilter = data.filter((d) => d.id !== id);
      console.log(allfilter)
      props.handleReconsulta();
      alert("Éxito, Bonificación eliminada correctamente");
      setmodalT(false);
      setisLoading(false)
      setdata_update(allfilter)
    } catch (err) {
      catchErrorModul("Corte de Caja => Eliminar Bonificación", JSON.stringify({}), JSON.stringify(err?.response));
      alert("Error.," + JSON.stringify(err?.response?.data));
      setData(data_original)
      setisLoading(false)

      setmodalT(false);
    }

  };



  useEffect(() => {
    setData(data_update)
    setdata_original(data_update)
  }, [data_update]);

  const columns = useMemo(
    () => [

      {
        header: "Ac.",
        id: "AC-sub",
        size: 80,
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        accessorFn: (d) =>
          <div>
            <img src={VIEW} title="VER DETALLES BONIFICACIÓN/DEVOLUCIÓN" style={{ cursor: "pointer" }}
              onClick={async () => {
                await CustomDialog(
                  <DETAIL_SALE
                    id={d?.id}
                    typeSale={"SALES_RETURNS"}
                  ></DETAIL_SALE>,
                  {
                    className: "modalTItle",
                    title: "Detalle de venta",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}>
            </img>
            <img
              alt=""
              title="FACTURAR BONIFICACIÓN/DEVOLUCIÓN"
              style={{ display: d.folio_invoice === null ? d.partner_type === "PROPIO" ? props.invocedata !== true ? "" : "none" : "none" : "none", cursor: "pointer" }}
              src={INVOICE}
              className="withImg"
              onClick={async () => {
                if (sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                  const result = await Confirm("¿Esta usted seguro de facturar esta venta con devolucion?", "Facturar Venta con devolucion", "Si", "No");
                  if (result) { handelGenerateFactureReturn(d.id) } else { }
                } else { alert("No tienes permisos para facturar") }
              }}
            ></img>
            <img
              alt=""
              style={{ display: d.id_invoice_fk !== null ? "" : "none", cursor: "pointer" }}
              title="DESCARGAR PDF"
              src={PDF}
              onClick={async () => {
                const result = await Confirm("¿Esta usted seguro de descargar esta factura?", "Descargar Facrtura", "Si", "No");
                if (result) { handleDonwload("pdf", d.id_invoice_fk, d.ticket); } else { }
              }}
            ></img>

            <img
              alt=""
              src={DELETE}
              style={{ display: d.type === "BONIFICACION" ? "" : "none", cursor: "pointer" }}
              title="ELIMINAR BONIFICACIÓN"
              className="withImg"
              onClick={async () => {
                if (sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                  const result = await Confirm("¿Esta usted seguro de eliminar esta Bonificación?", "Eliminar Bonificaciónm", "Si", "No");
                  if (result) {
                    if (d.id_invoice_fk === null) {
                      handle_delete_bon_dev(d.id)
                    } else {
                      alert("Esta bonificación no se puede eliminar porque no se ha cancelado la factura/nota de crédito relacionada")
                    }
                  } else { }
                } else { alert("No tienes permisos para facturar") }
              }}
            ></img>

          </div>



      },
      {
        header: "Tipo",
        id: "type-sub",
        accessorKey: "type",
        size: 80,
      },
      {
        header: "Ticket",
        id: "ticket-sub",
        accessorKey: "ticket",
        size: 80,

      },
      {
        header: "Cliente",
        id: "name_pos-sub",
        accessorKey: "name_pos",
        size: 130,
      },
      {
        header: "Fecha",
        id: "date-sub",
        accessorKey: "date",
        size: 150,
      },
      {
        header: "Tipo",
        id: "saletype-sub",
        accessorKey: "saletype",
        size: 80,
      },
      {
        header: "Sub Total",
        id: "subtotal-sub",
        accessorKey: "subtotal",
        size: 80,

      },
      {
        header: "Descuentos",
        id: "discount-sub",
        accessorKey: "discount",
        size: 80,

      },
      {
        header: "Impuestos",
        id: "tax-sub",
        accessorKey: "tax",
        size: 80,

      },
      {
        header: "Total",
        id: "total-sub",
        accessorKey: "total",
        size: 80,

      },
      {
        header: "Vendedor",
        id: "name_person_user-sub",
        accessorKey: "name_person_user",
        size: 130,

      },
      {
        header: "N. Crédito",
        id: "pagado-sub",
        accessorKey: "pagado",
        size: 80,


      },
      {
        header: "Credito",
        id: "folio_invoice-sub",
        accessorKey: "folio_invoice",
        size: 80,

      },
    ],

    [data]
  );

  return (
    <div style={{ width: "98%", margin: "0 auto" }} className='new_table_v2'>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters
        enablePagination
        enableSorting
        enableBottomToolbar={true}
        enableTopToolbar={true}
        state={{ isLoading: isLoading }}
        muiCircularProgressPropsave_datas={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        localization={MRT_Localization_ES}
        initialState={{ showGlobalFilter: true, showColumnFilters: true }}
        paginationDisplayMode='pages'
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        enableGrouping
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        getRowId={(row) => row?.id}
        enableTooltips={false}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableFullScreenToggle={false}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        enableColumnDragging={false}
        enableExpandAll={false}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        rowPinningDisplayMode='sticky'
        layoutMode="semantic"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              data.splice(
                hoveredRow.index,
                0,
                data.splice(draggingRow.index, 1)[0],
              );
              setData([...data]);
            }
          },
        })}
        muiTableBodyRowProps={({ row, table }) => {
          const { density } = table.getState();
          const heightStyle = {
            height: row.getIsPinned()
              ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69}px`
              : undefined,
          };

          return {
            sx: {
              ...heightStyle,
            },
          };
        }}
      />

    </div>

  );
}

export default RETURNS_SALES;
